import React from 'react';
import AdmSideBarMenu from '../../Components/SideBarMenu';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Form from 'react-bootstrap/Form';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import { Modal } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import {
    Container,
    Content,
    InfoTable,
    Line,
    Search,
    ListS,
} from './styles';
import { deleteLineSl, getLineSlug, getLines, postLine, putLine } from '../../../Services/adm';
import SnackBarAdm from '../../Components/SnackBar';


const Lines = () => {
    const navigate = useNavigate();

    const [lines, setLines] = React.useState([]);

    const [messageError, setMessageError] = React.useState();
    const [description, setDescription] = React.useState();
    const [status, setStatus] = React.useState();
    const [title, setTitle] = React.useState();
    const [image, setImage] = React.useState();
    const [sendImage, setSendImage] = React.useState();
    const [tax, setTax] = React.useState();
    const [sendTax, setSendTax] = React.useState();

    const [slug, setSlug] = React.useState();


    const [success, setSuccess] = React.useState(false);
    const [error, setError] = React.useState(false);
    const [show, setShow] = React.useState(false);
    const [show2, setShow2] = React.useState(false);
    const [empty, setEmpty] = React.useState(false);
    const [edit, setEdit] = React.useState(false);

    const handleClose = () => {
        cleanInput();
        setShow(false);
    }
    const handleClose2 = () => {
        cleanInput();
        setShow2(false);
    }

    const cleanInput = () => {
        setImage('');
        setDescription('');
        setTitle('');
        setStatus('');
        setSendTax('');
        setSendImage('');
        setTax('');
        setShow(false);
        setShow2(false);
    }

    const getLine = async () => {
        try {
            const response = await getLines();
            setLines(response.data.categories);
        } catch (error) {

        }
    }

    const getLineIndex = async (slug) => {
        try {
            const response = await getLineSlug(slug);
            setEdit(true);
            setSlug(slug);
            setDescription(response?.data?.category.description);
            setTitle(response?.data?.category.title);
            setImage(response?.data?.category.image);
            setTax(response.data.category.commission);
            setSendTax(response.data.category.commission)
            setShow(true);
        } catch (error) {

        }
    }

    const confirmDelete = (title, slug) => {
        setTitle(title);
        setSlug(slug);
        setShow2(true);
    }

    const deleteLine = async (slug) => {
        try {
            await deleteLineSl(slug);
            setSuccess(true);
            cleanInput();
            getLine();
            setTimeout(() => {
                setSuccess(false)
            }, 3000);
        } catch (error) {
            setMessageError(error)
            setError(true);
            setTimeout(() => {
                setError(false)
            }, 3000);
        }
    }

    const handleImageSelect = (event) => {
        const file = event.target.files[0];
        if (file) {
            setImage(URL.createObjectURL(event?.target?.files[0]));
            let files = event.target.files;
            let filesReader = new FileReader();
            filesReader.readAsDataURL(files[0]);
            filesReader.onload = (event) => {
                setSendImage(event.target.result);
            }
        }
    };

    const handleClickImage = () => {
        document.getElementById('fileInput').click();
    };

    const createLine = async () => {
        if (!description || !title || !sendImage || !sendTax) {
            setEmpty(true);
            setTimeout(() => {
                setEmpty(false);
            }, 3000);
            return
        } else {
            try {
                await postLine(title, description, sendImage, status, sendTax);
                cleanInput();
                getLine();
                setSuccess(true);
                setTimeout(() => {
                    setSuccess(false)
                }, 3000);
                getLine();
                handleClose2();
                handleClose();
            } catch (error) {
                setMessageError(error)
                setError(true);
                setTimeout(() => {
                    setError(false)
                }, 3000);
            }

        }
    }

    const editLine = async () => {
        if (!description || !title || !sendTax) {
            setEmpty(true);
            setTimeout(() => {
                setEmpty(false);
            }, 3000);
            return
        } else {
            try {
                await putLine(title, description, sendImage, status, slug, sendTax);
                cleanInput();
                getLine();
                setSuccess(true);
                setTimeout(() => {
                    setSuccess(false)
                }, 3000);
                navigate('/agroProdutos_management/supervisor/lines')
            } catch (error) {
                setMessageError(error)
                setError(true);
                setTimeout(() => {
                    setError(false)
                }, 3000);
            }

        }
    }

    const changeTax = (value) => {
        setTax(value);
        setSendTax(parseInt(value));
    }

    React.useEffect(() => {
        getLine()
    }, [])

    return (
        <Container>
            <div style={{ padding: '10px 5px' }}>
                <AdmSideBarMenu />
            </div>
            <Content>
                <InfoTable>
                    <Line style={{ justifyContent: 'space-between', display: "flex", paddingTop: '15px', }}>
                        <div style={{ paddingLeft: '25px' }}>
                            Categorias de Produtos
                        </div>
                        <Search>
                            <input type="text" placeholder='Procurar' style={{ color: '#A9ABAE' }} />
                        </Search>
                        <div>
                            <Button variant="contained" color="success" onClick={() => setShow(true)} >Nova Categotria</Button>
                        </div>
                    </Line>
                    <Divider variant="inset" />
                    <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                        {lines?.map((line) =>
                            <ListS key={line?.id}>
                                <ListItem alignItems="flex-start" sx={{ height: '100px' }}>
                                    <ListItemAvatar>
                                        <Avatar alt={line.title} src={line.image} />
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={line.title}
                                        secondary={
                                            <React.Fragment>
                                                <Typography
                                                    sx={{ display: 'inline' }}
                                                    component="span"
                                                    variant="body2"
                                                    color="text.primary"
                                                >
                                                    {line.slug}
                                                </Typography>
                                                {` — ${line.description}`}
                                            </React.Fragment>
                                        }
                                    />
                                    <EditIcon color="success" onClick={() => getLineIndex(line.slug)} />
                                    <DeleteIcon color="error" onClick={() => confirmDelete(line.title, line.slug)} />
                                </ListItem>
                                <Divider variant="inset" component="li" />
                            </ListS>
                        )}
                    </List>

                </InfoTable>
            </Content>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{edit ? "Editar Categoria de Produto" : "Criar Categoria de Produto"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', }}>
                            <Form.Group className="mb-3" controlId="image" style={{ margin: 'auto' }}>
                                <Avatar
                                    alt="Remy Sharp"
                                    src={image || "/static/images/avatar/1.jpg"}
                                    sx={{ width: 56, height: 56, cursor: 'pointer' }}
                                    onClick={handleClickImage}
                                />
                                <input
                                    type="file"
                                    id="fileInput"
                                    style={{ display: 'none' }}
                                    accept="image/*"
                                    onChange={handleImageSelect}
                                />
                            </Form.Group>
                        </Col>
                        <Col xs lg="6">
                            <Form.Group className="mb-3" controlId="title">
                                <Form.Label>Titulo</Form.Label>
                                <Form.Control type="text" placeholder="Titulo da Categoria" onChange={(e) => setTitle(e.target.value)} value={title} />
                            </Form.Group>
                        </Col>
                        <Col xs lg="4">
                            <Form.Group className="mb-3" controlId="tax">
                                <Form.Label>Taxa</Form.Label>
                                <Form.Control type="number" placeholder="Taxa" onChange={(e) => changeTax(Math.min(e.target.value, 100))} max={100} min={1} value={tax} />
                            </Form.Group>
                        </Col>

                    </Row>
                    <Form.Group className="mb-3" controlId="description">
                        <Form.Label>Descrição</Form.Label>
                        <Form.Control as="textarea" placeholder="Descrição" onChange={(e) => setDescription(e.target.value)} value={description} />
                    </Form.Group>
                    <Form.Check
                        type="checkbox"
                        label="Categoria ativa"
                        id="status"
                        onChange={(e) => setStatus(e.target.checked)}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    {edit ?
                        <Button variant="contained" color="primary" onClick={editLine}>Salvar</Button> :
                        <Button variant="contained" color="primary" onClick={createLine}>Salvar</Button>
                    }

                </Modal.Footer>

            </Modal>

            <Modal show={show2} onHide={handleClose2}>
                <Modal.Header closeButton>
                    <Modal.Title>Excluir Categoria</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Deseja excluir essa categoria "{title}"
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose2}>
                        Close
                    </Button>
                    <Button variant="contained" color="error" onClick={() => deleteLine(slug)}>Deletar</Button>
                </Modal.Footer>
            </Modal>

            <div id="sanckbar">
                {error && <SnackBarAdm message={`Ocorreu um erro ao executar essa operação ${messageError}`} type="error" time={3000} states={true} />}
                {success && <SnackBarAdm message={`Operação realizada com sucesso`} type="success" time={3000} states={true} />}
                {empty && <SnackBarAdm message={`Preencher todos os campos`} type="warning" time={3000} states={true} />}
            </div>
        </Container>
    )
}

export default Lines;