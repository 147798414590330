import React from 'react';
import cover from '../../../Assets/Login/login_register.png'
import Button from '@mui/material/Button';
import axios_base from '../../../axios_base';
import { useAdmInformation } from '../../../Providers/admInformations';
import { useNavigate } from "react-router-dom";
import SnackBar from '../../../Ecomerce/Components/General/SnackBar';
import {
    Container,
    Board,
    Column,
    Board0,
    Middle,
} from './styles';
import axios from 'axios';
import { Title } from '@mantine/core';


const AdminLogin = () => {

    const navigate = useNavigate();
    const { setInfo } = useAdmInformation();

    const [email, setEmail] = React.useState();
    const [password, setPassword] = React.useState();
    const [error, setError] = React.useState(false);

    const login = async (login, pass) => {
        try {
            var data = {
                email: login,
                password: pass
            }
            const { data: res } = await axios_base.post(`/api/auth/management/supervisor/login`, data)
            if (res.message === "success") {
                localStorage.setItem("Token", res.data.token);
                const { data: res2 } = await axios({ method: 'get', url: `${process.env.REACT_APP_LINK}/api/user/profile`, headers: { 'Authorization': `Bearer ${res.data.token}` }, })
                setInfo({ AdmName: res2.data.user.name, AdmType: res2.data.user.type, AdmEmail: res2.data.user.email });
                if (res2.data.user.type === "root") {
                    localStorage.setItem('controll', 'true');
                    localStorage.setItem('Auth', "true");
                    setInfo({ AdmToken: res.data.token, AdmAuth: true });
                    navigate(`/agroProdutos_management/supervisor/dashboard`);
                }
            }

        } catch (error) {
            setError(true);
            setTimeout(() => {
                setError(false);
            }, 3000);
        }
    }

    return (
        <Container >
            <Board0>
                <img src={cover} alt='logo' />
            </Board0>
            <Board>
                <Column>
                    <div style={{ textAlign: 'center' }}>
                        <Title>Login</Title>
                    </div>

                    <Middle>
                        <div className="mb-3" style={{ paddingTop: '25px' }}>
                            <label htmlFor="exampleInputEmail1" className="form-label">Email address</label>
                            <input type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" onChange={(e) => setEmail(e.target.value)} />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="exampleInputPassword1" className="form-label">Password</label>
                            <input type="password" className="form-control" id="exampleInputPassword1" onChange={(e) => setPassword(e.target.value)} />
                        </div>
                        <div style={{ alignItems: 'center', alignContent: 'center', display: 'flex', justifyContent: 'center', paddingTop: '10px' }}>
                            <Button variant="contained" color="success" onClick={() => login(email, password)}>
                                Login
                            </Button>
                        </div>
                    </Middle>

                </Column>
            </Board>
            <div id="sanckbar">
                {error && <SnackBar type="error" time={3000} message="Falha ao realizar login" state={true} />}
            </div>
        </Container>
    )
}

export default AdminLogin;