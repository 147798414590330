import React from 'react';
import Button from '@mui/material/Button';
import axios_base from '../../../../axios_base';
import { useNavigate } from "react-router-dom";
import SnackBar from '../../../Components/General/SnackBar';
import { Link } from 'react-router-dom';
import loginImage from '../../../../Assets/Login/login_register.png';
import { CustomizeInput } from '../../../../Styles/style';
import CircularProgress from '@mui/material/CircularProgress';

import {
    Container,
    Left,
    Right,
    Middle,
    DivText,
    DivTitle,
    DivButton,
} from './styles';


const ForgotPassword = () => {

    const navigate = useNavigate();
    const [email, setEmail] = React.useState();
    const [loginError, setLoginError] = React.useState(false);
    const [success, setSuccess] = React.useState(false);
    const [sending, setSending] = React.useState(false);

    const getCode = async (login, pass) => {
        setSending(true);
        try {
            var data = {
                email: login,
            }
            const { data: res } = await axios_base.post(`/api/auth/forgot-password`, data)
            if (res.message === "We have emailed your password reset link!" && res.status_code === 200) {
                setSuccess(true);
                setEmail('')
                setSending(false)
                navigate('/esqueci_senha');
                setTimeout(() => {
                    setSuccess(false);
                }, 50000);
            }

        } catch (error) {
            if (error.response.data.message === "We can't find a user with that email address.") {
                setLoginError(true);
                setSending(false);
                setTimeout(() => {
                    setLoginError(false);
                }, 6000);
            }

        }
    }
    return (
        <Container>
            <Left style={{ backgroundImage: `url(${loginImage})`, backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundSize: 'cover' }}>

            </Left>
            <Right>
                <DivTitle onClick={() => navigate('/')}>
                    <h1>Agro Produtos</h1>
                </DivTitle>
                <Middle>
                    <div style={{ textAlign: 'Left' }}>
                        <h3 style={{ fontWeight: '500', color: '#717171' }}> Recuperar Senha</h3>
                    </div>
                    <DivText>
                        <small style={{ color: '#717171' }}>
                            Digite o endereço de e-mail associado à sua conta para receber um link de redefinição. Se você não receber o e-mail em alguns minutos, verifique sua caixa de spam ou tente novamente.
                        </small>
                    </DivText>
                    <CustomizeInput>
                        <label htmlFor="email">Email</label>
                        <input type="email" id="email" onChange={(e) => setEmail(e.target.value)} />
                    </CustomizeInput>
                    <DivButton >
                        {sending ?
                            <Button variant="contained" color="success" style={{ width: '100%', height: '50px' }}>
                                <CircularProgress color="inherit" />
                            </Button>
                            :
                            <Button variant="contained" color="success" style={{ width: '100%', height: '50px' }}
                                onClick={() => getCode(email)}>
                                Receber link
                            </Button>
                        }

                    </DivButton>
                    <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'center', width: '400px' }}>
                        <Link to="/login" style={{ textDecoration: 'none' }}> <p style={{ paddingLeft: '5px', color: '#009c43' }} >Login</p></Link>
                    </div>
                    <div id="sanckbar">
                        {loginError && <SnackBar type="error" time={6000} message="Não foi possível enviar o código de verificação nesse e-mail" states={true} />}
                        {success && <SnackBar type="success" time={5000} message="Enviamos o código de recuperação de senha no seu e-mail" states={true} />}
                    </div>
                </Middle>
            </Right>
        </Container>
    )
}

export default ForgotPassword;