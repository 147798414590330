import React from 'react';
import logo from '../../../Assets/Login/logo_white.png';
import { useLocation } from 'react-router-dom';
import Badge from '@mui/material/Badge';
import { useAdmInformation } from '../../../Providers/admInformations';
import NotificationsIcon from '@mui/icons-material/Notifications';

import {
    Container,
    MenuLine1,
    MenuLine2,
    Social,
    MenuDiv,
    Icon,
    Left
} from './styles';
import axios from 'axios';

const MainHeader = () => {
    const { info, setInfo } = useAdmInformation();
    const location = useLocation();


    React.useEffect(() => {
        if (localStorage.getItem("Auth") === "true" && info.AdmName === null) {
            axios({ method: 'get', url: `${process.env.REACT_APP_LINK}/api/user/profile`, headers: { 'Authorization': `Bearer ${localStorage.getItem("Token")}` }, })
                .then(res => {
                    setInfo({ AdmName: res.data.data.user.name, AdmType: res.data.data.user.type, AdmEmail: res.data.data.user.email });
                })
                .catch(err => {

                })
        }
    }, [location])

    return (
        <Container>

            <MenuDiv>
                <MenuLine1>

                    <Left >
                        <img src={logo} alt='logo agro' />
                    </Left>

                    <MenuLine2>
                        <div>Categorias</div>
                        <div>Lojas</div>
                        <div>Ofertas</div>
                        <div>Destaques</div>
                        <div>Contato</div>
                    </MenuLine2>
                    <Social>
                        <Icon style={{ cursor: 'pointer' }}>
                            <Badge badgeContent={4} color="error">
                                <NotificationsIcon fontSize='large' />
                            </Badge>
                        </Icon>
                    </Social>
                </MenuLine1>

            </MenuDiv>
        </Container>
    );
};

export default MainHeader;