import React from 'react';
import { ButtonGreenSmall } from '../../../../Styles/stylesButtons';
import Email from '../../../../Assets/IconsJsx/Emai';
import Countdown from 'react-countdown';
import { useInformation } from '../../../../Providers/informations';
import { resendEmail } from '../../../../Services/auth';
import SnackBar from '../../../Components/General/SnackBar';
import { useNavigate } from 'react-router-dom';
import {
    Container,
    Inputs,
    Middle,
} from './styles';
import axios from 'axios';
import CheckSuccess from '../../../Components/General/Animations/Check';


const VerifyEmail = () => {

    const [hidden, setHidden] = React.useState(true);
    const { info } = useInformation();
    const [emailMask, setEmailMask] = React.useState('');
    const navigate = useNavigate();

    const [code1, setCode1] = React.useState();
    const [code2, setCode2] = React.useState();
    const [code3, setCode3] = React.useState();
    const [code4, setCode4] = React.useState();
    const [code5, setCode5] = React.useState();
    const [code6, setCode6] = React.useState();

    const [success, setSuccess] = React.useState(false);
    const [success2, setSuccess2] = React.useState(false);
    const [error, setError] = React.useState(false);
    const [error2, setError2] = React.useState(false);
    const [error429, setError429] = React.useState(false);

    const renderer = ({ minutes, seconds }) => {
        return <span>{minutes}:{seconds}</span>;
    };

    React.useState(() => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (info && info.ClientEmail && emailRegex.test(info.ClientEmail)) {
            const [username, domain] = info.ClientEmail.split("@");
            const maskedUsername = username[0] + "*".repeat(username.length - 1);
            setEmailMask(maskedUsername + "@" + domain);
        } else {
            // definir um valor padrão para emailMask ou lançar um erro, dependendo dos requisitos da sua aplicação
            return "";
        }
    }, [])

    const resendEmailFunc = async () => {
        try {
            setHidden(true);
            const resendEmailResponse = await resendEmail();
            if (resendEmailResponse.message === "Verification link sent!") {
                setSuccess(true);
                setTimeout(() => {
                    setSuccess(false);
                }, 4000);
            } else {
                setError(true);
                setTimeout(() => {
                    setError(false);
                }, 4000);
            }
        } catch (error) {
            setError(true);
            setTimeout(() => {
                setError(false);
            }, 4000);
        }
    }

    const checkCode = async () => {
        let code = `${code1}${code2}${code3}${code4}${code5}${code6}`
        try {
            var data = {
                "verification_code": code
            }
            const { data: res } = await axios({ method: 'post', url: `${process.env.REACT_APP_LINK}/api/auth/account/verify`, headers: { 'Authorization': `Bearer ${localStorage.getItem("clientToken")}`, }, data })
            if (res.message === "success") {
                setSuccess2(true)
                localStorage.setItem('emailVerified', res?.data?.is_validated);
                setTimeout(() => {
                    navigate('/')
                }, 5000);
            } else {
                setError2(true);
                setTimeout(() => {
                    setError2(false);
                }, 3000);
            }
        } catch (error) {
            setError429(true);
            setTimeout(() => {
                setError429(false);
            }, 3000);
        }
    }

    return (
        <Container>
            {success2 ?
                <Middle>
                    <h2>Tudo certo!</h2>
                    <CheckSuccess />
                    <small>Seu e-mail foi verificado, você será redirecionado para tela inicial em: <Countdown renderer={renderer} date={Date.now() + 5000} autoStart={true} /></small>
                </Middle>
                :
                <Middle>
                    <Email color="#009c43" w="70pt" h="70pt" />
                    <h2>Verifique seu Email</h2>
                    <small>Enviamos um email com o código de validação para {emailMask ? emailMask : "o email cadastrado"}<br /> acesse e insira o mesmo aqui!</small>
                    <Inputs>
                        <div>
                            <input type='text' id="code1" maxLength={1} autocomplete="off"
                                onInput={(e) => { e.target.value = e.target.value.replace(/[^0-9]/g, ''); e.nativeEvent.inputType === "deleteContentBackward" || e.nativeEvent.inputType === "deleteContentBackward" ? document.getElementById("code1").select() : document.getElementById("code2").select(); }}
                                onChange={(e) => setCode1(e.target.value)} />
                            <input type='text' id="code2" maxLength="1" autocomplete="off"
                                onInput={(e) => { e.target.value = e.target.value.replace(/[^0-9]/g, '0'); e.nativeEvent.inputType === "deleteContentBackward" || e.nativeEvent.inputType === "deleteContentBackward" ? document.getElementById("code1").select() : document.getElementById("code3").select(); }}
                                onChange={(e) => setCode2(e.target.value)} />
                            <input type='text' id="code3" maxLength="1" autocomplete="off"
                                onInput={(e) => { e.target.value = e.target.value.replace(/[^0-9]/g, '0'); e.nativeEvent.inputType === "deleteContentBackward" || e.nativeEvent.inputType === "deleteContentBackward" ? document.getElementById("code2").select() : document.getElementById("code4").select(); }}
                                onChange={(e) => setCode3(e.target.value)} />
                        </div>

                        <div>
                            <input type='text' id="code4" maxLength="1" autocomplete="off"
                                onInput={(e) => { e.target.value = e.target.value.replace(/[^0-9]/g, '0'); e.nativeEvent.inputType === "deleteContentBackward" || e.nativeEvent.inputType === "deleteContentBackward" ? document.getElementById("code3").select() : document.getElementById("code5").select(); }}
                                onChange={(e) => setCode4(e.target.value)} />
                            <input type='text' id="code5" maxLength="1" autocomplete="off"
                                onInput={(e) => { e.target.value = e.target.value.replace(/[^0-9]/g, '0'); e.nativeEvent.inputType === "deleteContentBackward" || e.nativeEvent.inputType === "deleteContentBackward" ? document.getElementById("code4").select() : document.getElementById("code6").select(); }}
                                onChange={(e) => setCode5(e.target.value)} />
                            <input type='text' id="code6" maxLength="1" autocomplete="off"
                                onInput={(e) => { e.target.value = e.target.value.replace(/[^0-9]/g, '0'); e.nativeEvent.inputType === "deleteContentBackward" || e.nativeEvent.inputType === "deleteContentBackward" ? document.getElementById("code5").select() : document.getElementById("code6").focus(); }}
                                onChange={(e) => setCode6(e.target.value)} />
                        </div>
                    </Inputs>
                    {!code1 || !code2 || !code3 || !code4 || !code5 || !code6 ?
                        <ButtonGreenSmall disabled style={{ width: '16rem', height: '3rem', fontWeight: '600', fontSize: '0.9rem', marginTop: '10px' }}>Validar Código</ButtonGreenSmall>
                        :
                        <ButtonGreenSmall onClick={() => checkCode()} style={{ width: '16rem', height: '3rem', fontWeight: '600', fontSize: '0.9rem', marginTop: '10px' }}>Validar Código</ButtonGreenSmall>
                    }
                    {error2 && <small style={{ color: '#FA4B57' }}> Código inválido </small>}

                    <div style={{ paddingTop: '15px' }}>
                        {hidden ?
                            <div>
                                <small> Reenviar o email em: </small>
                                <Countdown renderer={renderer} date={Date.now() + 30000} onComplete={() => setHidden(false)} autoStart={true} />
                            </div>
                            :
                            <samll onClick={() => resendEmailFunc()} style={{ fontWeight: '600', fontSize: '0.9rem', color: '#009c43', cursor: 'pointer' }}>Enviar email novamente</samll>
                        }
                    </div>
                </Middle>}

            {success && <SnackBar type="success" message="Email enviado com sucesso" time={4000} states={true} />}
            {error && <SnackBar type="error" message="Não foi possível enviar o email" time={4000} states={true} />}
            {error429 && <SnackBar type="error" message="Desculpa não foi possível validar o código, tente novamente em alguns instantes!" time={4000} states={true} />}
        </Container>
    )
}

export default VerifyEmail;