import React from 'react';
import InputGroup from '../../../General/InputGroup/Group';

export const EditInput = () => {
    return (
        <div></div>
    )
}

export const Disabled = () => {
    return (
        <div>
            <InputGroup label={["Titular da Conta"]} type={["text"]} id={["name"]} value={['********']} customClass={["col"]} group={false} />
            <div className="container">
                <div className="row">
                    <div className="col-3">
                        <label htmlFor="BankCode" className="form-label" style={{ fontSize: '0.8rem' }}>Código do Banco</label>
                        <input type="text" className="form-control" id="BankCode" value="****" disabled />
                    </div>
                    <div className="col-3">
                        <label htmlFor="ag" className="form-label" style={{ fontSize: '0.8rem' }}>Agência</label>
                        <input type="text" className="form-control" id="ag" aria-describedby="agencia"  value="****" disabled />
                    </div>
                    <div className="col-4">
                        <label htmlFor="ag" className="form-label" style={{ fontSize: '0.8rem' }}>Conta</label>
                        <input type="text" className="form-control" id="ag" aria-describedby="agencia" v value="****" disabled />
                    </div>
                    <div className="col-2">
                        <label htmlFor="ag" className="form-label" style={{ fontSize: '0.8rem' }}>Dígito</label>
                        <input type="text" className="form-control" id="ag" aria-describedby="agencia"  value="****" disabled />
                    </div>
                </div>
            </div>
        </div>
    )
}