import React from "react";
import { useLocation } from "react-router-dom";
import { TitleH3 } from '../../../../Styles/style';
import SwipeableViews from 'react-swipeable-views';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import moment from "moment";
import { Table } from "react-bootstrap";
import { getListQuestionStore } from "../../../../Services/storeProducts";
import DeleteIcon from '@mui/icons-material/Delete';
import StoreIcon from '@mui/icons-material/Store';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';

import {
    ListContentOrder,
    TopTitleDiv,
    ContentCard,
    Container,
} from './styles';
import AnswerQuestion from "../../../Components/Store/AnswerQuestion";
import IconBreadcrumbs from "../../../Components/General/Breadcrumbs";


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 2 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}



const Questions = () => {

    const location = useLocation();
    const theme = useTheme();

    const [questionList, setQuestionList] = React.useState([]);

    const [value, setValue] = React.useState(0);

    const getQuestionsList = async () => {
        const response = await getListQuestionStore();
        if (response?.message === "success") {
            setQuestionList(response.data);
        } else {

        }
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleChangeIndex = (index) => {
        setValue(index);
    };

    React.useEffect(() => {
        getQuestionsList();
    }, [location]);

    const where = [
        { where: 'Minha Loja', link: '/loja/perfil', icon: <StoreIcon sx={{ mr: 0.5 }} fontSize="inherit" /> },
        { where: 'Perguntas', link: '/loja/perguntas', color: '#009C43', icon: <ContactSupportIcon sx={{ mr: 0.5 }} fontSize="inherit" /> },
    ]
    return (
        <Container>
            <IconBreadcrumbs list={where} />

            <ContentCard>
                <TopTitleDiv>
                    <div style={{ height: '50%', display: 'flex', justifyContent: 'space-between', padding: '15px', }}>
                        <TitleH3> Perguntas </TitleH3>
                        {/*  <DivFilter style={{ display: 'flex' }}>
                            <p style={{ display: 'flex', alignItems: 'center', padding: '0 10px', height: '100%' }}>Pedidos feitos em</p>  <Select options={options} />
                        </DivFilter> */}
                    </div>
                    <AppBar position="static" sx={{ background: '#ffffff', color: 'black', border: 'none', boxShadow: '0', flex: '1' }}>
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            indicatorColor="success"
                            textColor="inherit"
                            variant="fullWidth"
                            aria-label="full width tabs example"
                        >
                            <Tab label="Abertas" {...a11yProps(0)} />
                            <Tab label="Respondidas" {...a11yProps(1)} />
                        </Tabs>
                    </AppBar>
                </TopTitleDiv>
                <ListContentOrder>
                    <SwipeableViews
                        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                        index={value}
                        onChangeIndex={handleChangeIndex}
                    >
                        <TabPanel value={value} index={0} dir={theme.direction}>
                            <Table>
                                <thead>
                                    <tr>
                                        <th style={{ width: '15vw' }}>
                                            Produto
                                        </th>
                                        <th style={{ width: '15vw', textAlign: 'left' }}>
                                            Data
                                        </th>
                                        <th style={{ width: '35vw' }}>
                                            Pergunta
                                        </th>
                                        <th style={{ width: '35vw' }}>
                                            Resposta
                                        </th>
                                        <th style={{ width: '5vw' }}>
                                            Ação
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {questionList?.open?.map((list) => (
                                        <tr key={list?.id} style={{ height: '50px', transition: 'background-color 0.3s ease-out' }}>
                                            <th scope="row" style={{ width: '15vw' }} /* onClick={() => navigate(`/produto/detalhes/${list?.store_id}/${list?.slug}`)} */>
                                                <div style={{ display: 'flex', width: '15vw' }}>
                                                    <small style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}> {list?.title}</small>
                                                </div>
                                            </th>
                                            <td style={{ width: '15vw', textAlign: 'left' }}>
                                                {moment(list?.created_at).format('DD/MM/YYYY')}
                                            </td>
                                            <td style={{ width: '25vw' }}>
                                                <div style={{ display: 'flex', width: '25vw' }}>
                                                    <small style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{list?.question}</small>
                                                </div>
                                            </td>
                                            <td style={{ width: '25vw' }}>
                                                <div style={{ display: 'flex', width: '25vw' }}>
                                                    <small style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{list?.answer}</small>
                                                </div>
                                            </td>
                                            <td style={{ width: '5vw', }}>
                                                <div style={{ display: 'flex' }}>
                                                    <div><AnswerQuestion question={list?.question} answer={list?.answer} client={list?.client_id} func={getQuestionsList} id={list?.id} /></div>
                                                    <div><DeleteIcon /></div>
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>

                            </Table>
                        </TabPanel>
                        <TabPanel value={value} index={1} dir={theme.direction}>
                            <Table>
                                <thead>
                                    <tr>
                                        <th style={{ width: '10%' }}>
                                            Produto
                                        </th>
                                        <th style={{ width: '10%' }}>
                                            Data
                                        </th>
                                        <th>
                                            Pergunta
                                        </th>
                                        <th>
                                            Resposta
                                        </th>
                                        <th style={{ width: '5vw' }}>
                                            Ação
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {questionList?.answered?.map((list) => (
                                        <tr key={list?.id} style={{ height: '50px', transition: 'background-color 0.3s ease-out' }}>
                                            <th scope="row" style={{ width: '15vw' }}>
                                                <div style={{ display: 'flex', width: '15vw' }}>
                                                    <small style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}> {list?.title}</small>
                                                </div>
                                            </th>
                                            <td style={{ width: '15vw', textAlign: 'left' }}>
                                                {moment(list?.created_at).format('DD/MM/YYYY')}
                                            </td>
                                            <td style={{ width: '25vw' }}>
                                                <div style={{ display: 'flex', width: '25vw' }}>
                                                    <small style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{list?.question}</small>
                                                </div>
                                            </td>
                                            <td style={{ width: '25vw' }}>
                                                <div style={{ display: 'flex', width: '25vw' }}>
                                                    <small style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{list?.answer}</small>
                                                </div>
                                            </td>
                                            <td style={{ width: '5vw', }}>
                                                <div style={{ display: 'flex' }}>
                                                    <div><AnswerQuestion question={list?.question} answer={list?.answer} client={list?.client_id} func={getQuestionsList} id={list?.id} /></div>
                                                    <div><DeleteIcon /></div>
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </TabPanel>
                    </SwipeableViews>
                </ListContentOrder>
            </ContentCard>
        </Container>
    )
}

export default Questions;