import styled from 'styled-components';

export const Container = styled.div`
    padding: 2%;
    padding-bottom: 5%;
    min-height: 100vh;
`;
export const Content = styled.div`
    flex: 1;
    overflow-y: auto;
    height: 100%;
    min-height: 100vh;
    
`;
export const Area = styled.div`
    flex:1;
    height: 100%;
    background-color: #fff ;
    box-shadow: 0px 0px 9px #26242424;
    border-radius: 6px;
    overflow: auto;
    padding: 10px;
`;
export const Search = styled.div`
        width: 100%;
        height: 70%;
        border: 1px solid #A9ABAE;
        border-radius: 50px;
        background-color: #ffffff ;
        display: flex;
        align-items: center;
        padding-left: 8px;
        justify-content: space-between;
        margin-right: 1rem;
    > input {
        width: 80%;
        height: 65%;
        font-size: 1rem;
        padding-left: 20px;
        background-color: #fff ;
        color: #A9ABAE;
    }
    > div {
        background-color: #009c43;
        color: #fff;
        width: 40px;
        height: 99%;
        display: flex;
        align-items: center;
        align-content: center;
        justify-content: center;
        border-radius: 100px;
    }
    >input::placeholder {
            color: #A9ABAE;
        }
        >select{
            background-color: #F1F1F1 ;
        }
`;
export const Top = styled.div`
    justify-content: space-between;
    display: flex;

    @media(max-width: 900px){
        flex-direction: column;
    }
`;
export const Div1 = styled.div`
     display: flex;
     justify-content: space-between;
     padding: 15px;
     width: 50%;

     @media(max-width: 900px){
        width: 100%;
     }
`;
export const Mobile = styled.div`
    display: none;
    @media(max-width: 900px){
        display: flex;
        justify-content: center;
        align-items: center;
    }
`;
export const Web = styled.div`
    display: flex;
    @media(max-width: 900px){
        display: none;
    }
`;
export const Div2 = styled.div`
    width: 50%;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    @media(max-width: 900px){
        width: 100%;
    }
`;