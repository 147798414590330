import React from 'react';
import Address from '../../General/AddressList';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import { useNavigate } from 'react-router-dom';

import {
    Container,
    InfoTable,
    Salles,
    Line,
    SmallCard,
    LineSmall,
    DivCenter,
} from './styles';
import ProfileTabs from '../Configurações/ProfilesTabs';


const ClientDashboard = () => {
    const navigate = useNavigate();
    return (
        <Container>
            {/* <SideBarMenu /> */}
            {/*   <Content> */}
            <Line style={{ overflow: 'auto' }}>
                <InfoTable>
                    <ProfileTabs />
                    {/*  {info.CientType === "customer" && info.ClientStoreStatus !== "pending" ? <SetAsStore /> : "Aguardando Liberação" */}
                </InfoTable>
                <DivCenter style={{}}>
                    <Salles>
                        <Line style={{ justifyContent: 'space-between', display: "flex", paddingTop: '15px', }}>
                            <Address redirect={"/usuario/perfil"} />
                        </Line>
                    </Salles>
                    <LineSmall>
                        <SmallCard onClick={() => navigate('/produtos/cart/lista')}>
                            <ShoppingCartIcon sx={{ width: '60%', height: '60%' }} />
                            <p style={{ color: '#8e8e8e' }}>Meu Carrinho</p>
                        </SmallCard >
                        <SmallCard onClick={() => navigate('/produtos/favoritos/lista')}>
                            <FavoriteIcon sx={{ width: '60%', height: '60%' }} />
                            <p style={{ color: '#8e8e8e' }}>Meus Favoritos</p>
                        </SmallCard>
                        <SmallCard onClick={() => navigate('/usuario/meus_pedidos')}>
                            <ShoppingBagIcon sx={{ width: '60%', height: '60%' }} />
                            <p style={{ color: '#8e8e8e' }}>Minhas Compras</p>
                        </SmallCard>
                    </LineSmall>
                </DivCenter>
            </Line>
            {/*   </Content> */}
        </Container>
    )
}

export default ClientDashboard;