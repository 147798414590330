import styled from "styled-components";

export const Container = styled.div`
    padding: 35px 35px;
    text-align: center;
    width: 15%;
    transition: transform 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    :hover{
        transform: translateY(-5px);
    }
    
    > .Image {
        cursor: pointer;
        justify-content: center; 
        display: flex;
    }
    > .Title {
        padding-top: 10px;
        text-decoration: none;
        color: #6E6E6E;
        font-weight: 700;
        text-transform: uppercase;
    }
`;