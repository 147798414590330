import styled from 'styled-components';

export const Grid = styled.div`
    display: grid;
    background: #F1F1F1;
    grid-template-columns: auto;
    grid-template-rows: auto;
    font-family: Muli,Lato,Helvetica Neue,Helvetica,Arial,sans-serif;
    grid-template-areas:
    'CT CT';
    overflow: hidden;


    
    @media(min-width: 1925px){
        
        grid-template-columns: auto;
        justify-content: center;
        padding: 0;
        margin: 0;
        overflow: hidden;
    }
`;