import React from "react";
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import CancelIcon from '@mui/icons-material/Cancel';

const steps = [
    'Pedido Realizado',
    'Em Processamento',
    'Enviado',
    'Entregue',
];

const steps2 = [
    'Cancelado',
];

const StatusLine = ({ status }) => {

    const [statusStep, setSttatusStep] = React.useState(1);
    const [statusF, setStatusF] = React.useState(false);

    React.useEffect(() => {
        switch (status) {
            case "Em Processamento":
                setSttatusStep(1)
                break;
            case "Enviado":
                setSttatusStep(2)
                break;
            case "Concluído":
                setSttatusStep(4)
                break;
            case "Cancelado":
                setStatusF(true);
            default:
                break;
        }
    }, [])

    return (
        <div >
            <Box sx={{ width: '100%' }}>
                {statusF ?
                    <div>
                        <CancelIcon sx={{ color: "#F54B5E" }} />
                        <br></br>
                        <span style={{ color: '#F54B5E' }}> Pedido Cancelado</span>
                    </div>
                    :
                    <Stepper activeStep={statusStep} alternativeLabel>
                        {steps.map((label) => (
                            <Step key={label}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>}

            </Box>
        </div>
    )
}

export default StatusLine;