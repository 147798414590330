import styled from "styled-components";

export const Container = styled.div`
    grid-area: FT;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    bottom: 0;
    background-color: #009c43;
    height: 300px;
    width: 100%;
   /*  @media(max-width: 1920px){
        height: 380px;
    }
     */
    @media(max-width: 1544px){
        height: 310px;
    }
`;

export const Logo = styled.div`
    padding-top: 15px;
    padding-bottom: 10px ;
    width: 100%;
    padding-left: 15px;
    >img{
        width: 50%;
        @media(max-width: 900px){
            width: 100%;
        }
    }
`;
export const Menu = styled.div`
    flex: 1;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    min-width: 0;
`;
export const MenuLine1 = styled.div`
    display: flex;
    width: calc(100% / 3);
    border-right: 1px #ffffff solid ;
    margin: 15px 0;
    font-weight: 700;
    @media(max-width: 900px){
        margin: 0 5px;
        font-size: 0.8rem;
    }

`;
export const MenuLine2 = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    color: #ffffff;
    padding: 15px 15px ;
    >p{
        cursor: pointer ;
        &:hover{
            color: #333333;
        }
    }
`;
export const Social = styled.div`
    padding-left: 10px ;
    padding: 0 20px;
    width: 80%;
    height: 40%;
    text-align: center;
    align-items: center;
    display: flex;
    justify-content: space-between;

    @media(max-width: 900px){
        width: 100%;
        height: 50%;
        flex-direction: column;
    }

`;

export const Icon = styled.div`
    border-radius: 100px ;
    color: #ffffff;
    &:hover{
       color: #116E44 ;
    }
`;

export const Copyright = styled.div`
    align-content: center;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    width: 100% ;
    text-align: center;
    color: #fff;
    @media(max-width: 900px){
        font-size: 0.7rem;
        margin: 0;
        margin-top: 10px;
    }
`;