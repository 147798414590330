import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
    height: 100%;
    padding: 10px 2px;
`;

export const Card = styled.div`
    width: 100%;
    height: 100%;
    border-radius: 6px;
    background: #ffffff;
    box-shadow: 0px 3px 6px #00000029;
   
`;

export const Div = styled.div`
    width: 100%;
    height: 250px;
    border: 0.8px solid #f5f5f5;
    background: #ffffff;
`;

export const Image = styled.div`
    width: 100%;
    height: 60%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    > img {
        width: 50%;
    }

`;

export const Text = styled.div`
    width: 100%;
    height: 40%;
    padding-top: 2px;
    padding-left: 10px;
    

    >div {
        >h3 {
        font-size: 1rem;
    }
    >h6 {
        font-size: 0.6rem;
        color: #009c43;
        font-weight: 600;
    }
    >p{
        font-size: 0.8rem;
        color: #A9ABAE;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2; /* Defina o número máximo de linhas que deseja mostrar */
        -webkit-box-orient: vertical;
    }
    }
`;