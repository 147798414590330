import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { ButtonGreen } from '../../../../Styles/stylesButtons';
import {
    Container,
    Left,
    Right,
    Line,
    CardLine,
    Div,
    P,
    DivGreen,
} from './styles'
import { getAds } from '../../../../Services/publics';

const Advertising = () => {

    const [homeAdbig, setHomeAdBig] = React.useState([]);
    const [homeAdSmall, setHomeAdsSmall] = React.useState([]);


    const getAdsList = async () => {
        const big = [];
        const small = [];
        try {
            const response = await getAds('home');
            response?.data?.banners?.forEach(element => {
                if (element.position === 3 || element.position === 4 || element.position === 5 || element.position === 6) {
                    small.push(element)
                } if (element.position === 2) {
                    big.push(element)
                }
            });
            setHomeAdBig(big)
            setHomeAdsSmall(small)
        } catch (error) {

        }
    }

    React.useEffect(() => {
        getAdsList();
    }, [])
    return (
        <Container>
            <Div>
                <Left>
                    <Card sx={{ width: '100%', height: '100%' }}>
                        <CardMedia
                            component="img"
                            alt="banner"
                            sx={{ height: '60%', borderRadius: '0 0 6px 6px' }}
                            image={homeAdbig[0]?.image}
                        />
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div">
                                <P>O Marktplace Agro que você esperava! </P>
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                <DivGreen style={{}}>
                                    <ButtonGreen style={{
                                        borderRadius: '25px', color: '#FFF',
                                        fontStyle: 'normal',
                                        fontWeight: '700',
                                        lineHeight: 'normal',
                                    }}>
                                        Frete Grátis
                                    </ButtonGreen>
                                    <small>*Confira termos e condições*</small>
                                </DivGreen>
                            </Typography>
                        </CardContent>
                    </Card>

                </Left>
                <Right>
                    <Line style={{ paddingBottom: '2%', }}>
                        {homeAdSmall.slice(0, 2).map((list) => (
                            <CardLine key={list.title}>
                                <Card sx={{ width: '100%', height: '100%' }} onClick={() => window.open(list?.action ? list?.action : '/', list?.target ? list?.target : '_self')}>
                                    <CardMedia
                                        component="img"
                                        alt={list.title}
                                        sx={{ height: '100%', borderRadius: '6px' }}
                                        image={list?.image}
                                    />
                                </Card>
                            </CardLine>
                        ))}
                    </Line>
                    <Line style={{ paddingTop: '2%' }}>
                        {homeAdSmall.slice(2, 4).map((list) => (
                            <CardLine key={list.title}>
                                <Card sx={{ width: '100%', height: '100%' }} onClick={() => window.open(list?.action ? list?.action : '/', list?.target ? list?.target : '_self')}>
                                    <CardMedia
                                        component="img"
                                        alt={list.title}
                                        sx={{ height: '100%', borderRadius: '6px' }}
                                        image={list?.image}
                                    />
                                </Card>
                            </CardLine>
                        ))}
                    </Line>
                </Right>
            </Div>
        </Container>
    )
}

export default Advertising;