import axios from "axios"

export async function addViewedProduct(slug, storeSlug) {
    try {
        const { data: res } = axios({ method: 'get', url: `${process.env.REACT_APP_LINK}/api/user/viewed/add/${storeSlug}/${slug}`, headers: { 'Authorization': `Bearer ${localStorage.getItem("clientToken")}` } })
        return res
    } catch (error) {
        throw error.response
    }
}

export async function addToCart(slug, amount, storeSlug) {
    try {
        var data = {
            quantity: amount
        }
        const { data: res } = await axios({ method: 'post', url: `${process.env.REACT_APP_LINK}/api/user/cart/add/${storeSlug}/${slug}`, headers: { 'Authorization': `Bearer ${localStorage.getItem("clientToken")}` }, data })
        return res
    } catch (error) {
        throw error.response
    }
}

export async function addToFavorite(slug, storeSlug) {
    const { data: res } = await axios({ method: 'get', url: `${process.env.REACT_APP_LINK}/api/user/favorites/add/${storeSlug}/${slug}`, headers: { 'Authorization': `Bearer ${localStorage.getItem("clientToken")}` } })
    return res
}

export async function removeFavorite(slug, storeSlug) {
    const { data: res } = await axios({ method: 'get', url: `${process.env.REACT_APP_LINK}/api/user/favorites/remove/${storeSlug}/${slug}`, headers: { 'Authorization': `Bearer ${localStorage.getItem("clientToken")}` } })
    return res
}

export async function rateProduct(store, product, stars, description, order) {
    var data = {
        "description": description,
        "stars": stars,
        "order": order
    }
    const { data: res } = await axios({ method: 'post', url: `${process.env.REACT_APP_LINK}/api/user/review/${store}/${product}`, headers: { 'Authorization': `Bearer ${localStorage.getItem("clientToken")}` }, data })
    return res
}

export async function postQuestion(question, store, product) {
    var data = {
        "question": question,
    }
    const { data: res } = await axios({ method: 'post', url: `${process.env.REACT_APP_LINK}/api/user/ask/${store}/${product}`, headers: { 'Authorization': `Bearer ${localStorage.getItem("clientToken")}` }, data })
    return res
}