import React from 'react';
import { Container, ContentSection } from './styles';
import MainHeader from '../MainHeader';
import { useLocation } from 'react-router-dom';

const Content = ({ children }) => {

    const location = useLocation();
    const [showHeader, setShow] = React.useState(false);

    React.useEffect(() => {
        if (window.location.href.split('/')[5] !== "login") {
            setShow(true)
        } else {
            setShow(false)
        }
    }, [location])

    return (
        <Container>
            {showHeader ?
                <>
                    <MainHeader />
                    <ContentSection style={{ height: 'calc(100% - 80px)' }}>
                        {children}
                    </ContentSection>
                </> :
                <>
                    <ContentSection>
                        {children}
                    </ContentSection>
                </>
            }



        </Container>
    );
}

export default Content;