import React from 'react';
import AdmSideBarMenu from '../../Components/SideBarMenu';
import Divider from '@mui/material/Divider';



import {
    Container,
    Content,
    InfoTable,
    Salles,
    Line,
    SmallCard,
    BigCard,
    DisplayNumber
} from './styles';


const AdmDashboard = () => {
    return (
        <Container>
            <div style={{ padding: '10px 5px' }}>
                <AdmSideBarMenu />
            </div>
            <Content>
                <Line>
                    <BigCard>
                        <Line style={{ justifyContent: 'space-between', display: "flex", paddingTop: '15px', }}>
                            <div style={{ paddingLeft: '25px' }}>
                                Informações do Site
                            </div>
                        </Line>
                        <Divider />
                    </BigCard>
                    <BigCard>

                    </BigCard>
                    <BigCard>

                    </BigCard>
                </Line>

                <Line>
                    <SmallCard>
                        <Line style={{ justifyContent: 'space-between', display: "flex", paddingTop: '15px', }}>
                            <div style={{ paddingLeft: '25px' }}>
                                Total de Clientes
                            </div>
                        </Line>
                        <Divider />
                        <DisplayNumber>
                            <h1>1500</h1>
                        </DisplayNumber>
                    </SmallCard>
                    <SmallCard>
                        <Line style={{ justifyContent: 'space-between', display: "flex", paddingTop: '15px', }}>
                            <div style={{ paddingLeft: '25px' }}>
                                Total de Lojas
                            </div>
                        </Line>
                        <Divider />
                        <DisplayNumber>
                            <h1>1500</h1>
                        </DisplayNumber>
                    </SmallCard>
                    <SmallCard>
                        <Line style={{ justifyContent: 'space-between', display: "flex", paddingTop: '15px', }}>
                            <div style={{ paddingLeft: '25px' }}>
                                Total de Produtos
                            </div>
                        </Line>
                        <Divider />
                        <DisplayNumber>
                            <h1>1500</h1>
                        </DisplayNumber>
                    </SmallCard>
                    <SmallCard>
                        <Line style={{ justifyContent: 'space-between', display: "flex", paddingTop: '15px', }}>
                            <div style={{ paddingLeft: '25px' }}>
                                Total em Vendas
                            </div>
                        </Line>
                        <Divider />
                        <DisplayNumber>
                            <h1>1500</h1>
                        </DisplayNumber>
                    </SmallCard>
                </Line>

                <Line>
                    <InfoTable>
                        <Line style={{ justifyContent: 'space-between', display: "flex", paddingTop: '15px', }}>
                            <div style={{ paddingLeft: '25px' }}>
                                Informações de Checkout
                            </div>
                        </Line>
                        <Divider />
                        <div className="container">
                            <div className="row">
                                <div className="col-3">
                                    <label htmlFor="BankCode" className="form-label" style={{ fontSize: '0.8rem' }}>Código do Banco</label>
                                    <input type="text" className="form-control" id="BankCode" value="****" disabled />
                                </div>
                                <div className="col-3">
                                    <label htmlFor="ag" className="form-label" style={{ fontSize: '0.8rem' }}>Agência</label>
                                    <input type="text" className="form-control" id="ag" aria-describedby="agencia" value="****" disabled />
                                </div>
                                <div className="col-4">
                                    <label htmlFor="ag" className="form-label" style={{ fontSize: '0.8rem' }}>Conta</label>
                                    <input type="text" className="form-control" id="ag" aria-describedby="agencia" v value="****" disabled />
                                </div>
                                <div className="col-2">
                                    <label htmlFor="ag" className="form-label" style={{ fontSize: '0.8rem' }}>Dígito</label>
                                    <input type="text" className="form-control" id="ag" aria-describedby="agencia" value="****" disabled />
                                </div>
                            </div>
                        </div>
                    </InfoTable>
                    <Salles>
                        <Line style={{ justifyContent: 'space-between', display: "flex", paddingTop: '15px', }}>
                            <div style={{ paddingLeft: '25px' }}>
                                Taxas
                            </div>
                        </Line>
                        <Divider />
                    </Salles>
                </Line>


            </Content>
        </Container>
    )
}

export default AdmDashboard;