import React from 'react';
import Button from '@mui/material/Button';
import { Modal } from 'react-bootstrap';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Typography from '@mui/material/Typography';
import Select from 'react-select';
import { ButtonBlueSmall, ButtonGreenSmall } from '../../../../Styles/stylesButtons';
import { TitleH6 } from '../../../../Styles/style';
import { formatarMoeda } from '../../../../Services/payment';
import { showProduct, createNewProduct, editProduct, getCategoriesStore, getCalcValue } from '../../../../Services/storeProducts';
import EditIcon from '@mui/icons-material/Edit';
import SnackBar from '../../General/SnackBar';
import CardProduct from '../../General/CardProduct';
import TablePrice from './TablePrice';
import Editor from '../../General/InputGroup/Editor';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Tooltip from '@mui/material/Tooltip';

import {
  Container,
} from './styles';

const steps = ['Informações do produto', 'Precificação', 'Fotos do produto', 'Preview do anúncio'];


const NewProduct = ({ locantion, operation, productSlug, after }) => {
  const [show, setShow] = React.useState(false);

  const [salesPrice, setSalesPrice] = React.useState([]);
  /* Steps */
  const [activeStep, setActiveStep] = React.useState(0);
  const handleNext = () => {
    if (activeStep + 1 === 1) {
      getCalValues()
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const handleReset = () => {
    setActiveStep(0);
  };
  /* Steps */

  const getCalValues = async () => {
    try {
      const response = await getCalcValue(price2, taxCategory)
      setSalesPrice(response?.data);
    } catch (error) {

    }
  }

  const options = [
    { value: 1, label: 'Ativo' },
    { value: 0, label: 'Inativo' },
  ]

  const [category, setCategory] = React.useState();
  const [titleP, setTitleP] = React.useState();
  const [slug, setSlug] = React.useState();
  /* const [unit, setUnit] = React.useState(); */
  const [price, setPrice] = React.useState();
  const [price2, setPrice2] = React.useState()
  /* const [minimal, setMinimal] = React.useState(); */
  const [status, setStatus] = React.useState();
  const [specifications, setSpecifications] = React.useState();
  const [description, setDescription] = React.useState();
  const [stock, setStock] = React.useState();
  const [img1, setImage1] = React.useState();
  const [img2, setImage2] = React.useState();
  const [img3, setImage3] = React.useState();
  const [img4, setImage4] = React.useState();
  const [img5, setImage5] = React.useState();
  const [img6, setImage6] = React.useState();

  const [image1, setImg1] = React.useState();
  const [image2, setImg2] = React.useState();
  const [image3, setImg3] = React.useState();
  const [image4, setImg4] = React.useState();
  const [image5, setImg5] = React.useState();
  const [image6, setImg6] = React.useState();

  const [taxCategory, setTaxCategory] = React.useState();

  const [categoryList, setCategoryList] = React.useState([]);

  const handleClose = () => setShow(false);
  const handleShow = () => {
    getCategories();
    setShow(true);
  }

  const [success, setSuccess] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [errorName, setErrorName] = React.useState(false);

  const createProduct = async (titleN, descriptionN, slugN, specificationsN, categoryP, price, status, stock) => {
    try {
      const createProduct = await createNewProduct(titleN, descriptionN, slugN, specificationsN, categoryP, price2, status, image1, image2, image3, image4, image5, image6, stock, salesPrice?.sales_price)
      setSuccess(true);
      handleClose();
      handleReset();
      cleanState();
      after();
      setTimeout(() => {
        setSuccess(false)
      }, 3000);
    } catch (error) {
      error.response.data.message === "The slug has already been taken." ? setErrorName(true) : setError(true)
      setTimeout(() => {
        setError(false);
        setErrorName(false);
      }, 6000);
    }
  }

  const funcEditProduct = async (titleN, descriptionN, slugN, specificationsN, categoryP, price2, status, stock) => {
    try {
      const response = await editProduct(titleN, descriptionN, slugN, specificationsN, categoryP, price2, status, image1, image2, image3, image4, image5, image6, productSlug, stock, salesPrice?.sales_price)
      if (response) {
        setSuccess(true);
        handleClose();
        handleReset();
        cleanState();
        after();
        setTimeout(() => {
          setSuccess(false)
        }, 3000);
      } else {
        setError(true);
        setTimeout(() => {
          setError(false);
        }, 3000);
      }
    } catch (error) {
      console.log(error.response);
      setError(true);
      setTimeout(() => {
        setError(false);
      }, 3000);
    }

  }

  const getCategories = async () => {
    const response = await getCategoriesStore()
    setCategoryList(response?.data?.categories);
    categoryOprions = response?.data?.categories?.map(category => ({ label: category.title, value: category.id }));
  }
  const getCategoriesEdit = async () => {
    const response = await getCategoriesStore()
    return response?.data?.categories
  }

  //Image Upload //
  const handleChangeImg = (event) => {
    var roundTo = null;
    var converted = event.target.files[0]?.size / (1024 * 1024);
    roundTo = converted.toFixed(roundTo);
    if (roundTo > 2) {
      event.target.value = null;
      /*  SetErrorSize(true); */
    } else {
      if (event.target.id === "img1") {
        setImage1(URL.createObjectURL(event?.target?.files[0]));
        let files = event.target.files;
        let filesReader = new FileReader();
        filesReader.readAsDataURL(files[0]);
        filesReader.onload = (event) => {
          setImg1(event.target.result);
        }
      }
      if (event.target.id === "img2") {
        setImage2(URL.createObjectURL(event?.target?.files[0]));
        let files = event.target.files;
        let filesReader = new FileReader();
        filesReader.readAsDataURL(files[0]);
        filesReader.onload = (event) => {
          setImg2(event?.target?.result);
        }
      }
      if (event.target.id === "img3") {
        setImage3(URL.createObjectURL(event?.target?.files[0]));
        let files = event.target.files;
        let filesReader = new FileReader();
        filesReader.readAsDataURL(files[0]);
        filesReader.onload = (event) => {
          setImg3(event.target.result);
        }
      }
      if (event.target.id === "img4") {
        setImage4(URL.createObjectURL(event?.target?.files[0]));
        let files = event.target.files;
        let filesReader = new FileReader();
        filesReader.readAsDataURL(files[0]);
        filesReader.onload = (event) => {
          setImg4(event.target.result);
        }
      }
      if (event.target.id === "img5") {
        setImage5(URL.createObjectURL(event?.target?.files[0]));
        let files = event.target.files;
        let filesReader = new FileReader();
        filesReader.readAsDataURL(files[0]);
        filesReader.onload = (event) => {
          setImg5(event.target.result);
        }
      }
      if (event.target.id === "img6") {
        setImage6(URL.createObjectURL(event?.target?.files[0]));
        let files = event.target.files;
        let filesReader = new FileReader();
        filesReader.readAsDataURL(files[0]);
        filesReader.onload = (event) => {
          setImg6(event.target.result);
        }
      }
    }
  }

  const cleanState = () => {
    setCategory("");
    setTitleP("");
    setSlug("");
    setStock("");
    /*  setUnit(""); */
    setPrice("");
    setPrice2("");
    /*  setMinimal(""); */
    setStatus("");
    setSpecifications("");
    setDescription("");
    setImage1("");
    setImage2("");
    setImage3("");
    setImage4("");
    setImage5("");
    setImage6("");
    setImg1("");
    setImg2("");
    setImg3("");
    setImg4("");
    setImg5("");
    setImg6("");
  }

  /* const setValue = () => {
    if (typeof str == 'number') return str;
    var nr;
    var virgulaSeparaDecimais = str.match(/(,)\d{2}$/);
    if (virgulaSeparaDecimais) nr = str.replace(/\./g, '').replace(',', '.')
    else nr = str.replace(',', '').replace('.', '');
    return parseFloat(nr / 100);
  } */

  const setPriceToFormat = async (e) => {
    var nr;
    const priceReturn = await formatarMoeda(e);
    setPrice(priceReturn);

    if (typeof e !== 'number') {
      var virgulaSeparaDecimais = priceReturn.match(/,\d{2}$/);
      if (virgulaSeparaDecimais) {
        nr = priceReturn.replace(/\./g, '').replace(',', '');
      }
      setPrice2(nr);
    } else {
      setPrice2(e);

    }

  }

  let categoryOprions = categoryList?.map(category => ({ label: category.title, value: category.id }));

  const categorieSet = (opt) => {
    setCategory(opt);
    const cts = categoryList?.find(cate => cate.id === opt?.value)
    setTaxCategory(cts?.commission)
  }

  /* edit */

  const setEdit = async () => {
    const categoryResponse = await getCategoriesEdit();
    handleShow();
    const getProductResponse = await showProduct(productSlug);
    setCategory(categoryOprions?.find(cate => cate.value === getProductResponse?.data?.products?.category_id));
    setStock(getProductResponse?.data?.products?.stock);
    const cts = categoryResponse?.find(cate => cate.id === getProductResponse?.data?.products?.category_id);
    setTaxCategory(cts.commission)
    operation === "copy" ? setTitleP(getProductResponse?.data?.products?.title + " copia") : setTitleP(getProductResponse?.data?.products?.title);
    if (!getProductResponse?.data?.products?.specifications) {
      setSpecifications("Produto sem especificações")
    } else {
      setSpecifications(getProductResponse?.data?.products?.specifications);
    }
    setSlug(getProductResponse?.data?.products?.product_slug);
    setPriceToFormat(getProductResponse?.data?.products?.price);
    setStatus(options.find(opt => opt?.value === getProductResponse?.data?.products?.status));
    setDescription(getProductResponse?.data?.products?.description);
    setImage1(`${process.env.REACT_APP_LINK}/${getProductResponse?.data?.products?.images?.[0]}`);
    setImage2(`${process.env.REACT_APP_LINK}/${getProductResponse?.data?.products?.images?.[1]}`);
    setImage3(`${process.env.REACT_APP_LINK}/${getProductResponse?.data?.products?.images?.[2]}`);
    setImage4(`${process.env.REACT_APP_LINK}/${getProductResponse?.data?.products?.images?.[3]}`);
    setImage5(`${process.env.REACT_APP_LINK}/${getProductResponse?.data?.products?.images?.[4]}`);
    setImage6(`${process.env.REACT_APP_LINK}/${getProductResponse?.data?.products?.images?.[5]}`);
  }

  return (
    <Container>
      {operation === "edit" &&
        <Tooltip title="Editar Produto">
          <EditIcon onClick={setEdit} sx={{ color: '#009C43' }} />
        </Tooltip>
      }

      {operation === "copy" &&
        <Tooltip title="Duplicar Produto">
          <ContentCopyIcon onClick={setEdit} sx={{ color: '#009C43' }} />
        </Tooltip>
      }

      {locantion !== "outside" && operation !== "edit" && operation !== "copy" &&
        <ButtonGreenSmall style={{ width: '12rem', height: '1.8rem' }} variant="contained" onClick={handleShow}>
          Adicionar Produto
        </ButtonGreenSmall>}
      {locantion === "outside" &&
        <AddCircleIcon onClick={handleShow} style={{ cursor: 'pointer', color: 'green' }} />}


      <Modal show={show} onHide={handleClose} size="lg" style={{ height: '100%' }} scrollable={true} >
        <Modal.Header closeButton>
          <Modal.Title><TitleH6>
            {operation === "edit" && "Editar Produto"}
            {operation === "copy" && "Duplicar Produto"}
            {operation !== "edit" && operation !== "copy" && "Adicionar Produto"}
          </TitleH6></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Box sx={{ width: '100%' }}>
            <Stepper activeStep={activeStep}>
              {steps.map((label, index) => {
                const stepProps = {};
                const labelProps = {};
                return (
                  <Step key={index} {...stepProps}>
                    <StepLabel {...labelProps}>{label}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>
            {activeStep === steps.length ? (
              <React.Fragment>
                <Typography sx={{ mt: 2, mb: 1 }}>
                  All steps completed - you&apos;re finished
                </Typography>
                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                  <Box sx={{ flex: '1 1 auto' }} />
                  <Button onClick={handleReset}>Reset</Button>
                </Box>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Typography sx={{ mt: 2, mb: 1 }}>
                  {activeStep === 0 &&
                    <div>
                      <div className='row'>
                        <div className='col-8'>
                          <div className="mb">
                            <label htmlFor="title" className="form-label" style={{ color: '#9e9e9e' }} >Título</label>
                            <input type="text" maxLength="100" className="form-control" value={titleP} placeholder="Nome do Produto" onChange={(e) => setTitleP(e.target.value)} id="title" />
                          </div>
                        </div>
                        <div className='col-4'>
                          <div className="mb-3">
                            <label className="form-label" style={{ color: '#9e9e9e' }}>Categoria</label>
                            <Select options={categoryOprions} id="categorys" value={category} onChange={opt => categorieSet(opt)} />
                          </div>
                        </div>
                      </div>
                      <div className='row'>
                        <div className="col-12">
                          <div className="mb-3">
                            <label htmlFor="descriptionArea" className="form-label" style={{ color: '#9e9e9e' }}>Subtítulo</label>
                            <textarea className="form-control" id="descriptionArea" placeholder="Descrição do Produto" rows="2" value={description} onChange={(e) => setDescription(e.target.value)}></textarea>
                          </div>
                        </div>
                      </div>
                      <div className='row'>
                        {/* Status */}
                        <div className='col-4'>
                          <div className="mb-3">
                            <label htmlFor="status" className="form-label" style={{ color: '#9e9e9e' }}>Status</label>
                            <Select options={options} id="status" onChange={opt => setStatus(opt)} value={status} />
                          </div>
                        </div>
                        {/* Stock */}
                        <div className='col-4'>
                          <div className="mb-3">
                            <label htmlFor="stock" className="form-label" style={{ color: '#9e9e9e' }}>Estoque</label>
                            <input type="number" className="form-control" value={stock} placeholder="Estoque" onChange={(e) => setStock(e.target.value)} id="stock" />
                          </div>
                        </div>
                        {/* Price */}
                        <div className="col-4">
                          <div className="mb-3">
                            <label htmlFor="price" className="form-label" style={{ color: '#9e9e9e' }}>Preço</label>
                            <input type="text" maxLength={12} className="form-control" id="price" placeholder='R$: ' value={price} onChange={(e) => { setPriceToFormat(e.target.value) }} />
                            <small style={{ fontSize: '0.6rem', color: '#F54B5E' }}>{price2 / 100 < 5 && "O preço do produto deve ser superior a R$ 5,00"}</small>
                            {/* <TablePrice price={price} setPriceFunction={setPriceToFormat} tax={taxCategory} /> */}
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col">
                          <div className="mb-6">
                            <label htmlFor="description" className="form-label" style={{ color: '#9e9e9e' }}>Descrição/Especificações</label>
                            {!operation && (
                              <Editor func={setSpecifications} value={specifications} valueDef={specifications} />
                            )}
                            {(operation === "edit" || operation === "copy") && specifications && (
                              <Editor func={setSpecifications} value={specifications} valueDef={specifications} />
                            )}

                          </div>
                        </div>
                      </div>
                    </div>
                  }

                  {activeStep === 1 &&
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <TablePrice price={salesPrice} price2={price2} />
                    </div>
                  }

                  {activeStep === 2 &&
                    <div style={{ paddingLeft: '60px' }}>
                      <div className='row'>
                        <div className='col'>

                          <label htmlFor="img1" style={{ width: '150px', height: '180px', cursor: 'pointer', backgroundColor: '#b8b8b8', backgroundSize: 'contain', backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundImage: `url(${img1})`, textAlign: 'center', borderRadius: '6px' }}>
                            <input onChange={handleChangeImg} accept="image/*" style={{ opacity: '0', width: '100%', height: '50%', }} id="img1" type="file" name="image" />
                            <div style={{ backgroundColor: 'rgba(255,255,255,0.5)', display: 'inline-block', padding: '5px' }}>{/* <PhotoCamera /> */}<br></br>Add Imagem</div>
                          </label>

                        </div>
                        <div className='col'>
                          <label htmlFor="img2" style={{ width: '150px', height: '180px', cursor: 'pointer', backgroundColor: '#b8b8b8', backgroundSize: 'contain', backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundImage: `url(${img2})`, textAlign: 'center', borderRadius: '6px' }}>
                            <input onChange={handleChangeImg} accept="image/*" style={{ opacity: '0', width: '100%', height: '50%', }} id="img2" type="file" />
                            <div style={{ backgroundColor: 'rgba(255,255,255,0.5)', display: 'inline-block', padding: '5px' }}>{/* <PhotoCamera /> */}<br></br>Add Imagem</div>
                          </label>
                        </div>
                        <div className='col'>
                          <label htmlFor="img3" style={{ width: '150px', height: '180px', cursor: 'pointer', backgroundColor: '#b8b8b8', backgroundSize: 'contain', backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundImage: `url(${img3})`, textAlign: 'center', borderRadius: '6px' }}>
                            <input onChange={handleChangeImg} accept="image/*" style={{ opacity: '0', width: '100%', height: '50%', }} id="img3" type="file" />
                            <div style={{ backgroundColor: 'rgba(255,255,255,0.5)', display: 'inline-block', padding: '5px' }}>{/* <PhotoCamera /> */}<br></br>Add Imagem</div>
                          </label>
                        </div>
                      </div>
                      <div style={{ padding: '10px 0' }} />
                      <div className='row'>
                        <div className='col'>
                          <label htmlFor="img4" style={{ width: '150px', height: '180px', cursor: 'pointer', backgroundColor: '#b8b8b8', backgroundSize: 'contain', backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundImage: `url(${img4})`, textAlign: 'center', borderRadius: '6px' }}>
                            <input onChange={handleChangeImg} accept="image/*" style={{ opacity: '0', width: '100%', height: '50%', }} id="img4" type="file" />
                            <div style={{ backgroundColor: 'rgba(255,255,255,0.5)', display: 'inline-block', padding: '5px' }}>{/* <PhotoCamera /> */}<br></br>Add Imagem</div>
                          </label>
                        </div>
                        <div className='col'>
                          <label htmlFor="img5" style={{ width: '150px', height: '180px', cursor: 'pointer', backgroundColor: '#b8b8b8', backgroundSize: 'contain', backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundImage: `url(${img5})`, textAlign: 'center', borderRadius: '6px' }}>
                            <input onChange={handleChangeImg} accept="image/*" style={{ opacity: '0', width: '100%', height: '50%', }} id="img5" type="file" />
                            <div style={{ backgroundColor: 'rgba(255,255,255,0.5)', display: 'inline-block', padding: '5px' }}>{/* <PhotoCamera /> */}<br></br>Add Imagem</div>
                          </label>
                        </div>
                        <div className='col'>
                          <label htmlFor="img6" style={{ width: '150px', height: '180px', cursor: 'pointer', backgroundColor: '#b8b8b8', backgroundSize: 'contain', backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundImage: `url(${img6})`, textAlign: 'center', borderRadius: '6px' }}>
                            <input onChange={handleChangeImg} accept="image/*" style={{ opacity: '0', width: '100%', height: '50%', }} id="img6" type="file" />
                            <div style={{ backgroundColor: 'rgba(255,255,255,0.5)', display: 'inline-block', padding: '5px' }}>{/* <PhotoCamera /> */}<br></br>Add Imagem</div>
                          </label>
                        </div>
                      </div>
                    </div>
                  }
                  {activeStep === 3 &&
                    <div style={{ paddingLeft: '25%' }}>
                      <CardProduct title={titleP} img={img1} price={(salesPrice?.sales_price / 100)} description={description} />
                    </div>
                  }
                </Typography>
                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                  <Button
                    color="inherit"
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    sx={{ mr: 1, borderRadius: '20px' }}
                  >
                    Voltar
                  </Button>
                  <Box sx={{ flex: '1 1 auto' }} />
                  <ButtonGreenSmall onClick={activeStep === steps.length - 1 ? () => operation === "edit" ? funcEditProduct(
                    titleP, description, slug, specifications, category?.value, price2, status, stock,
                  ) : createProduct(
                    titleP, description, slug, specifications, category?.value, price2, status, stock,
                  ) : handleNext}>
                    {activeStep === steps.length - 1 ? operation === "edit" ? "Editar" : 'Salvar' : 'Próximo'}
                  </ButtonGreenSmall>
                </Box>
              </React.Fragment>
            )}
            {error && <SnackBar type="error" time={3000} message="Não foi possível completar o cadastro" states={true} />}
            {errorName && <SnackBar type="error" time={6000} message="Não foi possível criar o produto com esse nome" states={true} />}
            {success && <SnackBar type="success" time={3000} message="Salvo com sucesso" states={true} />}
          </Box>
        </Modal.Body>
      </Modal>
    </Container>
  )
}

export default NewProduct;