import React from 'react';
import List from '@mui/material/List';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import NewProduct from '../../../../Components/Store/NewProduct';
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import Typography from '@mui/material/Typography';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import DeleteIcon from '@mui/icons-material/Delete';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { TitleH3, TitleH6 } from '../../../../../Styles/style';
import { ButtonSilverSmall, ButtonRedSmall } from '../../../../../Styles/stylesButtons';
import { deletePeoduct } from '../../../../../Services/storeProducts';
import SnackBar from '../../../../Components/General/SnackBar';
import { Modal } from 'react-bootstrap';
import StoreIcon from '@mui/icons-material/Store';
import InventoryIcon from '@mui/icons-material/Inventory';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import isMobile from '../../../../../Services/isMobile';

import {
    Container,
    Area,
    Content,
    Search,
    Top,
    Div1,
    Div2,
    Mobile,
    Web,
} from './styles';
import IconBreadcrumbs from '../../../../Components/General/Breadcrumbs';
import { useInformation } from '../../../../../Providers/informations';
import localforage from 'localforage';
import { Grid, Tooltip } from '@mui/material';


const ProductsLIst = () => {
    const location = useLocation();
    const navigate = useNavigate()

    const { getStoreP, setGetStoreP } = useInformation();

    const [productsList, setProductsList] = React.useState([]);
    const [totalProducts, setTotalProducts] = React.useState();
    const [totalPages, setTotalPages] = React.useState();
    const [nexProducts, setNextProducts] = React.useState();

    const getProductsList = () => {
        try {
            axios({ method: 'get', url: `${process.env.REACT_APP_LINK}/api/store/products`, headers: { 'Authorization': `Bearer ${localStorage.getItem('clientToken')}` } })
                .then(res => {
                    setProductsList(res.data.data);
                    setTotalPages(res?.data?.meta?.last_page);
                    setTotalProducts(res?.data?.meta?.total);
                    setNextProducts(res.data.meta.links);
                    localforage.setItem('storeProductsList', res.data);
                    setGetStoreP(false);
                    setPage(1)

                })
                .catch(err => { })
        } catch (error) { }
    }

    const localInfo = async () => {
        let listProducts = await localforage.getItem('storeProductsList');
        setProductsList(listProducts?.data);
        setTotalPages(listProducts?.meta.last_page);
        setNextProducts(listProducts?.meta.links);
    }

    const getNextpage = async (page) => {
        const { data: res } = await axios({ method: 'get', url: `${page}`, headers: { 'Authorization': `Bearer ${localStorage.getItem('clientToken')}` } })
        setProductsList(res.data);

        let listProducts = await localforage.getItem('storeProductsList');
        setTotalPages(listProducts?.meta.last_page);
        setNextProducts(listProducts?.meta.links);
        setPage(parseInt(localStorage.getItem('pageNumber')))
    }

    React.useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        if (searchParams.get('page')) {
            getNextpage(searchParams.get('page'));
        } else {
            if (getStoreP) {
                getProductsList();
            } else {
                localInfo();
            }
            setPage(1)
        }
    }, [location])

    const nextPage = async (value) => {
        navigate(`${location.pathname}?page=${nexProducts?.[value]?.url}`)

        localStorage.setItem('locationPage', `${location.pathname}?page=${nexProducts?.[value]?.url}`)
        localStorage.setItem('pageNumber', value);
    }
    const [page, setPage] = React.useState(1);
    const handleChange = (event, value) => {
        setPage(value);
        nextPage(value);
    };


    const [show, setShow] = React.useState(false);
    const [deleteSeccess, setDeleteSucess] = React.useState(false);
    const [deleteError, setDeleteError] = React.useState(false);
    const [productToDelete, setProductToDelete] = React.useState();
    const [productname, setProductName] = React.useState();

    const confirmDelete = (slug, name) => {
        setProductToDelete(slug)
        setProductName(name);
        setShow(true);

    }

    const deleteProduct = async () => {
        try {
            const deleteResponse = await deletePeoduct(productToDelete)
            if (deleteResponse?.message === "success" && deleteResponse?.status_code === 200) {
                setDeleteSucess(true);
                handleClose()
                getProductsList();
                setTimeout(() => {
                    setDeleteSucess(false);
                }, 4000);
            } else {
                setDeleteError(true);
                handleClose()
                setTimeout(() => {
                    setDeleteError(false);
                }, 4000);
            }
        } catch (error) {
            setDeleteError(true);
            handleClose()
            setTimeout(() => {
                setDeleteError(false);
            }, 4000);
        }
    }

    const handleClose = () => {
        setProductToDelete()
        setProductName();
        setShow();
    }


    React.useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        if (!searchParams.get('page')) {
            if (getStoreP) {
                getProductsList();
            }
        }
    }, [getStoreP])

    const options = { style: 'currency', currency: 'BRL' };

    const where = [
        { where: 'Minha Loja', link: '/loja/perfil', icon: <StoreIcon sx={{ mr: 0.5 }} fontSize="inherit" /> },
        { where: 'Produtos', link: '/produtos/lista', color: '#009C43', icon: <InventoryIcon sx={{ mr: 0.5 }} fontSize="inherit" /> },
    ]
    return (
        <Container>
            {/*  <SideBarMenu /> */}
            <IconBreadcrumbs list={where} />

            <Content>
                <Area>
                    <Top>
                        <Div1 >
                            <TitleH3>Lista de Produtos</TitleH3>
                            <Mobile>
                                <NewProduct after={getProductsList} />
                            </Mobile>
                        </Div1>
                        <Div2 style={{}}>
                            {totalProducts !== 0 &&
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <Search >
                                        <input placeholder='Buscar produtos...' id='searchProduct' /* value={search} onChange={(e) => setSearch(e.target.value)} onKeyUpCapture={(e) => e.key === "Enter" && searchProducts(e.target.value)} */ />
                                        <div>
                                            <SearchOutlinedIcon style={{ cursor: 'pointer' }} fontSize='large' /* onClick={() => searchProducts(search)} */ />
                                        </div>
                                    </Search>
                                    <Web>
                                        <NewProduct after={getProductsList} />
                                    </Web>
                                </div>}
                        </Div2>
                    </Top>
                    <hr></hr>
                    {totalProducts === 0 ?
                        <List sx={{ width: '100%', bgcolor: 'background.paper', paddingTop: '80px', textAlign: 'center' }}>
                            <p style={{ color: '#6E6E6E' }}><CreateNewFolderIcon /> Você ainda não possui produtos cadastrados, adicione seu primeiro produto</p>
                            <NewProduct after={getProductsList} />
                        </List>
                        :
                        <Grid container spacing={3} sx={{ justifyContent: 'center' }}>

                            {productsList?.map((products) => (
                                <Grid item xs={isMobile() ? '6' : 'auto'} key={products?.product_slug}>
                                    <Card sx={{ width: isMobile() ? 180 : 230, height: isMobile() ? 350 : 300, border: '1px solid #A9ABAE', borderRadius: '10px' }}>
                                        <div style={{ width: '100%', justifyContent: 'space-between', display: 'flex', padding: '5px' }}>
                                            <div style={{ background: products?.status === 0 ? "#F10202" : "#009C43", width: '10px', height: '10px', borderRadius: '100%' }}>

                                            </div>
                                            <div>
                                                <CardMedia
                                                    sx={{ height: 140, width: isMobile() ? 140 : 200 }}
                                                    image={`${process.env.REACT_APP_LINK}/${products?.images?.[0]}`}
                                                    title={products.title}
                                                />
                                            </div>
                                        </div>
                                        <CardContent>
                                            <Typography gutterBottom variant="h5" component="div">
                                                {products?.price?.toLocaleString('pt-BR', options)}
                                            </Typography>

                                            <Tooltip title={products.title}>
                                                <Typography variant="body2" color="text.secondary" sx={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', cursor: 'pointer' }}>
                                                    {products.title}
                                                </Typography>
                                            </Tooltip>

                                        </CardContent>
                                        <CardActions sx={{ justifyContent: 'space-between', display: 'flex' }}>
                                            <Button size="small"> <NewProduct after={getProductsList} operation={"edit"} productSlug={products.product_slug} /></Button>
                                            <Button size="small"> <NewProduct after={getProductsList} operation={"copy"} productSlug={products.product_slug} /></Button>
                                            {!isMobile() && <Tooltip title="Deletar Produto"><Button size="small"> <DeleteIcon onClick={() => confirmDelete(products?.product_slug, products?.title)} sx={{ color: '#F54B5E' }} /></Button></Tooltip>}
                                        </CardActions>
                                        {isMobile() && <Tooltip title="Deletar Produto"><Button size="small"> <DeleteIcon onClick={() => confirmDelete(products?.product_slug, products?.title)} sx={{ color: '#F54B5E' }} /></Button></Tooltip>}
                                    </Card>
                                </Grid>
                            ))}
                        </Grid>}

                </Area>
            </Content >
            <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '10px' }}>
                <Stack spacing={2}>
                    <Pagination count={totalPages} page={page} onChange={handleChange} />
                </Stack>
            </div>

            <Modal show={show} onHide={handleClose} size="sm" style={{ height: '100%' }} >
                <Modal.Header closeButton>
                    <Modal.Title><TitleH6>
                        {productname}
                    </TitleH6></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Deseja excluir esse produto?
                    <div style={{ padding: '10px 0', alignItems: 'flex-end', display: 'flex', justifyContent: 'flex-end' }}>
                        <ButtonSilverSmall variant="contained" onClick={() => handleClose()}>Cancelar</ButtonSilverSmall>
                        <ButtonRedSmall variant="contained" onClick={() => deleteProduct()}>Excluir</ButtonRedSmall>
                    </div>
                </Modal.Body>
            </Modal>
            {deleteSeccess && <SnackBar type="success" time={4000} message="Produto deletetado com sucesso" states={true} />}
            {deleteError && <SnackBar type="error" time={4000} message="Não foi possível excluir esse produto " states={true} />}
        </Container >
    )
}

export default ProductsLIst;