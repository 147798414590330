import React from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import empty from '../../../../Assets/ImagensGerais/empty.png';

import {
    Container,
    ProductSection,
} from './styles';
import CardProduct from '../CardProduct';


export const CardCaroselProductMobile = ({ products }) => {

    return (
        <Container>
            <Carousel autoPlay={false}
                showThumbs={false}
                infiniteLoop={false}
                showStatus={false}
                renderArrowNext={(onClickHandler, hasNext, label) =>
                    hasNext && (
                        <></>
                    )
                }
                renderArrowPrev={(onClickHandler, hasPrev, label) =>
                    hasPrev && (
                        <></>
                    )
                }>

                <ProductSection>
                    {products?.slice(0, 1).map((product) =>
                        <CardProduct key={product?.id} cat="linha_sementes" store={product.store_slug} img={!product?.images ? empty : `${process.env.REACT_APP_LINK}/${product?.images?.[0]}`} title={product?.title} description={product?.description} price={product?.price} slug={product.product_slug} />
                    )}
                </ProductSection>

                <ProductSection>
                    {products?.slice(1, 2).map((product) =>
                        <CardProduct key={product?.id} cat="linha_sementes" store={product.store_slug} img={!product?.images ? empty : `${process.env.REACT_APP_LINK}/${product?.images?.[0]}`} title={product?.title} description={product?.description} price={product?.price} slug={product.product_slug} />
                    )}
                </ProductSection>
                <ProductSection>
                    {products?.slice(2, 3).map((product) =>
                        <CardProduct key={product?.id} cat="linha_sementes" store={product.store_slug} img={!product?.images ? empty : `${process.env.REACT_APP_LINK}/${product?.images?.[0]}`} title={product?.title} description={product?.description} price={product?.price} slug={product.product_slug} />
                    )}
                </ProductSection>

                <ProductSection>
                    {products?.slice(3, 4).map((product) =>
                        <CardProduct key={product?.id} cat="linha_sementes" store={product.store_slug} img={!product?.images ? empty : `${process.env.REACT_APP_LINK}/${product?.images?.[0]}`} title={product?.title} description={product?.description} price={product?.price} slug={product.product_slug} />
                    )}
                </ProductSection>
                <ProductSection>
                    {products?.slice(4, 5).map((product) =>
                        <CardProduct key={product?.id} cat="linha_sementes" store={product.store_slug} img={!product?.images ? empty : `${process.env.REACT_APP_LINK}/${product?.images?.[0]}`} title={product?.title} description={product?.description} price={product?.price} slug={product.product_slug} />
                    )}
                </ProductSection>
                <ProductSection>
                    {products?.slice(5, 6).map((product) =>
                        <CardProduct key={product?.id} cat="linha_sementes" store={product.store_slug} img={!product?.images ? empty : `${process.env.REACT_APP_LINK}/${product?.images?.[0]}`} title={product?.title} description={product?.description} price={product?.price} slug={product.product_slug} />
                    )}
                </ProductSection>
                <ProductSection>
                    {products?.slice(6, 7).map((product) =>
                        <CardProduct key={product?.id} cat="linha_sementes" store={product.store_slug} img={!product?.images ? empty : `${process.env.REACT_APP_LINK}/${product?.images?.[0]}`} title={product?.title} description={product?.description} price={product?.price} slug={product.product_slug} />
                    )}
                </ProductSection>
                <ProductSection>
                    {products?.slice(7, 8).map((product) =>
                        <CardProduct key={product?.id} cat="linha_sementes" store={product.store_slug} img={!product?.images ? empty : `${process.env.REACT_APP_LINK}/${product?.images?.[0]}`} title={product?.title} description={product?.description} price={product?.price} slug={product.product_slug} />
                    )}
                </ProductSection>
            </Carousel>
        </Container>
    );
};
