import styled from "styled-components";

export const Container = styled.div`
    display: flex;
`;

export const Content = styled.div`
    flex: 1;
    overflow-y: auto;
    height: 90vh;
    padding: 10px 15px;
`;
export const Line = styled.div`
    width: 100%;
    display: flex;
    padding: 0 15px;
    padding-bottom: 15px ;
`;

export const Salles = styled.div`
    width: 350px;
    height: 500px;
    background-color: #fff ;
    box-shadow: 0px 0px 9px #26242424;
    border-radius: 6px;
    margin-left: 15px;
    overflow: auto ;
`;
export const InfoTable = styled.div`
    flex:1;
    height: 500px;
    background-color: #fff ;
    box-shadow: 0px 0px 9px #26242424;
    border-radius: 6px;
    overflow: auto;
    padding: 10px;
`;

export const SmallCard = styled.div`
    background-color: #fff ;
    box-shadow: 0px 0px 9px #26242424;
    border-radius: 6px;
    height: 150px;
    width: 25%;
    margin-left: 7px;
    margin-right: 7px;
`;

export const BigCard = styled.div`
    background-color: #fff ;
    box-shadow: 0px 0px 9px #26242424;
    border-radius: 6px;
    height: 450px;
    width: 35%;
    margin-left: 7px;
    margin-right: 7px;
`;
export const Search = styled.div`
        width: 40%;
        height: 40px;
        border: 1px solid #A9ABAE;
        border-radius: 3px ;
        background-color: #F1F1F1 ;
        >input {
        flex: 1;
        width: 70%;
        height: 100%;
        padding-left: 10px ;
        background-color: #F1F1F1 ;
    }
    >input::placeholder {
            color: #A9ABAE;
        }
        >select{
            background-color: #F1F1F1 ;
        }
`;

export const DisplayNumber = styled.div`
    flex: 1;
    height: 55%;
    text-align: center;
    align-items: center;
    display: flex;
    justify-content: center;
`;