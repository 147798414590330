import styled from "styled-components";

export const Container = styled.div`
    padding: 2%;
`;
export const Line = styled.div`
    width: 100%;
    display: flex;
`;
export const BigCard = styled.div`
    background-color: #fff ;
    box-shadow: 0px 0px 9px #26242424;
    border-radius: 6px;
    height: 100vh;
    overflow: auto;
    margin-right: 10px;
    padding-bottom: 25px;
    width: 60%;
    @media(max-width: 800px){
        width: 100%;
    }
`;
export const HistoryCard = styled.div`
    background-color: #fff ;
    box-shadow: 0px 0px 9px #26242424;
    border-radius: 6px;
    height: 100vh;
    margin-left: 10px;
    width: 40%;
    @media(max-width: 800px){
        display: none;
    }
    
`;
export const Card = styled.div`
    background-color: #fff ;
    box-shadow: 0px 0px 9px #26242424;
    border-radius: 6px;
    height: 120px;
    width: 280px;
    padding: 15px;
    
`;