import axios from "axios";

export async function getState() {
    const { data: res } = await axios({ method: 'get', url: `${process.env.REACT_APP_LINK}/api/store/shipment/states`, headers: { 'Authorization': `Bearer ${localStorage.getItem("clientToken")}` } })
    return res
}

export async function getMacroRegion(rote) {
    const { data: res } = await axios({ method: 'get', url: `${process.env.REACT_APP_LINK}/${rote}`, headers: { 'Authorization': `Bearer ${localStorage.getItem("clientToken")}` } })
    return res
}

export async function postCountryFunc() {
    const { data: res } = await axios({ method: 'post', url: `${process.env.REACT_APP_LINK}/api/store/shipment/country`, headers: { 'Authorization': `Bearer ${localStorage.getItem("clientToken")}` } })
    return res
}

export async function postStatesFunc(statesIds) {
    var data = new FormData();
    statesIds.map(ids => (ids.value)).forEach(ids => data.append("states[]", ids));
    const options = {
        method: 'POST',
        url: `${process.env.REACT_APP_LINK}/api/store/shipment/states`,
        headers: {
            'Content-Type': 'multipart/form-data',
            'Accept': 'application/json',
            'type': "formData",
            'Enctype': 'multipart/form-data',
            Authorization: `Bearer ${localStorage.getItem('clientToken')}`
        },
        data: data
    };
    const { data: res } = await axios.request(options)
    return res
}

export async function postMacroRegion(macroIds) {
    var data = new FormData();
    macroIds.map(ids => (ids.value)).forEach(ids => data.append("mesorregioes[]", ids));
    const options = {
        method: 'POST',
        url: `${process.env.REACT_APP_LINK}/api/store/shipment/mesorregioes`,
        headers: {
            'Content-Type': 'multipart/form-data',
            'Accept': 'application/json',
            'type': "formData",
            'Enctype': 'multipart/form-data',
            Authorization: `Bearer ${localStorage.getItem('clientToken')}`
        },
        data: data
    };
    const { data: res } = await axios.request(options)
    return res
}

export async function postMicroRegion(microIds) {
    var data = new FormData();
    microIds.map(ids => (ids.value)).forEach(ids => data.append("microrregioes[]", ids));
    const options = {
        method: 'POST',
        url: `${process.env.REACT_APP_LINK}/api/store/shipment/microrregioes`,
        headers: {
            'Content-Type': 'multipart/form-data',
            'Accept': 'application/json',
            'type': "formData",
            'Enctype': 'multipart/form-data',
            Authorization: `Bearer ${localStorage.getItem('clientToken')}`
        },
        data: data
    };
    const { data: res } = await axios.request(options)
    return res
}

export async function postCitiesFunc(citiesIds) {
    var data = new FormData();
    citiesIds.map(ids => (ids.value)).forEach(ids => data.append("cities[]", ids));
    const options = {
        method: 'POST',
        url: `${process.env.REACT_APP_LINK}/api/store/shipment/cities`,
        headers: {
            'Content-Type': 'multipart/form-data',
            'Accept': 'application/json',
            'type': "formData",
            'Enctype': 'multipart/form-data',
            Authorization: `Bearer ${localStorage.getItem('clientToken')}`
        },
        data: data
    };
    const { data: res } = await axios.request(options)
    return res
}