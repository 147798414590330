import React from 'react'
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import {
    Container,
    Card,
    Div,
    Image,
    Text,
} from './styles';
import { useNavigate } from 'react-router-dom';


const MobileCardLastView = ({ productsList }) => {

    const navigate = useNavigate()

    const options = { style: 'currency', currency: 'BRL' };

    return (
        <Container>
            <Card>
                Visto recentemente
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container>
                        {productsList?.slice(0, 4)?.map((product) => (
                            <Grid item xs={12} key={product?.product_slug}>
                                <Div onClick={() => navigate(`/produto/detalhes/${product?.store_slug}/${product?.product_slug}`)}>
                                    <Image>
                                        <img src={`${process.env.REACT_APP_LINK}/${product?.images?.[0]}`} alt={product?.title}></img>
                                    </Image>
                                    <Text>
                                        <div style={{ height: '55%' }}> <p>{product?.title}</p></div>
                                        <div style={{ height: '25%' }}><h3>{product?.price?.toLocaleString('pt-BR', options)}</h3></div>
                                        <div style={{ height: '20%' }}><h6>Ver Produto</h6></div>
                                    </Text>
                                </Div>
                            </Grid>
                        ))}
                    </Grid>
                </Box>
            </Card>
        </Container>
    )
}

export default MobileCardLastView;