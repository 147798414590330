import React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';

import {
    Container,
} from './styles';

const LargeCard = ({ image, title, description, height, link, target }) => {

    return (
        <Container>
            <Card sx={{ display: 'flex', cursor: 'pointer', height: { height }, justifyContent: 'space-between' }} onClick={() => window.open(link ? link : '/', target ? target : '_self')}>
                <Box sx={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
                    <CardContent sx={{ justifyContent: 'space-between', height: '100%' }}>
                        <Typography component="div" variant="p" style={{ height: '90%' }}>
                            {title}
                        </Typography>
                        <Typography variant="subtitle1" color="text.secondary" component="div" style={{ height: '10%' }}>
                            {description}
                        </Typography>
                    </CardContent>
                </Box>
                <CardMedia
                    component="img"
                    sx={{ width: "50%", objectFit: 'cover', }}
                    image={image}
                    alt="i"
                />
            </Card>
        </Container>
    )
}

export default LargeCard;