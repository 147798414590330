import React from 'react';
import { Slot } from '@radix-ui/react-slot';
import { Modal } from '@mantine/core';
import { useInformation } from '../../../../Providers/informations';

import {
    Container,
} from './styles';
import { TitleH6 } from '../../../../Styles/style';

const ModalComponent = ({ asChild, buttonText, modalTitle, children, comp, param, widthS }) => {

    const { open, setOpen } = useInformation();
    const Comp = asChild ? Slot : 'button';

    const [modalState, setModalState] = React.useState(false)

    return (
        <Container>
            {param === 1 ?
                <Comp onClick={() => setModalState(!modalState)} style={{ background: '#ffffff' }}>
                    {buttonText}
                </Comp>
                : <Comp onClick={() => setOpen(!open)} >
                    {buttonText}
                </Comp>}


            {children}

            <Modal
                opened={param === 1 ? modalState : open}
                onClose={() => param === 1 ? setModalState(false) : setOpen(false)}
                title={<TitleH6>{modalTitle}</TitleH6>}
                styles={() => ({ modal: { borderRadius: 10, width: widthS }, header: { borderBottom: 'solid 2px #c6c6c6' } })}
            >
                {comp}
            </Modal>

        </Container>
    )
}

export default ModalComponent;