import React from "react";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { getWithdrawl, getBalance, makeWithdrawl } from "../../../../Services/StoreCheckout";
import StoreTimeLine from "../../../Components/Store/StoreTimeLine";
import { useInformation } from "../../../../Providers/informations";
import { ButtonGreen, ButtonGreenSmall } from "../../../../Styles/stylesButtons";
import { formatarMoeda } from "../../../../Services/payment";
import { TitleH3 } from "../../../../Styles/style";
import Typography from '@mui/material/Typography';
import { Modal } from "react-bootstrap";
import Tabs from '@mui/material/Tabs';
import { Input } from "@mui/material";
/* import Tab from '@mui/material/Tab';
 */import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import CountUp from 'react-countup';
import SnackBar from "../../../Components/General/SnackBar";
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import StoreIcon from '@mui/icons-material/Store';
import Button from '@mui/material/Button';

import {
    Container,
    BigCard,
    Line,
    Card,
    HistoryCard,
} from './styles';
import IconBreadcrumbs from "../../../Components/General/Breadcrumbs";


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

const RequestWithdrawl = () => {

    const { storeInfo } = useInformation();

    const options = { style: 'currency', currency: 'BRL' };

    const [value, setValue] = React.useState(0);

    const [withdrawalValue, setWithdrawalValue] = React.useState();
    const [bankDescriptio, setBanckDescriptio] = React.useState();
    const [displayValue, setDisplayValue] = React.useState();
    const [account, setBanckAccount] = React.useState();
    const [bankCode, setBanckCode] = React.useState();
    const [agency, setBanckAgency] = React.useState();

    const [disabledButton, setDisableButton] = React.useState(false);
    const [success, setSuccess] = React.useState(false);
    const [detail, setDetail] = React.useState(false);
    const [amount, setAmount] = React.useState(false);
    const [amountMin, setAmountMin] = React.useState(false);
    const [error, setError] = React.useState(false);
    const [show, setShow] = React.useState(false);

    const [banckInfotmation, setBanckInformation] = React.useState([]);
    const [withdrawalList, setWithdrawalList] = React.useState([]);
    const [balance, setBalance] = React.useState([]);

    const getWithdrawals = async () => {
        try {
            const response = await getWithdrawl()
            setWithdrawalList(response?.data)
        } catch (error) {

        }
    }

    const getBalanceFunc = async () => {
        try {
            const response = await getBalance()
            setBalance(response?.data);
        } catch (error) {

        }
    }
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const handleClose = () => {
        setShow(!show)
    }
    const [valueInt, setValueInt] = React.useState();

    const setPriceToFormat = async (e) => {
        if (typeof e == 'number') return e;
        var nr;
        var virgulaSeparaDecimais = e.match(/(,)\d{2}$/);
        if (virgulaSeparaDecimais) nr = e.replace(/\./g, '').replace(',', '.')
        else nr = e.replace(',', '').replace('.', '');
        const priceReturn = await formatarMoeda(e)
        const priceWithoutSeparator = priceReturn.replace(/\./g, '').replace(',', '.');
        if(parseFloat(priceWithoutSeparator) > 1000000){
            setDisplayValue("1.000.000,00")
            setWithdrawalValue(parseFloat(1000000) - parseFloat(tax));
            setValueInt((parseFloat(1000000) * 100 - parseFloat(tax) * 100));
        }else{
            setDisplayValue(priceReturn)
            setWithdrawalValue(parseFloat(priceWithoutSeparator) - parseFloat(tax));
            setValueInt((parseFloat(priceWithoutSeparator) * 100 - parseFloat(tax) * 100));
        }
        if (parseFloat(nr / 100) > (balance?.available_amount / 100)) {
            setDisableButton(true);
            setAmount(true);
            if (parseFloat(nr / 100) < 5) { setAmountMin(true); }
            else { setAmountMin(false); }
        }
        else {
            if (parseFloat(nr / 100) < 5) { setAmountMin(true); setDisableButton(true); }
            else { setAmountMin(false); setDisableButton(false); }
            setAmount(false);
        }
    }

    const makeWi = async () => {
        try {
            const returnResponse = await makeWithdrawl(valueInt);
            if (returnResponse) {
                setSuccess(true);
                setTimeout(() => {
                    setSuccess(false);
                    getBalanceFunc();
                    handleClose();
                }, 3000);
            }
        } catch (error) {
            setError(true);
            setTimeout(() => {
                setError(false);
            }, 3000);
        }
    }

    React.useEffect(() => {
        getWithdrawals();
        getBalanceFunc();
    }, []);

    const loadBanck = () => {
        setBanckInformation(JSON.parse(storeInfo?.StorePagarme_account_info));
        setBanckDescriptio(banckInfotmation?.default_bank_account?.holder_name);
        setBanckCode(banckInfotmation?.default_bank_account?.bank);
        setBanckAgency(banckInfotmation?.default_bank_account?.branch_number);
        setBanckAccount(banckInfotmation?.default_bank_account?.account_number);
    }
    React.useEffect(() => {
        loadBanck()
    }, [])

    const [count, setCount] = React.useState(true);
    /* const [tax, setTax] = React.useState(3.67) */
    const tax = 3.67;

    React.useEffect(() => {
        setTimeout(() => {
            setCount(false);
        }, 1000);
    }, [])
    const where = [
        { where: 'Minha Loja', link: '/loja/perfil', icon: <StoreIcon sx={{ mr: 0.5 }} fontSize="inherit" /> },
        { where: 'Ganhos', link: '/loja/ganhos', color: '#009C43', icon: <AttachMoneyIcon sx={{ mr: 0.5 }} fontSize="inherit" /> },
    ]
    return (
        <Container>
            <IconBreadcrumbs list={where} />

            <Line>
                <BigCard >
                    <div style={{ padding: '2% 5%', display: 'flex', justifyContent: 'space-between' }}>
                        <TitleH3>Detalhes de Ganhos</TitleH3>
                    </div>
                    <hr></hr>
                    <Line style={{ padding: '5px', justifyContent: 'space-around' }}>
                        <Card>
                            <small>Saldo </small>
                            <TitleH3>
                                {!count ? (balance?.available_amount / 100).toLocaleString('pt-BR', options) : <CountUp end={100} duration={1.75} />}
                            </TitleH3>
                        </Card>
                        <Card>
                            <small>A receber</small>
                            <TitleH3>
                                {!count ? (balance?.waiting_funds_amount / 100).toLocaleString('pt-BR', options) : <CountUp end={100} duration={1.75} />}

                            </TitleH3>
                        </Card>
                    </Line>
                    <Line style={{ justifyContent: 'flex-end', padding: '10px 5%' }}>
                        <ButtonGreen onClick={() => { setShow(!show); loadBanck() }}>Solicitar Saque</ButtonGreen>
                    </Line>
                    <hr></hr>
                    <div style={{ padding: '0 15px', display: 'flex', justifyContent: 'space-between' }}>
                        <TitleH3>Histórico</TitleH3>
                    </div>
                    <ResponsiveContainer width="100%" height="50%">
                        <BarChart
                            width={150}
                            height={300}
                            data={[{ name: 'Disponìvel', valor: (balance?.available_amount / 100), fill: '#085F2F' },
                            { name: 'A receber', valor: (balance?.waiting_funds_amount / 100), fill: '#085F2F' },
                            { name: 'Total Transferido', valor: (balance?.transferred_amount / 100), fill: '#085F2F' }]}
                            margin={{
                                top: 20,
                                right: 30,
                                left: 20,
                                bottom: 5,
                            }}
                            barSize={30}
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="name" />
                            <YAxis />
                            <Tooltip />
                            <Bar dataKey="valor" stackId="a" fill='#085F2F' />
                        </BarChart>
                    </ResponsiveContainer>
                </BigCard>
                <HistoryCard>
                    <div style={{ padding: '3% 5%', display: 'flex', justifyContent: 'space-between' }}>
                        <TitleH3>Movimentações</TitleH3>
                    </div>
                    <hr></hr>
                    <Box sx={{ width: '100%' }}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                                {/*  <Tab label="Saques" {...a11yProps(0)} /> */}
                                {/*   <Tab label="Recebimentos" {...a11yProps(1)} /> */}
                                {/* <Tab label="Item Three" {...a11yProps(2)} /> */}
                            </Tabs>
                        </Box>
                        <TabPanel value={value} index={0}>
                            <StoreTimeLine value={withdrawalList} />
                        </TabPanel>
                        <TabPanel value={value} index={1}>
                            Recebimentos
                        </TabPanel>
                        {/* <TabPanel value={value} index={2}>
                            Item Three
                        </TabPanel> */}
                    </Box>
                </HistoryCard>
            </Line >

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{detail ? "Revisar os dados" : "Solicitar saque"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {detail ?
                        <div>
                            <p>Valores:</p>
                            <Line style={{ width: '60%', justifyContent: 'space-between' }}>
                                <small>Valor solicitado:</small>
                                <small>{displayValue?.toLocaleString('pt-BR', options)}</small>
                            </Line>
                            <Line style={{ width: '60%', justifyContent: 'space-between' }}>
                                <small>Taxa de transferência:</small>
                                <small>{tax?.toLocaleString('pt-BR', options)}</small>
                            </Line>
                            <Line style={{ width: '60%', justifyContent: 'space-between', color: parseFloat(withdrawalValue) < 0 ? 'red' : 'green', fontWeight: 'bold' }}>
                                <small>Total a recer</small>
                                <small>{withdrawalValue?.toLocaleString('pt-BR', options)} </small>
                            </Line>
                            <hr></hr>
                            <p>Destino:</p>
                            <Line style={{ width: '60%', justifyContent: 'space-between' }}>
                                <small>Titular da Conta:</small>
                                <small>{bankDescriptio}</small>
                            </Line>
                            <Line style={{ width: '60%', justifyContent: 'space-between' }}>
                                <small>Código do Banco:</small>
                                <small>{bankCode}</small>
                            </Line>
                            <Line style={{ width: '60%', justifyContent: 'space-between' }}>
                                <small>Conta:</small>
                                <small>{account}</small>
                            </Line>
                            <Line style={{ width: '60%', justifyContent: 'space-between' }}>
                                <small>Agência:</small>
                                <small>{agency}</small>
                            </Line>
                        </div>
                        :
                        <>
                            <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                                <Card>
                                    <small>Saldo Disponível </small>
                                    <TitleH3>{(balance?.available_amount / 100).toLocaleString('pt-BR', options)}</TitleH3>
                                </Card>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'center', width: '100%', padding: '15px 0', flexDirection: 'column', alignItems: 'center' }}>
                                <small style={{ padding: '15px 0' }}>Digite o valor que deseja sacar:</small>
                                <div>R$: <Input placeholder="250,00" sx={{ paddingLeft: '10px' }} onChange={(e) => setPriceToFormat(e.target.value)} value={displayValue}  maxLength='12' ></Input></div>
                                {amount && <small style={{ padding: '5px 0', color: 'red' }}>*Valor maior que o disponível*</small>}
                                {amountMin && <small style={{ padding: '5px 0', color: 'red' }}>*Valor mínimo para saque é de R$ 5,00*</small>}
                            </div>
                        </>
                    }
                </Modal.Body>
                <Modal.Footer>
                    {detail ? <Button style={{ borderRadius: '20px' }} variant="primary" onClick={() => setDetail(false)}>
                        Voltar
                    </Button> : <Button style={{ borderRadius: '20px' }} variant="primary" onClick={handleClose}>
                        Cancelar
                    </Button>}
                    {!disabledButton && detail ?
                        <ButtonGreenSmall variant="secondary" onClick={() => makeWi()}>
                            Sacar
                        </ButtonGreenSmall>
                        :
                        !amount &&
                        <ButtonGreenSmall variant="secondary" onClick={() => { setDetail(true); loadBanck() }}>
                            Avançar
                        </ButtonGreenSmall>
                    }

                </Modal.Footer>
                <div id="sanckbar">
                    {error && <SnackBar type="error" time={3000} message="Não foi possível realizar o saque" states={true} />}
                    {success && <SnackBar type="success" time={3000} message="Saque realizado com sucesso!" states={true} />}
                </div>
            </Modal>

        </Container >
    )
}

export default RequestWithdrawl