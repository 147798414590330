import styled from "styled-components";
import { createTheme } from '@mui/material/styles';

export const Container = styled.div`
    height: 100%;
`;

export const ContentCard = styled.div`
    width: 100%;
    height: 100%;
    background-color: #FFFFFF ;
    box-shadow: 0px 0px 9px #26242424;
    border-radius: 6px;
    overflow: auto;
`;

export const ListDiv = styled.div`
  cursor: pointer;
  color: #7a7474;
  :hover {
    color: #000000;
  }

`;

export const TopTitleDiv = styled.div`
    width: 100%;
    height: 130px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 2px #00000029;
    border-bottom: 1px solid #DDDDDD;
    opacity: 1;
    padding: 5px;
`;

export const ListContentOrder = styled.div`
    overflow: auto;
    padding: 1rem 2rem;
`;
export const theme2 = createTheme({
  palette: {
    primary: {
      // Purple and green play nicely together.
      main: '#009c43',
    },
    secondary: {
      // This is green.A700 as hex.
      main: '#009c43',
    },
  },
});

export const DeleteSection = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 80vh;

    @media(max-height: 768px){
      height: 70vh;
    }
  `;