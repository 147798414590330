import React from "react";
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { grey } from '@mui/material/colors';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Modal } from 'react-bootstrap';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import {
    Container,
    List,
} from './styles';
import { ButtonGreenSmall } from "../../../../Styles/stylesButtons";
import { postQuestion } from "../../../../Services/addViewProduct";
import axios from "axios";
import SnackBar from "../SnackBar";

const Ask = ({ store, product, getQuestionsF, dataList, val }) => {

    const [warning, setWarming] = React.useState(false);
    const [success, setSuccess] = React.useState(false);
    const [show, setShow] = React.useState(false);

    const [questionsList, setQuestionsList] = React.useState([])
    const [totalOfPages, setTotalOfPages] = React.useState();
    const [nexProducts, setNextProducts] = React.useState();
    const [page, setPage] = React.useState(1);

    const handleChange = (event, value) => {
        setPage(value);
        nextPage(value);
    };

    const [question, setQuestion] = React.useState();

    const nextPage = async (value1) => {
        const { data: res } = await axios({ method: 'get', url: `${process.env.REACT_APP_LINK}${nexProducts?.[value1]?.url.split(".com.br")[1]}`, headers: { 'Authorization': `Bearer ${localStorage.getItem("clientToken")}` } });
        setQuestionsList(res.data);
    }

    const makeQuestion = async () => {
        if (!question) {
            setWarming(true);
            setTimeout(() => {
                setWarming(false)
            }, 3000);
            return
        }
        try {
            const response = await postQuestion(question, store, product);
            if (response?.message === "success") {
                setQuestion('');
                setSuccess(true);
                setShow(false);
                getQuestionsF();
                setTimeout(() => {
                    setSuccess(false)
                }, 3000);
            }
        } catch (error) {

        }
    }

    const handleCloseModal = () => {
        setShow(false);
    }

    React.useEffect(() => {
        setQuestionsList(dataList?.data);
        setTotalOfPages(dataList?.meta?.last_page);
        setNextProducts(dataList?.meta?.links)
    }, [dataList])

    return (
        <Container>
            <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end', padding: '15px' }}>
                <ButtonGreenSmall style={{ width: '280px', height: '40px' }} onClick={() => setShow(true)}>Perguntar ao vendedor</ButtonGreenSmall>
            </div>
            <List>
                {questionsList?.map((list) => (
                    <Card sx={{ maxWidth: '100%', marginBottom: '5px' }} key={list?.id}>
                        <CardHeader
                            avatar={
                                <Avatar sx={{ bgcolor: grey }} aria-label="recipe">
                                    {list?.user?.charAt(0)}
                                </Avatar>
                            }
                            action={
                                <IconButton aria-label="settings">
                                    <MoreVertIcon />
                                </IconButton>
                            }
                            title={`${list?.user}`}
                            subheader={list?.question}
                        />
                        <CardContent>
                            <Typography variant="body2" color="text.secondary">
                                {list?.answer}
                            </Typography>
                        </CardContent>
                    </Card>
                ))}
                <Stack spacing={2}>
                    <Typography></Typography>
                    <Pagination count={totalOfPages} page={page} onChange={handleChange} />
                </Stack>
            </List>

            <Modal show={show} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Fazer Pergunta</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{}}>
                    <div className='row'>
                        <div className='col-12'>
                            <div className="mb">
                                <label htmlFor="question" className="form-label"/*  style={{ color: '#9e9e9e' }}  */>Pergunte ao vendedor</label>
                                <textarea maxLength="240" className="form-control" id="question" placeholder="Qual o prazo de entrega para ..... ?" rows="3" value={question} onChange={(e) => setQuestion(e.target.value)}></textarea>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer style={{ display: 'flex', flexDirection: 'row' }}>
                    <div><ButtonGreenSmall style={{ width: '15rem', height: '35px' }} onClick={() => makeQuestion()}>Perguntar</ButtonGreenSmall></div>
                </Modal.Footer>
                {success && <SnackBar type="success" time={4000} message="Pergunta enviada ao vendedor" states={true} />}
                {warning && <SnackBar type="error" time={4000} message="Preencher os campos em branco" states={true} />}
            </Modal>
        </Container>
    )
}

export default Ask;