import React from 'react';
import CartListProducts from '../../Components/General/CartListProducts/idenx';
/* import CardCaroselProduct from '../../Components/General/CardCaroselProduct';
 */import * as GetProducts from '../../../Services/getProducts';
import CarProductCard from '../../Components/General/CarProductCard';
import { TitleH3 } from '../../../Styles/style';
import * as LogoutFunction from '../../../Services/logout';
import {
    Container,
    ProductSection,
    SideSection,
} from './styles';

const Cart = () => {
    const [productsList, setProductsList] = React.useState([]);

    const getViewdProducts = async () => {
        try {
            const list = await GetProducts.getViewedProducts();
            if (list?.response?.data.message === "Unauthenticated.") {
                LogoutFunction.Logout();
            }
            else {
                setProductsList(list?.products);
            }
        } catch (error) {

        }
    }


    React.useEffect(() => {
        getViewdProducts();
    }, []);

    return (
        <Container>
            <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                <div style={{ flex: '1', paddingRight: '15px', }}>
                    <CartListProducts />
                </div>
                <SideSection >
                    <div>
                        <div style={{ padding: '0px 15px', paddingTop: '10px' }}>
                            <TitleH3 style={{ fontSize: '1.2rem' }}>Vistos anteriormente</TitleH3>
                            <hr></hr>
                        </div>

                        <div style={{ flex: '1', background: '#fff', borderRadius: '6px', padding: '5px 5px', marginBottom: '5px', overflowY: 'auto' }}>
                            {productsList
                                .filter(products => products?.stock > 0)
                                .map(products =>
                                    <CarProductCard key={products?.id} products={products} origin="sec" />
                                )}
                        </div>
                    </div>
                </SideSection>
            </div>
            <ProductSection>
                {/*  <CardCaroselProduct /> */}
            </ProductSection>
        </Container>
    )
}

export default Cart;