import styled from "styled-components";

export const Container = styled.div`
    padding: 25px;
    transition: transform 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    :hover{
        transform: translateY(-5px);
    }
    @media(max-width: 800px){
        padding: 35px;
    }
`;
