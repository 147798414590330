import React from "react";
import { Modal, Form, Row, Col } from "react-bootstrap";
import { ButtonBlueSmall, ButtonGreen, ButtonGreenSmall } from "../../../../Styles/stylesButtons";
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import PixIcon from '@mui/icons-material/Pix';
import PaymentsIcon from '@mui/icons-material/Payments';
import QRCode from "react-qr-code";
import Countdown from 'react-countdown';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Select from 'react-select'
import SnackBar from "../SnackBar";
import { finishOrderCreditCard, finishOrder } from "../../../../Services/payment";
import SipperLoader from "../Animations/Spinner";
import CheckSuccess from "../Animations/Check";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import Tooltip from '@mui/material/Tooltip';
import { useNavigate } from "react-router-dom";
import errorImage from '../../../../Assets/icons/error.png';
import isMobile from '../../../../Services/isMobile';

import {
    Container,
    DivB,
} from './styles';
import { DivList, DivResume, LabelResume, ValueResume } from "../../../Pages/OrderReview/styles";

const PaymentOption = ({ value, productsList, addressId, payment, funPaymentMethod }) => {

    const navigate = useNavigate();

    /* Modal state */
    const [completOrder, setCompletOrder] = React.useState(false);
    const handleCompletOrder = () => {
        setCompletOrder(!completOrder);
        if (paymentCompleted) {
            navigate("/usuario/meus_pedidos");
        }
    }
    const [expanded, setExpanded] = React.useState(false);

    const [line, setLines] = React.useState(6)
    const [line2, setLines2] = React.useState(2)

    /* payment option state */
    const [makingPayment, setMakingPayment] = React.useState(false);
    const [pixSelected, setPixSelected] = React.useState(false);
    const [pixQrCode, setPixQrCode] = React.useState(0);

    const [billSelected, setBillSelected] = React.useState(false);
    const [billNumber, setBillNumber] = React.useState(0);
    const [billPdf, setBillPdf] = React.useState(null);

    const [paymentCompleted, setpaymentCompleted] = React.useState(false);

    const copyNilluNumber = (billNumber) => {
        const input = document.createElement('input');
        input.value = billNumber;
        document.body.appendChild(input);
        input.select();
        document.execCommand('copy');
        document.body.removeChild(input);
        alert("Código copiado para a área de transferência");
    }

    const copyQrCode = (qrCode) => {
        const input = document.createElement('input');
        input.value = qrCode;
        document.body.appendChild(input);
        input.select();
        document.execCommand('copy');
        document.body.removeChild(input);
        alert("Código copiado");
    }

    const [creditCarSelected, setCreditCardSelected] = React.useState(false);
    const [creditCardInfo, setInfCreditCardInfo] = React.useState();
    const [installments, setTnstallments] = React.useState(null);
    const [card_number, setCard_number] = React.useState(null);
    const [card_name, setCard_name] = React.useState(null);
    const [card_exp_month, setCard_exp_month] = React.useState(null);
    const [card_exp_year, setCard_exp_year] = React.useState(null);
    const [card_cvv, setCard_cvv] = React.useState(null);

    /* Errors states */
    const [missingInformation, setMissionInformation] = React.useState(false);
    const [missingNumber, setMissingNumber] = React.useState(false);
    const [paymentError, setPaymentError] = React.useState(false);
    const [minimalError, setMinimalError] = React.useState(false);
    const [payError, setPayError] = React.useState(false);

    const [errorTitle, setErrorTitle] = React.useState('')

    const [paymentSelected, setPaymentSelected] = React.useState(false);

    const handleChange = (panel, method) => (event, isExpanded) => {
        if (paymentSelected) {
            return
        } else {
            setExpanded(isExpanded ? panel : false);
            funPaymentMethod(method);
        }
    };

    /* finish order */
    const selectMethod = async (paymentMethod) => {
        try {
            setPaymentSelected(true);
            if (paymentMethod === "credit_card") {
                const numeroDeCaracteres = card_number.length;
                if (numeroDeCaracteres < 19) {
                    setMissingNumber(true);
                    setTimeout(() => {
                        setMissingNumber(false);
                    }, 5000);
                    setPaymentSelected(false);
                    return
                }
                if (!installments || !card_number || !card_name || !card_exp_month || !card_exp_year || !card_cvv) {
                    setMissionInformation(true);
                    setTimeout(() => {
                        setMissionInformation(false);
                    }, 5000);
                    setPaymentSelected(false);
                    return
                }
                setMakingPayment(true);
                setCreditCardSelected(true);
                try {
                    /* const creditCardOrder = */ await finishOrderCreditCard(productsList, addressId, paymentMethod, installments, card_number, card_name, card_exp_month, card_exp_year, card_cvv);
                    setMakingPayment(false);
                    return
                } catch (error) {
                    console.log(error?.response?.data?.errors)
                    setErrorTitle(error?.response?.data?.errors[0]?.messages[0]);
                    setPayError(true)
                    if (error.response.data.error === "MinimumOrderValueException") {
                        setMinimalError(true)
                    } else {
                        setPaymentError(true);
                    }
                    setMakingPayment(false);
                    setPaymentSelected(false);
                    setCreditCardSelected(false);
                    return
                }
            }
            else {
                setMakingPayment(true);
                if (paymentMethod === "pix") {
                    setPixSelected(true);
                }
                else if (paymentMethod === "boleto") {
                    setBillSelected(true);
                }
                try {
                    const cashOrder = await finishOrder(productsList, addressId, paymentMethod);
                    if (paymentMethod === "pix") {
                        setPixSelected(true);
                        setPixQrCode(cashOrder.data?.qr_code);
                    }
                    else if (paymentMethod === "boleto") {
                        setBillSelected(true);
                        setBillNumber(cashOrder.data?.boleto_line);
                        setBillPdf(cashOrder.data?.boleto_pdf);
                    }
                    setpaymentCompleted(true);
                    setMakingPayment(false);
                } catch (error) {
                    setPayError(true)
                    if (error.response.data.error === "MinimumOrderValueException") {
                        setMinimalError(true)
                    } else {
                        setPaymentError(true);
                    }
                    setPaymentSelected(false);
                    return
                }
            }
        } catch (error) {
            setPaymentSelected(false);
        }

    }
    const mappedArray = payment?.credit_card
        ? Object.keys(payment.credit_card).map((key) => ({
            label: payment.credit_card[key].label,
            value: payment.credit_card[key].times,
        }))
        : [];
    let options = mappedArray?.map(pay => ({ label: pay.label, value: pay.value }));

    const completedNavigate = () => {
        navigate("/usuario/meus_pedidos");
    }
    const backNavigate = () => {
        setPayError(false)
        /* setCompletOrder(false); */
        /*  setCreditCardSelected(false); */
        setMakingPayment(false);
        setPaymentError(false);
    }

    const renderer = ({ hours, minutes, seconds }) => {
        return <span>{hours}:{minutes}:{seconds}</span>;
    };

    const formatCreditCard = (cardNumber) => {

        setCard_number(cardNumber)
        const cleanedNumber = cardNumber.replace(/\D/g, '');
        const formattedNumber = cleanedNumber.replace(/(\d{4})(?=\d)/g, '$1 ');
        setInfCreditCardInfo(formattedNumber)
    }


    const optionsBlr = { style: 'currency', currency: 'BRL' };

    React.useState(() => {
        if (isMobile() || window.innerWidth < 800) {
            setLines2(4);
            setLines(12);
        }

    }, [window.innerWidth])

    return (
        <Container>
            <ButtonGreen onClick={handleCompletOrder} style={{ borderRadius: '20px', width: '60%' }}>
                Pagar e concluir
            </ButtonGreen>

            <Modal show={completOrder} backdrop="static" onHide={handleCompletOrder} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Métodos de pagamento</Modal.Title>
                </Modal.Header>
                {payError ?
                    <Modal.Body>
                        <Typography >
                            <div style={{ height: "auto", margin: "0 auto", width: "100%", display: 'flex', justifyContent: 'center' }}>
                                <img src={errorImage} alt="error" style={{ width: '15%' }} />

                            </div>
                            <div style={{ margin: '20px 10px', textAlign: 'center', }}>
                                <texto style={{ fontSize: '1rem', lineHeight: '1.5', color: '#000', fontWeight: '400' }}>
                                    {minimalError && "O valor do seu carrinho é menor que o pedido mínimo dessa loja !"}
                                    {paymentError && <div>
                                        Houve um erro no processamento do seu pagamento. Por favor, verifique suas informações.
                                        <br></br>
                                        <small>{errorTitle}</small>
                                    </div>}
                                </texto>
                                <div style={{ paddingTop: '10px', display: 'flex', justifyContent: 'center' }}>
                                    <DivB style={{}}>
                                        <ButtonGreen onClick={() => backNavigate()} style={{ borderRadius: '20px', width: '100%', }}>
                                            Voltar
                                        </ButtonGreen>
                                    </DivB>
                                </div>
                            </div>
                        </Typography>
                    </Modal.Body> :
                    <Modal.Body>
                        <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1', 'pix')}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                            >
                                <Typography sx={{ width: '33%', flexShrink: 0, alignContent: 'center', display: 'flex', }}>
                                    <PixIcon style={{ marginRight: '15px', color: '#30b6a8' }} /> Pix
                                </Typography>
                                <Typography sx={{ color: 'text.secondary' }}> {pixSelected && makingPayment ? "Finalizando pedido..." : "Confirmação Instantânea"}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography sx={{ display: 'flex', alignContent: 'center', justifyContent: 'center', textAlign: 'center' }}>
                                    {pixSelected ?
                                        makingPayment ? <SipperLoader color="success" /> :
                                            <div>
                                                <div style={{ marginBottom: '10px' }}>
                                                    <p>Seu código qr code é valido por -  <small style={{ color: 'red' }} ><Countdown renderer={renderer} date={Date.now() + 7200000} autoStart={true} /></small></p>
                                                </div>
                                                <div style={{ height: "auto", margin: "0 auto", width: "35%", paddingBottom: '25px' }}>
                                                    <QRCode
                                                        size={256}
                                                        style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                                                        value={pixQrCode}
                                                        viewBox={`0 0 256 256`}
                                                    />

                                                </div>
                                                <ButtonBlueSmall onClick={() => copyQrCode(pixQrCode)} style={{ borderRadius: '20px', width: '250px', }}>
                                                    Copiar o código
                                                </ButtonBlueSmall>
                                                <div style={{ margin: '20px 10px', textAlign: 'center', }}>

                                                    <texto style={{ fontSize: '1.2rem', lineHeight: '1.5', color: '#555', fontWeight: '400' }}>
                                                        Use o seu aplicativo de banco ou carteira digital para escanear este QR Code e efetuar o pagamento de forma rápida e segura com o PIX!
                                                    </texto>

                                                </div>
                                                <DivB style={{ width: '100%' }}>
                                                    <ButtonGreen onClick={() => completedNavigate()} style={{ borderRadius: '20px', width: '50%', }}>
                                                        Já fiz o pagamento
                                                    </ButtonGreen>
                                                </DivB>
                                            </div> :
                                        <DivB >
                                            <DivList style={{ flexDirection: 'column' }}>
                                                <DivResume>
                                                    <LabelResume>
                                                        Subtotal
                                                    </LabelResume>
                                                    <ValueResume>
                                                        {value?.toLocaleString('pt-BR', optionsBlr)}
                                                    </ValueResume>
                                                </DivResume>
                                                <DivResume>
                                                    <LabelResume>
                                                        {(value - (payment?.pix / 100)) < 0 ? "Taxa" : "Desconto"}
                                                    </LabelResume>
                                                    <ValueResume style={{ color: (value - (payment?.pix / 100)) < 0 ? 'red' : 'green' }}>
                                                        {(value - (payment?.pix / 100)) < 0 ? "+" : "-"}
                                                        &nbsp;
                                                        {(value - (payment?.pix / 100))?.toLocaleString('pt-BR', optionsBlr)}
                                                    </ValueResume>
                                                </DivResume>
                                                <hr></hr>
                                                <DivResume>
                                                    <LabelResume>
                                                        Total
                                                    </LabelResume>
                                                    <ValueResume>
                                                        {(payment?.pix / 100)?.toLocaleString('pt-BR', optionsBlr)}
                                                    </ValueResume>
                                                </DivResume>
                                            </DivList>
                                            <ButtonGreen onClick={() => selectMethod('pix')} style={{ borderRadius: '20px', width: '100%', background: '#30b6a8' }}>
                                                <PixIcon style={{ marginRight: '15px' }} />  Pagar com PIX
                                            </ButtonGreen>
                                        </DivB>
                                    }
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3', 'card')}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel3bh-content"
                                id="panel3bh-header"
                            >
                                <Typography sx={{ width: '33%', flexShrink: 0 }}>
                                    <CreditCardIcon style={{ marginRight: '15px', color: '#269dd4' }} /> Cartão de crédito
                                </Typography>
                                <Typography sx={{ color: 'text.secondary' }}>
                                    {creditCarSelected && makingPayment ? "Requisitando pagamento..." : "Confirmação imediata mediante a liberação do banco"}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography >
                                    {creditCarSelected ? makingPayment ?
                                        <div style={{ display: 'flex', alignContent: 'center', justifyContent: 'center', }}>
                                            <SipperLoader color="success" />
                                        </div> :
                                        <div>
                                            <div style={{ height: "auto", margin: "0 auto", width: "35%" }}>
                                                <CheckSuccess />

                                            </div>
                                            <div style={{ margin: '20px 10px', textAlign: 'center', }}>
                                                <texto style={{ fontSize: '1rem', lineHeight: '1.5', color: '#000', fontWeight: '400' }}>
                                                    Obrigado por sua compra! Seu pagamento com cartão de crédito está sendo processado. Você receberá um recibo por e-mail em breve. Agradecemos pela preferência!
                                                </texto>
                                            </div>
                                            <div style={{ display: 'flex', alignContent: 'center', justifyContent: 'center', }}>
                                                <DivB>
                                                    <ButtonGreen onClick={() => completedNavigate()} style={{ borderRadius: '20px', width: '100%', }}>
                                                        Ver meu pedido
                                                    </ButtonGreen>
                                                </DivB>
                                            </div>
                                        </div> :
                                        <Form>
                                            <Row className="mb-4">
                                                <Col xs={line}>
                                                    <Form.Group controlId="formGridCardName">
                                                        <Form.Label>* Numero do Cartão</Form.Label>
                                                        <Form.Control value={creditCardInfo} maxLength={19} onChange={e => formatCreditCard(e.target.value)} />
                                                    </Form.Group>
                                                </Col>
                                                <Col xs={line}>
                                                    <Form.Group controlId="formGridUserName">
                                                        <Form.Label>* Nome do Titular (Conforme escrito no cartão)</Form.Label>
                                                        <Form.Control value={card_name} onChange={e => setCard_name(e.target.value)} />
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            <Row className="mb-4">
                                                <Col xs={line}>
                                                    <Form.Group controlId="formGridInstallment">
                                                        <Form.Label>* Parcelamento</Form.Label>
                                                        <Select options={options} onChange={opt => setTnstallments(opt.value)} />
                                                    </Form.Group>
                                                </Col>
                                                <Col xs={line2}>
                                                    <Form.Group controlId="formGridMonth">
                                                        <Form.Label>* Mês</Form.Label>
                                                        <Form.Control value={card_exp_month} maxLength={2} onChange={e => setCard_exp_month(e.target.value)} />
                                                    </Form.Group>
                                                </Col>
                                                <Col xs={line2}>
                                                    <Form.Group controlId="formGridYear">
                                                        <Form.Label>* Ano</Form.Label>
                                                        <Form.Control value={card_exp_year} maxLength={2} onChange={e => setCard_exp_year(e.target.value)} />
                                                    </Form.Group>
                                                </Col>
                                                <Col xs={line2}>
                                                    <Form.Group controlId="formGridCode">
                                                        <Form.Label>* CVV</Form.Label>
                                                        <Form.Control value={card_cvv} maxLength={3} onChange={e => setCard_cvv(e.target.value)} />
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                <DivB>
                                                    <ButtonGreen onClick={() => selectMethod('credit_card')} style={{ borderRadius: '20px', width: '100%' }}>
                                                        <CreditCardIcon style={{ marginRight: '15px', }} />  Pagar com Cartão
                                                    </ButtonGreen>
                                                </DivB>

                                            </div>
                                        </Form>
                                    }

                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2', 'boleto')}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel2bh-content"
                                id="panel2bh-header"
                            >
                                <Typography sx={{ width: '33%', flexShrink: 0 }}>
                                    <PaymentsIcon style={{ marginRight: '15px' }} />Boleto
                                </Typography>
                                <Typography sx={{ color: 'text.secondary' }}>
                                    {billSelected && makingPayment ? "Gerando boleto..." : "Confirmação em até 3 dias útes"}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography sx={{ display: 'flex', alignContent: 'center', justifyContent: 'center', textAlign: 'center' }}>

                                    {billSelected ?
                                        makingPayment ?
                                            <div style={{ display: 'flex', alignContent: 'center', justifyContent: 'center', }}>
                                                <SipperLoader color="success" />
                                            </div> :
                                            <div>
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <div style={{ height: "auto", margin: "0 auto", width: "100%", border: '1px solid #5555', padding: '10px', backgroundColor: '#5555', borderRadius: '4px' }}>
                                                        <texto style={{ fontSize: '0.9rem', lineHeight: '1.5', color: '#000', fontWeight: '400' }}>
                                                            {billNumber}
                                                        </texto>
                                                    </div>
                                                    <div onClick={() => copyNilluNumber(billNumber)} style={{ marginLeft: '10px', border: '1px solid #5555', height: "100%", flex: '1', backgroundColor: '#F1F1F3', borderRadius: '4px', cursor: 'pointer' }}>
                                                        <Tooltip title="Copiar código">
                                                            <ContentCopyIcon />
                                                        </Tooltip>
                                                    </div>
                                                    <a href={billPdf} target="_blank" rel="noreferrer">
                                                        <div style={{ marginLeft: '10px', border: '1px solid #5555', height: "100%", flex: '1', backgroundColor: '#F1F1F3', borderRadius: '4px', cursor: 'pointer' }}>
                                                            <Tooltip title="Abrir Boleto">
                                                                <OpenInNewIcon />
                                                            </Tooltip>
                                                        </div>
                                                    </a>
                                                </div>
                                                <div style={{ margin: '20px 10px', textAlign: 'center', }}>
                                                    <texto style={{ fontSize: '1.2rem', lineHeight: '1.5', color: '#555', fontWeight: '400' }}>
                                                        Seu boleto foi gerado e enviado para o seu e-mail. Se desejar, você também pode copiar o código, ou <a href={billPdf} target="_blank" rel="noreferrer">abrir em uma nova guia</a> e efetuar o pagamento.
                                                    </texto>
                                                </div>
                                                <ButtonGreen onClick={() => completedNavigate()} style={{ borderRadius: '20px', width: '40%', }}>
                                                    Ver meu pedido
                                                </ButtonGreen>
                                            </div> :
                                        <DivB>
                                            <DivList style={{ flexDirection: 'column' }}>
                                                <DivResume>
                                                    <LabelResume>
                                                        Subtotal
                                                    </LabelResume>
                                                    <ValueResume>
                                                        {value?.toLocaleString('pt-BR', optionsBlr)}
                                                    </ValueResume>
                                                </DivResume>
                                                <DivResume>
                                                    <LabelResume>
                                                        {(value - (payment?.boleto / 100)) < 0 ? "Taxa Boleto" : "Desconto"}
                                                    </LabelResume>
                                                    <ValueResume style={{ color: (value - (payment?.boleto / 100)) < 0 ? 'red' : 'green' }}>
                                                        {(value - (payment?.boleto / 100)) < 0 ? "+" : "-"}
                                                        &nbsp;
                                                        {(value - (payment?.boleto / 100))?.toLocaleString('pt-BR', optionsBlr)}
                                                    </ValueResume>
                                                </DivResume>
                                                <hr></hr>
                                                <DivResume>
                                                    <LabelResume>
                                                        Total
                                                    </LabelResume>
                                                    <ValueResume>
                                                        {(payment?.boleto / 100)?.toLocaleString('pt-BR', optionsBlr)}
                                                    </ValueResume>
                                                </DivResume>
                                            </DivList>
                                            <ButtonGreen onClick={() => selectMethod('boleto')} style={{ borderRadius: '20px', width: '100%', }}>
                                                <PaymentsIcon style={{ marginRight: '15px' }} /> Pagar com Boleto
                                            </ButtonGreen>
                                        </DivB>
                                    }
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                    </Modal.Body>
                }

                <Modal.Footer></Modal.Footer>
                {missingInformation && <SnackBar type="warning" time={5000} message="Preencher todos os campos" states={true} />}
                {missingNumber && <SnackBar type="warning" time={5000} message="Verifique o numero do seu cartão" states={true} />}
            </Modal>
        </Container >
    )
}

export default PaymentOption