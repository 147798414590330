import { Document, Page, Text, View, PDFDownloadLink, StyleSheet, Image } from '@react-pdf/renderer';
import { ButtonGreen } from '../../../../Styles/stylesButtons';
import logo from '../../../../Assets/Login/logo_white.png'
import { useInformation } from '../../../../Providers/informations';
import dayjs from 'dayjs';


const styles = StyleSheet.create({
    page: {
        flexDirection: 'row',
    },
    section: {
        margin: 10,
        padding: 10,
        flexGrow: 1,
        backgroundColor: '#ffffff',
        border: '1px solid #c7c8c9'
    },
    top: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingBottom: '20px',
        width: '100%'
    },
    left: {
        width: '80%',
        border: '1px solid #c7c8c9',
        padding: '5px',
        borderRadius: '6px'
    },
    center: {
        width: '100%',
        border: '1px solid #f7f7f7',
        padding: '5px',
        borderRadius: '6px'
    },
    divz: {
        flexDirection: 'row',
        fontSize: 12,
        borderBottom: '1px solid #f7f7f7',
    },
    divzTop: {
        flexDirection: 'row',
        fontSize: 12,
        borderBottom: '1px solid #f7f7f7',
        height: '20px',
    },
    divY: {
        flexDirection: 'row',
        fontSize: 15,
        justifyContent: 'space-between',
        padding: '10px 0',
        borderBottom: '1px solid #c7c8c9',
    },
    image: {
        backgroundColor: '#009c43',
        width: '20%',
        border: '1px solid #f7f7f7',
        padding: '5px',
        borderRadius: '6px',
    },
    itenD: {
        textAlign: 'left',
    },
    itemC: {
        textAlign: 'center',
    },
    line: {
        flexDirection: 'row',
        fontSize: 12,
        justifyContent: 'space-between',
        padding: '10px 0',
        borderBottom: '1px solid #f7f7f7',
        textAlign: 'left',
    },
    line2: {
        flexDirection: 'row',
        fontSize: 12,
        textAlign: 'left',
        width: '50%'
    },
    line3: {
        flexDirection: 'row',
        fontSize: 12,
        textAlign: 'center',
        width: '15%'
    },
    table: {
        border: '1px solid #f7f7f7',
        padding: '5px',
        borderRadius: '6px'
    }
});


const ExportReport = ({ detailSales, m, y }) => {

    const dataAtual = dayjs();
    const { storeInfo } = useInformation();

    const options = { style: 'currency', currency: 'BRL' };

    return (
        <div>
            <PDFDownloadLink style={{ color: '#009c43', textDecoration: 'none', }} document={<Document>
                <Page size="A4" style={styles.page}>
                    <View style={styles.section}>
                        <View id="top" style={styles.top}>
                            <View style={styles.image}>
                                <Image
                                    src={logo}
                                />
                            </View>
                            {/* Info sobre o cliente */}
                            <View id="left" style={styles.left}>
                                <View style={styles.divz}>
                                    <View style={{ width: '33%' }}>
                                        <Text>Loja:</Text>
                                    </View>
                                    <View style={{ width: '67%' }}>
                                        <Text>{storeInfo?.StoreName}</Text>
                                    </View>
                                </View>
                                <View style={styles.divz}>
                                    <View style={{ width: '33%' }}>
                                        <Text>CNPJ:</Text>
                                    </View>
                                    <View style={{ width: '67%' }}>
                                        <Text>{storeInfo?.StoreCnpj}</Text>
                                    </View>
                                </View>
                                <View>
                                    <View style={styles.divz}>
                                        <View style={{ width: '33%' }}>
                                            <Text>Endereço:</Text>
                                        </View>
                                        <View style={{ width: '67%' }}>
                                            <Text> {storeInfo?.StoreStreet} {storeInfo?.StoreNumber}, {storeInfo?.StoreDistrict} - {storeInfo?.StoreCity} {storeInfo?.StoreState} - {storeInfo?.StoreCountry} </Text>
                                        </View>
                                    </View>
                                </View>
                            </View>
                        </View>
                        {/* Info sobre relatório */}
                        <View id="center" style={styles.center}>
                            <View style={styles.divzTop}>
                                <View style={{ width: '33%' }}>
                                    <Text>Relatório de vendas referente a:</Text>
                                </View>
                                <View style={{ width: '67%' }}>
                                    <Text>{`${m}/${y}`}</Text>
                                </View>
                            </View>
                            <View style={styles.divzTop}>
                                <View style={{ width: '33%' }}>
                                    <Text>Gerado em:</Text>
                                </View>
                                <View style={{ width: '67%' }}>
                                    <Text>{dataAtual.format('DD/MM/YYYY HH:mm')}</Text>
                                </View>
                            </View>
                        </View>
                        {/* Table Produtos */}
                        <View style={styles.table}>
                            <View style={styles.divY}>
                                <View style={styles.line3}>
                                    <Text style={styles.itenD}>Data</Text>
                                </View>
                                <View style={styles.line2}>
                                    <Text style={styles.itenD}>Vendas</Text>
                                </View>
                                <View style={styles.line3}>
                                    <Text style={styles.itemC}>Valor Total</Text>
                                </View>
                            </View>


                            <View >
                                {detailSales?.map((prod) => (
                                    <View style={styles.line} key={prod?.date}>
                                        <View style={styles.line3}>
                                            <Text>{prod?.date}</Text>
                                        </View>
                                        <View style={styles.line2}>
                                            <Text> {prod?.vendas}</Text>
                                        </View>
                                        <View style={styles.line3}>
                                            <Text>{(prod?.valor / 100).toLocaleString('pt-BR', options)}</Text >
                                        </View>
                                    </View>
                                ))}
                            </View>


                        </View>
                    </View>
                </Page>
            </Document>} fileName="relatório_vendas.pdf">
                {({ blob, url, loading, error }) =>
                    loading ? 'Gerando PDF...' : <div><ButtonGreen> Exporta Relatório</ButtonGreen></div>
                }
            </PDFDownloadLink>
        </div>
    )
}
export default ExportReport;