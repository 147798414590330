import styled from "styled-components";

export const Container = styled.div`
    margin: 0 3%;
    padding: 0;

    @media(max-width: 800px){
        margin: 0%;
    }
`;

export const CarouselSection = styled.div`
    width: 100%;
    align-content: center;
    justify-content: center;
    align-items: center;

   
`;

export const ProductSection = styled.div`
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    @media(max-width: 800px){
        width: 100vw;
        flex-direction: column;
    }
`;

export const Title = styled.h3`
    font-size: 1.8rem; 
    color: #7d7d7d;

    @media(max-width: 800px){
        font-size: 1.6rem; 
    }
`;

export const ButtonFl = styled.div`
    display: block;
    z-index: 9999; 
    width: 50px; 
    height: 50px; 
    background-color: #009c43; 
    color: #fff; 
    font-size: 16px;
    text-align: center;
    align-items: center;
    line-height: 45px;
    border: none;
    border-radius: 100%;
    cursor: pointer;
    &:hover{
        background-color: #007a37;
        width: 80px;
        height: 80px;
        line-height: 75px;
    }                         
`;