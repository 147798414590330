import React from 'react';
import { Navigate } from 'react-router-dom';

const Verify = ({ children }) => {
    const auth = localStorage.getItem('ClientAuth');
    return auth !== "true" ? children : <Navigate to="/usuario/perfil" />;
  }

export default Verify;

