import styled from "styled-components";

export const Container = styled.div`
    padding: 2%;
`;
export const Line = styled.div`
    width: 100%;
    display: flex;
    padding: 0px 15px;
    padding-bottom: 15px ;
    @media(max-width: 800px){
        flex-direction: column;
    }
`;
export const InfoTable = styled.div`
    flex:1;
    height: 100vh;
    background-color: #fff ;
    box-shadow: 0px 0px 9px #26242424;
    border-radius: 6px;
    border: 1px solid #c6c6c6 ;
    overflow: auto;

    @media(max-height: 768px){
        height: 100vh;
    }
`;

export const SmallCard = styled.div`
    background-color: #fff ;
    box-shadow: 0px 0px 9px #26242424;
    border: 1px solid #c6c6c6 ;
    border-radius: 6px;
    height: calc(100vh / 3 - 10px);
    width: 100%;
    margin: 0px 10px;
    display: flex;
    flex-direction: column;
    padding: 15px 15px;
    margin-bottom: 15px;
`;
export const TitleArea = styled.div`
    height: 1rem;
    width: 100%;
    padding: 0 15px;
`;

export const Area = styled.div`
    display: flex;
    height: cal(100% - 2rem);
    flex: 1;
`;

export const Square = styled.div`
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
`;

export const Small = styled.small`
    color: #6E6E6E;
`;

export const Bottom = styled.div`
    display: flex;
    justify-content: flex-end;
    width: 100%;
    height: 1.2rem;
`;
export const DivMidle = styled.div`
    width: 25%;
    height: 100vh;
    padding: 0 10px;
    @media(max-width: 800px){
        width: 100%;
    }
`;