import React from "react";
import empty from '../../../Assets/ImagensGerais/empty.png';
import { useParams } from "react-router-dom";
import Divider from '@mui/material/Divider';
import { getStore, getStoreProducts } from "../../../Services/getProducts";
import CarouselStore from "../../Components/General/CarouselStore";
import { Grid, Pagination, Stack } from "@mui/material";
import CardProduct from "../../Components/General/CardProduct";
import { ProductSection } from "../Search/styles";
import { TitleH3 } from '../../../Styles/style';
import { ReactComponent as EmptyImage } from '../../../Assets/icons/empty.svg';
import { useLocation, useNavigate } from "react-router-dom";
import localforage from "localforage";
import {
    Container,
    Content,
    Title,
} from './styles';
import axios from "axios";



const ProductsByStore = () => {

    let param = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const [totalOfPages, setTotalOfPages] = React.useState();
    const [nexProducts, setNextProducts] = React.useState();
    const [pageWidth, setPageWidth] = React.useState(window.innerWidth);
    const [listSearchedProducts, setListSearchedProducts] = React.useState([])
    const [logo, setLogo] = React.useState();
    const [banner, setBanner] = React.useState();
    const [page, setPage] = React.useState(1);

    const getProductsStore = async () => {
        try {
            const listProductsResponse = await getStoreProducts(param.slug)
            console.log(listProductsResponse, 'iais')
            setTotalOfPages(listProductsResponse?.meta.last_page);
            setNextProducts(listProductsResponse?.meta.links);
            setListSearchedProducts(listProductsResponse?.data);
            localforage.setItem('products', listProductsResponse);
        } catch (error) {
            console.log(error)
        }
    }

    const getLogo = async () => {
        try {
            const response = await getStore(param.slug);
            console.log(response);
            setBanner(response.data.store.banner);
            setLogo(response.data.store.logo);
        } catch (error) {

        }
    }

    React.useEffect(() => {
        setPageWidth(window.innerWidth);
        getLogo();
    }, [])

    const getNextpage = async (page, numPage) => {
        const { data: res } = await axios({ method: 'get', url: `${page}` })
        setListSearchedProducts(res.data);

        let listProducts = await localforage.getItem('products');
        setTotalOfPages(listProducts?.meta.last_page);
        setNextProducts(listProducts?.meta.links);
        setPage(parseInt(localStorage.getItem('pageNumber')))
    }

    React.useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        if (searchParams.get('page')) {
            getNextpage(searchParams.get('page'), searchParams.get('numpage'));
        } else {
            getProductsStore();
        }
    }, [location])

    const nextPage = (value) => {
        navigate(`${location.pathname}?page=${nexProducts?.[value]?.url}`)
        localStorage.setItem('locationPage', `${location.pathname}?page=${nexProducts?.[value]?.url}`)
        localStorage.setItem('pageNumber', value);
    }

    const handleChange = (event, value) => {
        setPage(value);
        nextPage(value);
    };

    return (
        <Container>
            <CarouselStore banner={banner} logo={logo} />
            {listSearchedProducts?.length > 0 ?
                <Content>
                    <Divider textAlign="left"><Title>{param.name}</Title></Divider>
                    <ProductSection style={{ width: '100%' }}>
                        <Grid container spacing={1} >
                            <Grid container item spacing={0.5}>
                                {listSearchedProducts.map((list) => (
                                    <Grid item xs={pageWidth <= 800 ? 6 : 3} spacing={1} sx={{ display: 'flex', justifyContent: pageWidth <= 800 ? 'center' : 'flex-end' }} key={list?.id}>
                                        <CardProduct cat="linha_sementes" img={!list?.images ? empty : `${process.env.REACT_APP_LINK}/${list?.images?.[0]}`} title={list?.title} description={list?.description} price={list?.price} store={list.store_slug} slug={list.product_slug} />
                                    </Grid>
                                ))}
                            </Grid>
                            <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginBottom: '25px' }}>
                                <Stack spacing={2}>
                                    <Pagination count={totalOfPages} page={page} onChange={handleChange} />
                                </Stack>
                            </div>
                        </Grid>
                    </ProductSection>
                </Content> :
                <Content style={{ textAlign: 'center' }}> <Divider textAlign="left"><Title>{param.name}</Title></Divider>
                    <div style={{ padding: '25px' }}>
                        <TitleH3 style={{ color: '#b8b7b7' }}>Essa loja ainda não cadastrou produtos</TitleH3>
                    </div>
                </Content>
            }
        </Container>
    )
}
export default ProductsByStore;
