import React from 'react';
import AdmSideBarMenu from '../../Components/SideBarMenu';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useLocation, useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import { Modal } from 'react-bootstrap';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';

import {
    Container,
    Content,
    InfoTable,
    Line,
    Search,
    ListS,
} from './styles';
import axios from 'axios';
import moment from 'moment';
import { blockStore, deleteStore } from '../../../Services/adm';
import SnackBarAdm from '../../Components/SnackBar';


const StoreList = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const [slug, setStoreSlud] = React.useState();
    const [storeName, setStoreName] = React.useState();
    const [storedetail, setStoreDetail] = React.useState([]);
    const [storeList, setStoreList] = React.useState([]);


    const [show, setShow] = React.useState(false);
    const [detailS, setDetailS] = React.useState(false);
    const handleClose = () => setShow(false);

    const [blockedSuccess, setBlockedSuccess] = React.useState(false);
    const [blockedErros, setBlockedError] = React.useState(false);

    const [deletedSuccess, setDeletedSuccess] = React.useState(false);
    const [deletedErros, setDeletedError] = React.useState(false);

    const handleOpenD = (store) => {
        setStoreDetail(store)
        setDetailS(!detailS);
    };

    const handleCloseD = () => {
        setDetailS(!detailS);
    };

    const requestToAprove = (slug, name) => {
        setStoreSlud(slug);
        setStoreName(name);
        setShow(true);
    }

    const aprove = () => {
        try {
            axios({ method: 'get', url: `${process.env.REACT_APP_LINK}/api/admin/stores/allow/${slug}`, headers: { 'Authorization': `Bearer ${localStorage.getItem('Token')}` } })
                .then(res => {
                    setShow(false);
                    navigate('/agroProdutos_management/supervisor/store')
                })
                .catch(err => {

                })
        } catch (error) {

        }

    }

    const blockS = async (slug) => {
        try {
            const response = await blockStore(slug);
            if (response.message === "success") {
                setBlockedSuccess(true);
                getStoreList();
            }
        } catch (error) {
            setBlockedError(true);
            setTimeout(() => {
                setBlockedError(false);
            }, 3000);
        }
    }

    const deleteStoreF = async (id) => {
        try {
           /*  const response = */ await deleteStore(id);
            setDeletedSuccess(true);
            getStoreList();
        } catch (error) {
            setDeletedError(true);
            setTimeout(() => {
                setDeletedError(false);
            }, 3000);
        }
    }

    const getStoreList = () => {
        try {
            axios({ method: 'get', url: `${process.env.REACT_APP_LINK}/api/admin/stores`, headers: { 'Authorization': `Bearer ${localStorage.getItem('Token')}` } })
                .then(res => {
                    setStoreList(res.data.data.stores);
                })
                .catch(err => {

                })
        } catch (error) {

        }
    }

    React.useEffect(() => {
        getStoreList()
    }, [location])
    return (
        <Container>
            <div style={{ padding: '10px 5px' }}>
                <AdmSideBarMenu />
            </div>
            <Content>
                <InfoTable>
                    <Line style={{ justifyContent: 'space-between', display: "flex", paddingTop: '15px', }}>
                        <div style={{ paddingLeft: '25px' }}>
                            Lojas
                        </div>
                        <Search>
                            <input type="text" placeholder='Procurar' style={{ color: '#A9ABAE' }} />
                        </Search>
                        <div>
                            <MoreVertIcon style={{ cursor: "pointer" }} />
                        </div>
                    </Line>
                    <Divider variant="inset" />
                    <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                        {storeList.map((listStore) =>
                            <ListS key={listStore?.id}>
                                <ListItem alignItems="flex-start" sx={{ height: '100px' }}>
                                    <ListItemAvatar onClick={() => handleOpenD(listStore)}>
                                        <Avatar alt={listStore.name} src={listStore?.logo} />
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={listStore.name}
                                        secondary={
                                            <React.Fragment>
                                                <Typography
                                                    sx={{ display: 'inline' }}
                                                    component="span"
                                                    variant="body2"
                                                    color="text.primary"
                                                >
                                                    {listStore.status}
                                                </Typography>
                                                {` — ${listStore.phone} — ${listStore.cnpj}`}
                                            </React.Fragment>
                                        }
                                    />
                                    {listStore.status === "pending" && <Button variant="contained" color="success" onClick={() => requestToAprove(listStore.slug, listStore.name)}>Aprovar</Button>}
                                    {listStore.status === "blocked" && <Button variant="contained" color="success" onClick={() => requestToAprove(listStore.slug, listStore.name)}>Aprovar</Button>}
                                    {listStore.status === "active" && <Button variant="contained" onClick={() => blockS(listStore?.slug)} color="error">Bloquear</Button>}
                                </ListItem>
                                <Divider variant="inset" component="li" />
                            </ListS>
                        )}
                    </List>
                </InfoTable>
            </Content>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Modal heading</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Deseja confirmar a requisição da loja: {storeName} ?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={aprove}>
                        Save
                    </Button>
                </Modal.Footer>
                <div id="sanckbar">

                </div>
            </Modal>

            <Modal show={detailS} onHide={handleCloseD}>
                <Modal.Header closeButton>
                    <Modal.Title>Detalhe da Loja</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ display: 'flex', justifyContent: 'center' }}>
                    <Card sx={{ maxWidth: 345 }}>
                        <CardMedia
                            sx={{ height: 140 }}
                            image={storedetail?.banner}
                            title={storedetail?.name}
                        />
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div">
                                {storedetail?.name} / {storedetail?.cnpj}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                <p>Endereço: {storedetail?.street},{storedetail?.number} - {storedetail?.district} - {storedetail?.city}/{storedetail?.state} - {storedetail?.country}</p>
                                <p>Status: {storedetail?.status}</p>
                                <p>Entrou em : {moment(storedetail.created_at).format('DD/MM/YYYY')}</p>
                            </Typography>
                        </CardContent>
                        <CardActions>
                            <Button size="small" style={{ color: 'red' }} onClick={() => deleteStoreF(storedetail?.id)}>Excluir Loja</Button>
                        </CardActions>
                    </Card>
                </Modal.Body>

                <div id="sanckbar">

                </div>
            </Modal>
            {blockedErros && <SnackBarAdm type="error" time={3000} message="Não foi possível bloquear a loja!" states={blockedErros} />}
            {blockedSuccess && <SnackBarAdm type="success" time={3000} message="Loja bloqueada!" states={blockedErros} />}

            {deletedSuccess && <SnackBarAdm type="success" time={3000} message="Loja excluida com sucesso!" states={blockedErros} />}
            {deletedErros && <SnackBarAdm type="error" time={3000} message="Não foi possível excluir a loja!" states={blockedErros} />}
        </Container>
    )
}

export default StoreList;

