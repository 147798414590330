import axios from "axios"

export async function getBannersList(page) {
    var { data: res } = await axios({ method: 'get', url: `${process.env.REACT_APP_LINK}/api/public/banners/${page}` })
    return res
}

export async function getAds(page) {
    var { data: res } = await axios({ method: 'get', url: `${process.env.REACT_APP_LINK}/api/public/banners/advertisement/${page}` })
    return res
}