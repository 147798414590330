import axios from "axios";

export async function GetLine1(num, line) {
    const { data: res } = await axios({ method: 'get', url: `${process.env.REACT_APP_LINK}/api/public/products/category/${line}/${num}`, headers: { 'Accept': 'application/json' } })
    return res
}

export async function getdetailProduct(slug, store) {
    try {
        const { data: res } = await axios({ method: 'get', url: `${process.env.REACT_APP_LINK}/api/public/stores/${store}/products/${slug}`, headers: { 'Accept': 'application/json' } })
        return res
    } catch (error) {
        throw error.response
    }
}

export async function getProductsCart() {
    try {
        const { data: res } = await axios({ method: 'get', url: `${process.env.REACT_APP_LINK}/api/user/cart`, headers: { 'Authorization': `Bearer ${localStorage.getItem("clientToken")}` } })
        return res
    } catch (error) {
        throw error.response
    }
}

export async function getProductsOrder() {
    try {
        const { data: res } = await axios({ method: 'get', url: `${process.env.REACT_APP_LINK}/api/user/cart/pre-order`, headers: { 'Authorization': `Bearer ${localStorage.getItem("clientToken")}` } })
        return res
    } catch (error) {
        throw error.response
    }
}
export async function getFavorites() {
    try {
        const { data: res } = await axios({ method: 'get', url: `${process.env.REACT_APP_LINK}/api/user/favorites`, headers: { 'Authorization': `Bearer ${localStorage.getItem("clientToken")}` } })
        return res
    } catch (error) {
        throw error.response
    }
}

export async function getViewedProducts() {
    try {
        const { data: res } = await axios({ method: 'get', url: `${process.env.REACT_APP_LINK}/api/user/viewed`, headers: { 'Authorization': `Bearer ${localStorage.getItem("clientToken")}` } })
        return res.data
    } catch (error) {
        throw error.response
    }
}

export async function searchProducts(parameters) {
    try {
        var data = {
            "search": parameters,
        }
        const { data: res } = await axios({ method: 'post', url: `${process.env.REACT_APP_LINK}/api/public/products`, headers: { 'Accept': 'application/json' }, data })
        return res
    } catch (error) {
        return error.response.data
    }
}

export async function deleteCarProduct(parameters, storeSlug) {
    try {
        var { data: res } = await axios({ method: 'delete', url: `${process.env.REACT_APP_LINK}/api/user/cart/remove/${storeSlug}/${parameters}`, headers: { 'Authorization': `Bearer ${localStorage.getItem("clientToken")}` } })
        return res
    } catch (error) {
        return error.response.data
    }
}

export async function getCategory() {
    try {
        var { data: res } = await axios({ method: 'get', url: `${process.env.REACT_APP_LINK}/api/public/product-categories` })
        return res
    } catch (error) {
        return error
    }
}

export async function getReviews(store, product) {
    var { data: res } = await axios({ method: 'get', url: `${process.env.REACT_APP_LINK}/api/public/products/reviews/${store}/${product}` })
    return res
}

export async function getStores() {
    var { data: res } = await axios({ method: 'get', url: `${process.env.REACT_APP_LINK}/api/public/stores` })
    return res
}

export async function getStoreProducts(slug) {
    var { data: res } = await axios({ method: 'get', url: `${process.env.REACT_APP_LINK}/api/public/stores/${slug}/products` })
    return res
}
export async function getStore(slug) {
    var { data: res } = await axios({ method: 'get', url: `${process.env.REACT_APP_LINK}/api/public/stores/${slug}` })
    return res
}

export async function edirPreOrder(slug, value) {
    var data = {
        "selected": value
    }
    var { data: res } = await axios({ method: 'put', url: `${process.env.REACT_APP_LINK}/api/user/cart/pre-order/${slug}`, headers: { 'Authorization': `Bearer ${localStorage.getItem("clientToken")}` }, data })
    return res
}

export async function getQuestionsList(store, product) {
    const { data: res } = await axios({ method: 'get', url: `${process.env.REACT_APP_LINK}/api/public/products/questions/${store}/${product}` })
    return res
}

export async function getIndex() {
    const { data: res } = await axios({ method: 'get', url: `${process.env.REACT_APP_LINK}/api/public/index` })
    return res
}