import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { InformationProvider } from './Providers/informations';
import { AdmInformationProvider } from './Providers/admInformations';


ReactDOM.render(
  <React.StrictMode>
    <AdmInformationProvider>
      <InformationProvider>
        <App />
      </InformationProvider>
    </AdmInformationProvider>
  </React.StrictMode>,
  document.getElementById('root')
)
