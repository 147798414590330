import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    height: 100%;
`;

export const Middle = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 2%;
    margin-bottom: 5%;
    justify-content: center;
    background-color: #fff;
    border-radius: 6px;
    width: 62rem;
    height: 100%;
    padding: 15px;


   > h2{
    font-weight: bold;
    font-size: 4rem;
    opacity: 0.8;
    color: #009c43;
    padding-bottom: 0.3rem;
   }

   >h6 {
    font-weight: 600;
    font-size: 1.2rem;
    color: #434343;
    padding-bottom: 0.3rem;
   }
   >small{
     font-size: 0.9rem;
     padding-bottom: 0.6rem;
   }

`;