import styled from "styled-components";

export const Container = styled.div`

`;
export const DivMain = styled.div`
    border-radius: 6px;
    @media(max-width: 900px){
        height: 350px;
    }
    @media(max-width: 650px){
        height: 250px;
    }
    @media(max-width: 400px){
        height: 200px;
    }
`;
export const DivImg = styled.div`
    height: 250px;
    border-radius: 6px;
    
`;