import React from 'react';
import CommonLogin from '../Ecomerce/Pages/Login/CommonLogin';
import Layout from '../Ecomerce/Components/General/Layout';
import MainPage from '../Ecomerce/Pages/MainPage';
import CommonRegister from '../Ecomerce/Pages/Login/CommonRegister';
import ForgotPassword from '../Ecomerce/Pages/Login/FogotPassword';
import Profile from '../Ecomerce/Pages/Profile';
import PrivateRoute from '../Hooks/ClientAuth';
import StoreProfile from '../Ecomerce/Pages/StoreProfile';
import Verify from '../Hooks/VerifyLogin';
import ProductsLIst from '../Ecomerce/Pages/Store/Products/ProductsList';
import Cart from '../Ecomerce/Pages/Cart';
import Favorite from '../Ecomerce/Pages/Favorite';
import OrderReview from '../Ecomerce/Pages/OrderReview';
import SearchPage from '../Ecomerce/Pages/Search';
import MyOrders from '../Ecomerce/Pages/MyOrders';
import PaymentCompleted from '../Ecomerce/Pages/PaymentCompleted';
import ProductDetails from '../Ecomerce/Pages/ProductDetail';
import VerifyEmail from '../Ecomerce/Pages/Login/VerifyEmail';
import Error404 from '../Ecomerce/Pages/Error';
import MySales from '../Ecomerce/Pages/Store/Sales';
import Categories from '../Ecomerce/Pages/Categories';
import ResetPassword from '../Ecomerce/Pages/Login/ResetPassword';
import StoresList from '../Ecomerce/Pages/StoresList';

import {
  Routes,
  Route,
} from "react-router-dom";
import ProductsByStore from '../Ecomerce/Pages/ProductsByStore';
import SalesDetail from '../Ecomerce/Pages/Store/SalesDetail/inex';
import AboutUs from '../Ecomerce/Pages/AboutUs';
import RequestWithdrawl from '../Ecomerce/Pages/Store/RequestWithdrawl/indes';
import Questions from '../Ecomerce/Pages/Store/Questions';
import Help from '../Ecomerce/Pages/Help';
import SalesReport from '../Ecomerce/Pages/Store/SalesReport';

const AppRoutes = () => (
  <Layout>
    <Routes>
      <Route path="" element={<MainPage />} />
      <Route path='/login'>
        <Route exact path=':param' element={<Verify> <CommonLogin /> </Verify>} />
        <Route exact path='' element={<Verify> <CommonLogin /> </Verify>} />
      </Route>
      <Route path='/register'>
        <Route exact path=':param' element={<Verify> <CommonRegister /> </Verify>} />
        <Route exact path='' element={<Verify> <CommonRegister /> </Verify>} />
      </Route>

      <Route exact path='/esqueci_senha' element={<ForgotPassword />} />
      <Route exact path='/reset-password/:token' element={<ResetPassword />} />
      <Route exact path='/confirmar/email' element={<VerifyEmail />} />

      <Route exact path='/usuario/perfil' element={<PrivateRoute><Profile /></PrivateRoute>} />
      <Route exact path='/usuario/meus_pedidos' element={<PrivateRoute> <MyOrders /> </PrivateRoute>} />
      <Route exact path='/produtos/favoritos/lista' element={<PrivateRoute> <Favorite /> </PrivateRoute>} />
      <Route exact path='/produtos/cart/lista' element={<PrivateRoute> <Cart /> </PrivateRoute>} />
      <Route exact path='/produtos/compra/finalizar/:param' element={<PrivateRoute> <OrderReview /> </PrivateRoute>} />
      <Route exact path='/payment_completed' element={<PaymentCompleted />} />


      <Route exact path='/produtos/lista' element={<PrivateRoute> <ProductsLIst /> </PrivateRoute>} />
      <Route exact path='/loja/perfil' element={<PrivateRoute> <StoreProfile /> </PrivateRoute>} />
      <Route exact path='/loja/vendas' element={<PrivateRoute> <MySales /> </PrivateRoute>} />
      <Route exact path='/loja/vendas/relatorios' element={<PrivateRoute> <SalesReport /> </PrivateRoute>} />
      <Route exact path='/loja/perguntas' element={<PrivateRoute> <Questions /> </PrivateRoute>} />
      <Route exact path='/loja/vendas/detalhe' element={<PrivateRoute> <SalesDetail /> </PrivateRoute>} />
      <Route exact path='/loja/ganhos' element={<PrivateRoute> <RequestWithdrawl /> </PrivateRoute>} />


      <Route exact path="/produto/detalhes/:store/:slug" element={<ProductDetails />} />
      <Route exact path='/produtos/buscar/:search' element={<SearchPage />} />

      <Route exact path='/lojas/lista' element={<StoresList />} />
      <Route exact path='/loja/:name/:slug/produtos' element={<ProductsByStore />} />
      <Route exact path='/categorias/:categories/:title' element={<Categories />} />
      <Route exact path='/sobre-nos' element={<AboutUs />} />
      <Route exact path='/ajuda' element={<Help />} />
      <Route path='*' element={<Error404 />} />
    </Routes>
  </Layout>

);

export default AppRoutes;