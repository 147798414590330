import React from 'react';
import * as GetProducts from '../../../../Services/getProducts';
import CarProductCard from '../CarProductCard';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import empty_car from '../../../../Assets/icons/carrinho_vazio.png';
import { TitleH3 } from '../../../../Styles/style';
import {
    Container,
    DivHead,
    ProductSection,
    DivBottom,
    EmptyDiv,
} from './styles';
import { ButtonGreen } from '../../../../Styles/stylesButtons';


const CartListProducts = () => {

    const location = useLocation();
    const navigate = useNavigate();

    const [productsList, setProductsList] = React.useState([]);
    const [totalPrice, setTotalPrice] = React.useState();

    const [control, setControl] = React.useState(true);

    const options = { style: 'currency', currency: 'BRL' };

    const getProductsCart = async () => {
        const products = await GetProducts.getProductsCart();
        setProductsList(products.data.products);


        const price = products.data.products.reduce((accumulator, product) => {
            if (product.pre_order_selected === 1) {
                return accumulator + parseFloat(product.total_price);
            }
            return accumulator;
        }, 0);

        /* products.data.products.map((product) => {
            if (product.pre_order_selected === 1) {
                price = price + parseFloat(product.total_price);
            }
        }); */
        setTotalPrice(price)
    }

    const createOrder = () => {
        navigate('/produtos/compra/finalizar/verify');
    }

    const getCartAgain = () => {
        getProductsCart();
        setControl(true);
    };
    React.useEffect(() => {
        getProductsCart();
    }, [location]);

    return (
        <Container>
            <DivHead>
                <div style={{ display: 'flex', justifyContent: 'space-between', padding: '15px', width: '50%' }}>
                    <TitleH3>Carrinho de Compra</TitleH3>
                </div>
            </DivHead>
            <hr></hr>
            {productsList?.length > 0 ?
                <div style={{ height: '100%', paddingBottom: '2%' }}>
                    <ProductSection >
                        {productsList?.map((products) =>
                            <CarProductCard Key={products?.id} products={products} origin="cart" key={products.id} funcGet={getCartAgain} control={control} set={setControl} />
                        )}
                    </ProductSection>
                    <hr></hr>
                    <DivBottom>
                        <p>Total: <b>{totalPrice?.toLocaleString('pt-BR', options)}</b></p>
                        <ButtonGreen onClick={createOrder} >Próxima Etapa</ButtonGreen>
                        {/*  <DeliveryOptions /> */}
                    </DivBottom>
                </div> :
                <EmptyDiv>
                    <img alt="car" src={empty_car} />
                    <p>Seu carrinho está vazio!</p>
                    <Link to="/">Vá as Compras</Link>
                </EmptyDiv>
            }


        </Container>
    )
}

export default CartListProducts;