import React from 'react';
import CircIconShow from '../CircIconShow';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Avatar from '@mui/material/Avatar';
import AvatarGroup from '@mui/material/AvatarGroup';
import { Carousel } from 'react-responsive-carousel';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';



import {
    Container,
    ItemArea,
    arrowStyles
} from './styles';

const Categories = ({ list }) => {

    const [pageWidth, setPageWidth] = React.useState(window.innerWidth);

    React.useEffect(() => {
        function handleResize() {
            setPageWidth(window.innerWidth);
        }
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const itemsPerPage = window.innerWidth <= 1600 ? 6 : 8;
    const pages = [];
    for (let i = 0; i < list.length; i += itemsPerPage) {
        pages.push(list.slice(i, i + itemsPerPage));
    }
    return (
        <Container>
            {/*   <Divider><h3 style={{ fontSize: '2rem', color: '#7d7d7d', }}>Linhas</h3></Divider> */}

            {pageWidth <= 800 ?
                <ItemArea style={{ marginBottom: '10%', marginTop: '5%' }}>
                    <AvatarGroup max={6}>
                        {list?.map((cat) => (
                            <Avatar key={cat?.slug} sx={{ width: 60, height: 60 }} src={cat?.image} />
                        ))}
                        {/*  <Avatar sx={{ width: 60, height: 60 }} src={sem} />
                        <Avatar sx={{ width: 60, height: 60 }} src={fertilizantes} />
                        <Avatar sx={{ width: 60, height: 60 }} src={fungicida} />
                        <Avatar sx={{ width: 60, height: 60 }} src={nutrição} />
                        <Avatar sx={{ width: 60, height: 60 }} src={pet} />
                        <Avatar sx={{ width: 60, height: 60 }} src={ger} /> */}
                    </AvatarGroup>
                </ItemArea>
                :
                <div style={{ width: '100%' }}>
                    <Carousel autoPlay={false}
                        showThumbs={false}
                        infiniteLoop={false}
                        showStatus={false}
                        renderArrowNext={(onClickHandler, hasNext, label) =>
                            hasNext && (
                                <button type="button" onClick={onClickHandler} title={"Próximo"} style={{ ...arrowStyles, right: 0 }}>
                                    <ArrowForwardIosIcon />
                                </button>
                            )
                        }
                        renderArrowPrev={(onClickHandler, hasPrev, label) =>
                            hasPrev && (
                                <button type="button" onClick={onClickHandler} title={"Anterior"} style={{ ...arrowStyles, left: 0 }}>
                                    <ArrowBackIosNewIcon />
                                </button>
                            )
                        }>
                        {pages.map((page, pageIndex) => (
                            <ItemArea key={pageIndex}>
                                {page.map((cat) => (
                                    <CircIconShow key={cat?.slug} title={cat?.title} image={cat?.image} slug={cat?.slug} />
                                ))}
                            </ItemArea>
                        ))}

                        {/*  <ItemArea>
                    {list?.slice(0, 8)?.map((cat) => (
                        <CircIconShow key={cat?.slug} title={cat?.title} image={cat?.image} slug={cat?.slug} />
                    ))}
                </ItemArea> */}

                    </Carousel>
                </div>
            }

        </Container>
    )
}

export default Categories;