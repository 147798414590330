import React from 'react'
import ClientDashboard from '../../Components/Client/ClientDashboard';
import { useInformation } from '../../../Providers/informations';

import {
    Container,
} from './styles';


const Profile = () => {
    const {info} = useInformation()
    return (
        <Container>
            {info.ClientName !== null ? <ClientDashboard /> : <div />}
          
        </Container>
    )
}

export default Profile;