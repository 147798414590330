import React from 'react';
import loginImage from '../../../../Assets/Login/login_register.png';
import { useInformation } from '../../../../Providers/informations';
import Condition from '../../../../Assets/Documents/CONDIÇÕES DE NAVEGAÇÃO.pdf';
import SnackBar from '../../../Components/General/SnackBar';
import { CustomizeInput } from '../../../../Styles/style';
import { registerFunc } from '../../../../Services/auth';
import { useNavigate } from 'react-router-dom';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {
    Container,
    useStyles,
    Middle,
    Right,
    Left,
    DivTitle,
    DivButton,
} from './styles';
import CircularProgress from '@mui/material/CircularProgress';


const CommonRegister = () => {

    const { setInfo, setEmailVeried } = useInformation();
    const navigate = useNavigate();
    const classes = useStyles();

    const [confirmPassword, setConfrimPassword] = React.useState();
    const [password, setPassword] = React.useState();
    const [email, setEmail] = React.useState();
    const [name, setName] = React.useState();

    const [errorEmailAlready, setErrorEmaeilAlready] = React.useState(false);
    const [incorrectPassword, setIncorretPassword] = React.useState(false);
    const [creating, setCreating] = React.useState(false);
    const [success, setSuccess] = React.useState(false);
    const [checked, setChecked] = React.useState(false);

    const [seePassword, setSeePassword] = React.useState(false);
    const [passType, setPassType] = React.useState('password');

    const seePass = (see, type) => {
        setSeePassword(see)
        setPassType(type);
    }


    const handleChange = (event) => {
        setChecked(event.target.checked);
    };

    const checkPassword = (checkPassword) => {
        setConfrimPassword(checkPassword);
        if (password !== checkPassword) {
            setIncorretPassword(true)
        } else {
            setIncorretPassword(false);
        }
    }

    const register = async (name, email, password, confirmPassword, checked) => {
        if (confirmPassword === password && checked === true) {
            setCreating(true);
            const userRegister = await registerFunc(name, email, password);

            if (userRegister?.status_code === 201) {
                setCreating(false)
                setSuccess(true);
                setInfo({ ClientName: userRegister.data.user.name, CientType: "customer", ClientEmail: userRegister.data.user.email, clientToken: userRegister.data.token, ClientAuth: true, ClientUuid: userRegister.data.user.uuid });
                localStorage.setItem('ClientAuth', "true");
                localStorage.setItem("clientToken", userRegister.data.token);
                setEmailVeried(false);
                return navigate('/confirmar/email');
            }
            if (userRegister?.response?.data?.message === "The email has already been taken." || "An error occurred on register") {
                setCreating(false)
                setErrorEmaeilAlready(true);

            }
        } else {

        }
    }

    return (
        <Container>
            <Left style={{ backgroundImage: `url(${loginImage})`, backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundSize: 'cover', cursor: 'pointer' }} onClick={() => navigate('/')}>

            </Left>
            <Right>
                <DivTitle onClick={() => navigate('/')}>
                    <h1>Agro Produtos</h1>
                </DivTitle>
                <Middle>
                    <div style={{ textAlign: 'Left' }}>
                        <h3 style={{ fontWeight: '500', color: '#717171' }}>Criar Conta</h3>
                    </div>
                    <CustomizeInput>
                        <label htmlFor="name">Nome</label>
                        <input type="text" id="name" onChange={(e) => setName(e.target.value)} />
                    </CustomizeInput>
                    <CustomizeInput>
                        <label htmlFor="email">Email</label>
                        <input type="email" id="email" onChange={(e) => setEmail(e.target.value)} />
                    </CustomizeInput>
                    <CustomizeInput>
                        <label htmlFor="password">Senha</label>
                        <div style={{ display: 'flex', height: '100%' }}>
                            <input type={passType} id="password" value={password} onChange={(e) => setPassword(e.target.value)} />
                            <div style={{ justifyContent: 'flex-end', display: 'flex', color: 'green', height: '15px' }}>
                                {seePassword ? <VisibilityOffIcon style={{ cursor: 'pointer' }} onClick={() => seePass(false, 'password')} /> : <VisibilityIcon style={{ cursor: 'pointer' }} onClick={() => seePass(true, 'text')} />}
                            </div>
                        </div>

                    </CustomizeInput>
                    <CustomizeInput>
                        <label htmlFor="password2">Confirmar Senha</label>
                        <input type={passType} id="password2" value={confirmPassword} onChange={(e) => checkPassword(e.target.value)} onClick={(e) => checkPassword(e.target.value)} />
                        {incorrectPassword !== false && <small className={classes.small}>As senhas não  coincidem</small>}
                    </CustomizeInput>
                    <div className="mb-3">
                        <Checkbox
                            checked={checked}
                            onChange={handleChange}
                            inputProps={{ 'aria-label': 'controlled' }}
                        />
                        <label className="form-label" style={{ color: '#717171' }}>Estou de acordo com os <Link to="" onClick={() => window.open(Condition, '_blank')} style={{ color: '#009c43' }} >termos</Link> de uso </label>
                    </div>
                    <DivButton>
                        {checked !== true || !name || !email || !password || !confirmPassword || password !== confirmPassword ? <Button variant="contained" color="success" disabled style={{ width: '100%', height: '50px' }}>
                            Criar Conta
                        </Button> : creating ? <Button variant="contained" color="success" style={{ width: '100%', height: '50px' }}>
                            <CircularProgress sx={{ color: '#ffffff' }} />
                        </Button> :
                            <Button variant="contained" color="success" onClick={() => register(name, email, password, confirmPassword, checked)} style={{ width: '100%', height: '50px' }}>
                                Criar Conta
                            </Button>}
                    </DivButton>
                    <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'center', width: '400px' }}>
                        <p style={{ paddingLeft: '5px', marginTop: '10px', color: '#717171' }} >Já tem uma conta? <Link to="/login/h4#" style={{ textDecoration: 'none', color: '#009c43' }}> Login</Link></p>
                    </div>
                    <div id="sanckbar">
                        {success && <SnackBar type="success" time={3000} message="Registrado com sucesso" states={true} />}
                        {errorEmailAlready && <SnackBar type="error" time={3000} message="Não foi possível criar sua conta com esse email" states={true} />}
                    </div>
                </Middle>
            </Right>
        </Container>
    )
}

export default CommonRegister;