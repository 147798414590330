import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Categories from '../../Components/General/Categories';
import { useInformation } from '../../../Providers/informations';
import Divider from '@mui/material/Divider';
import { Label } from '../../../Styles/style';
import LargeCard from '../../Components/General/LargeCard';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import CardCaroselProduct from '../../Components/General/CardCaroselProduct';
import { CardCaroselProductMobile } from '../../Components/General/CardCaroselProduct/indexMobile';
import SmallCardCaroselProduct from '../../Components/General/SmallCardCaroselProduct';
import { Modal } from 'react-bootstrap';
import * as GetProducts from '../../../Services/getProducts';
import isMobile from '../../../Services/isMobile';
import { InstallButton } from './installButton';
import { deferredPrompt } from '../../../installPrompt';
import MobileCardProducts from '../../Components/General/MobileCardProducts';
import MobileCardLastView from '../../Components/General/MobileCardLastView';
import Advertising from '../../Components/General/Advertising';
import { getAds } from '../../../Services/publics';
import localforage from 'localforage';
import {
    Container,
    ProductSection,
    Title,
    ButtonFl,
} from './styles';
import { Logout } from '../../../Services/logout';


const MainPage = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { cart, setViewdProducst, viewdProducst, category } = useInformation();

    const [pageWidth, setPageWidth] = React.useState(window.innerWidth);
    const [homeAreas, setHomeAreas] = React.useState([]);

    /* const [homeAdsTop, setHomeAdsTop] = React.useState([]); */
    const [homeAdsBottom, setHomeAdsBottom] = React.useState([]);

    function recarregarPagina() {
        setPageWidth(window.innerWidth);
    }
    var TimeOut;
    window.onresize = function () {
        clearTimeout(TimeOut);
        TimeOut = setTimeout(recarregarPagina, 10);
    };

    const getHomeProducts = async () => {
        try {
            const response = await GetProducts.getIndex();
            setHomeAreas(response?.data?.areas);
        } catch (error) {

        }
    }

    const getViewdProducts = async () => {
        try {
            const list = await GetProducts.getViewedProducts();
            if (list?.response?.data.message === "Unauthenticated.") {
                Logout();
            } else {
                setViewdProducst(list?.products);
            }
        } catch (error) {

        }
    }

    const setProductsFromLocal = async () => {
        let products = await localforage.getItem('homeProducts');
        !products ? getHomeProducts() : setHomeAreas(products);


        let viewed = await localforage.getItem('viewdProducts');
        setViewdProducst(viewed);
    }

    const isPWAInstalled = () => {
        if (window.matchMedia('(display-mode: standalone)').matches) {
            // A aplicação está em modo standalone (instalada como PWA)
            return true;
        } else if (window.navigator.standalone || (window.matchMedia('(display-mode: fullscreen)').matches && /iphone|ipod|ipad|android/i.test(window.navigator.userAgent))) {
            // A aplicação está em modo standalone (instalada como PWA) em alguns dispositivos iOS ou Android
            return true;
        } else {
            // A aplicação não está instalada como PWA
            return false;
        }
    };

    React.useEffect(() => {
        const handler = (e) => {
            e.preventDefault();
            if (isMobile()) {
                if (isPWAInstalled()) {
                    setShow(false)
                } else {
                    if (localStorage.getItem("notShow") === "true") {
                    } else {
                        setShow(true)
                    }
                }
            }
        };

        window.addEventListener('beforeinstallprompt', handler);

        return () => window.removeEventListener('beforeinstallprompt', handler);
    }, []);

    const getAdsList = async () => {
        const top = [];
        const bottom = [];
        try {
            const response = await getAds('home');
            response?.data?.banners?.forEach(element => {
                if (element.position === 7 || element.position === 8 || element.position === 9) {
                    bottom.push(element)
                } else {
                    top.push(element)
                }
            });
            console.log(bottom);
            setHomeAdsBottom(bottom)
            /*   setHomeAdsTop(top) */
        } catch (error) {

        }
    }

    React.useEffect(() => {
        function handleResize() {
            setPageWidth(window.innerWidth);
        }

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [])

    React.useEffect(() => {
        getHomeProducts();
        getViewdProducts()
    }, [location])

    React.useEffect(() => {
        getAdsList();
    }, [location])

    const [show, setShow] = React.useState(false);
    const handleCloseModal = () => {
        setShow(false);
    }

    const notShowAgain = () => {
        localStorage.setItem("notShow", "true");
    }
    return (
        <Container>
            <Categories list={category} />
            {localStorage.getItem("ClientAuth") === "true" &&
                <>
                    <Label>
                        {!isMobile() && <Divider textAlign="left"><Title>Continuar Vendo</Title></Divider>}
                    </Label>
                    <ProductSection>
                        {pageWidth <= 800 ? <MobileCardLastView productsList={viewdProducst} /> : <CardCaroselProduct products={viewdProducst} param={true} />}
                    </ProductSection>
                </>
            }

            <Advertising />

            {
                homeAreas?.map((areas) => (
                    pageWidth <= 800 ? <MobileCardProducts productsList={areas?.products} titleArea={areas?.title} key={areas.title} slug={areas.category_slug} /> :
                        <>
                            <ProductSection key={areas.title}>
                                <CardCaroselProduct products={areas?.products} title={areas.title} slug={areas.category_slug} />
                            </ProductSection>
                        </>
                ))
            }

            <ProductSection>
                {homeAdsBottom?.map((list) => (
                    <div key={list.title} style={{ padding: '5px' }}><LargeCard image={list?.image} title={list.title} height="150px" description={``} link={list.action} target={list.target} /></div>
                ))}
            </ProductSection>

            {cart?.length >= 1 &&
                <div style={{ position: 'fixed', bottom: '20px', right: '20px' }}>
                    <ButtonFl onClick={() => navigate('/produtos/cart/lista')}>
                        <ShoppingCartIcon />
                    </ButtonFl>
                </div>
            }
            <ProductSection >
                <CardCaroselProductMobile />
            </ProductSection>

            <Modal show={show} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Instalar aplicativo</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{}}>
                    <div className='row'>
                        <div className='col-12'>
                            <div className="mb">
                                <p>Deseja instalar o aplicativo do Agro no seu dispositivo ?</p>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer style={{}}>
                    <p onClick={notShowAgain} style={{ cursor: 'pointer' }}>Não mostrar novamente</p>
                    <InstallButton deferredPrompt={deferredPrompt} afterInstall={handleCloseModal} />
                </Modal.Footer>
            </Modal>
        </Container>
    );

};

export default MainPage;