import React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import moment from "moment";
import CardMedia from '@mui/material/CardMedia';
import OrdersProducts from '../../Components/General/OrdersProducts';
import {
    ItemDiv,
    Top,
    Bottom,
    TopL,
    TopR,
    Label,
    TextContent,
    Line,
    Lines,
    TextProduct,
    TextStore,
    ProductViewCard,
} from './styles';

const Itens = ({ list }) => {
    const options = { style: 'currency', currency: 'BRL' };
    return (
        <ItemDiv>
            <Top>
                <TopL>
                    <Lines >
                        <Box sx={{ flexGrow: 1 }}>
                            <Grid container spacing={3}>
                                <Grid item xs="4">
                                    <Line>
                                        <Label>Pedido</Label>
                                        <TextContent>{list?.order}</TextContent>
                                    </Line>
                                </Grid>
                                <Grid item xs="4">
                                    <Line>
                                        <Label>Data da Compra</Label>
                                        <TextContent>{moment(list?.created_at).format('DD MMMM YYYY')}</TextContent>
                                    </Line>
                                </Grid>
                                <Grid item xs="4">
                                    <Line>
                                        <Label>Frete</Label>
                                        <TextContent style={{ color: '#009C43' }}>Grátis</TextContent>
                                    </Line>
                                </Grid>
                            </Grid>
                        </Box>
                    </Lines>
                    <Lines>
                        <Box sx={{ flexGrow: 1 }}>
                            <Grid container spacing={3}>
                                <Grid item xs="4">
                                    <Line>
                                        <Label>Cliente</Label>
                                        <TextContent>{list?.customer}</TextContent>
                                    </Line>
                                </Grid>
                                <Grid item xs="4">
                                    <Line>
                                        <Label>Status de Pagamento</Label>
                                        {list?.status === "Pagamento Concluído" && <TextContent style={{ color: '#009c43' }}>{list?.status}</TextContent>}
                                        {list?.status === "Pagamento Vencido" && <TextContent style={{ color: '#F54B5E' }}>{list?.status}</TextContent>}
                                        {list?.status === "Pagamento Pendente" && <TextContent>{list?.status}</TextContent>}
                                    </Line>
                                </Grid>
                                <Grid item xs="4">
                                    <Line>
                                        <Label>Total</Label>
                                        <TextContent>{list?.total_price?.toLocaleString('pt-BR', options)}</TextContent>
                                    </Line>
                                </Grid>
                            </Grid>
                        </Box>
                    </Lines>
                </TopL>
                <TopR>
                    <OrdersProducts list={list} price={list?.total_price?.toLocaleString('pt-BR', options)} />
                </TopR>
            </Top>
            <Bottom>
                <Grid container spacing={5}>
                    {list?.stores?.map((store) => (
                        store?.order_products?.slice(0, 3).map((product) => (

                            <Grid item xs="auto" key={product.title}>
                                <ProductViewCard>
                                    <CardMedia
                                        component="img"
                                        height="76.995"
                                        image={`${process.env.REACT_APP_LINK}/storage/stores/${product?.images[1]}`}
                                        alt={product.title}
                                    />
                                    <div style={{ padding: '5px 10px' }}>
                                        <TextProduct>{product.title}</TextProduct>
                                        <TextStore>{store?.name}</TextStore>
                                    </div>
                                </ProductViewCard>
                            </Grid>
                        ))
                    ))}
                </Grid>
            </Bottom>
        </ItemDiv>
    )
};

export default Itens;