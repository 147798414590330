import React from 'react';
import { ButtonGreenSmall } from '../../../Styles/stylesButtons';

export const InstallButton = ({ deferredPrompt, afterInstall }) => {

    const installButton = () => {
        deferredPrompt.prompt();

        deferredPrompt.userChoice
            .then((choiceResult) => {
                if (choiceResult.outcome === 'accepted') {
                    console.log('O usuário aceitou instalar o PWA.');
                } else {
                    console.log('O usuário recusou a instalação do PWA.');
                }
                deferredPrompt = null;
                afterInstall();
            });
    }

    return (
        <ButtonGreenSmall onClick={installButton}>Instalar</ButtonGreenSmall>
    );
}

