import React from "react";
import ReactInputMask from "react-input-mask";

import {
    Container,
    DivRow
} from './style';

const InputGroup = ({ label, type, id, value, setState, setState2, group, customClass, read, onClick }) => {


    const determinarMascara = (label) => {
        if (label === 'CNPJ') {
          return '99.999.999/9999-99';
        } else if (label === 'CPF') {
          return '999.999.999-99';
        } else {
          return null;
        }
      };
    
      const mascara = determinarMascara(label?.[0]);

    return (
        <Container>
            {group ?
                <DivRow>
                    <div className="container">
                        <div className="row">
                            <div className={customClass?.[0]}>
                                <label htmlFor={id?.[0]} className="form-label">{label?.[0]}</label>
                                {label?.[0] === "CNPJ" || "CPF" ?
                                    <ReactInputMask type={type?.[0]} mask={mascara} className="form-control" id={id?.[0]} aria-describedby={label?.[0]} value={value?.[0]} onChange={(e) => setState(e.target.value)} read /> :
                                    <input type={type?.[0]} className="form-control" id={id?.[0]} aria-describedby={label?.[0]} value={value?.[0]} onChange={(e) => { /* setState2(e.target.value); */ setState(e.target.value) }} read />
                                }
                            </div>
                            <div className={customClass?.[1]}>
                                {label?.[1] === "Telefone" && id?.[1] === "phone" ? <label htmlFor={id?.[1]} className="form-label">{label?.[1]} {!value?.[1] && <small style={{ color: 'red' }}>"Pendente"</small>}</label> : <label for={id?.[1]} className="form-label">{label?.[1]}</label>}
                                {label?.[1] === "Telefone" ?
                                    <ReactInputMask type={type?.[1]} mask="(99) 99999-9999" className="form-control" id={id?.[1]} aria-describedby={label?.[1]} value={value?.[1]} onChange={(e) => setState2(e.target.value)} read /> :
                                    <input type={type?.[1]} className="form-control" id={id?.[1]} aria-describedby={label?.[1]} value={value?.[1]} onChange={(e) => setState2(e.target.value)} read />}

                            </div>
                        </div>
                    </div>
                </DivRow>
                :
                <DivRow>
                    <div className="container">
                        <div className="row">
                            <div className={customClass?.[0]}>
                                <label htmlFor={id?.[0]} className="form-label">{label?.[0]}</label>
                                <input type={type?.[0]} className={`form-control ${read}`} id={id?.[0]} aria-describedby={label?.[0]} value={value?.[0]} onClick={onClick} onChange={(e) => setState(e.target.value)} />
                            </div>
                        </div>
                    </div>
                </DivRow>
            }
        </Container>
    )
}
export default InputGroup;