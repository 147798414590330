import React from "react";
import Button from '@mui/material/Button';
import { Card, CardActions, CardContent, Divider, Typography } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { Modal } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import CardMedia from '@mui/material/CardMedia';
import Select from 'react-select'
import HomeM from '../../../Assets/Maps/home.svg';
import SearchM from '../../../Assets/Maps/search.svg';
import StoreM from '../../../Assets/Maps/store.svg';
import Grid from '@mui/material/Grid';

import {
    Container,
    Content,
    InfoTable,
    Line,
    Search,
    ButtonPage,
} from './styles';
import AdmSideBarMenu from "../../Components/SideBarMenu";
import SnackBarAdm from "../../Components/SnackBar";
import { getBanners, getBannersId, postBanner, putBanner } from "../../../Services/adm";


const Banners = () => {

    const [bannersList, setBannersList] = React.useState([]);

    const [messageError, setMessageError] = React.useState();
    const [searchBanner, setSearchBanner] = React.useState('')

    const [show, setShow] = React.useState(false);
    const [show2, setShow2] = React.useState(false);
    const [edit, setEdit] = React.useState(false);
    const [success, setSuccess] = React.useState(false);
    const [error, setError] = React.useState(false);
    const [empty, setEmpty] = React.useState(false);

    const [folder, setFolder] = React.useState('home');

    const [slug, setSlug] = React.useState();
    const [title, setTitle] = React.useState();
    const [image, setImage] = React.useState();
    const [postion, setPosition] = React.useState();
    const [status, setStatus] = React.useState();
    const [page, setPage] = React.useState();
    const [date, setDate] = React.useState();
    const [banner, setBanner] = React.useState();
    const [sendImage, setSendImage] = React.useState();
    const [link, setLink] = React.useState();
    const [target, setTraget] = React.useState();


    const loadBanner = async () => {
        try {
            const response = await getBanners();
            setBannersList(response.data.banners);
        } catch (error) {

        }
    }


    const handleClose = () => {
        cleanInput();
        setShow(false);
    }
    const handleClose2 = () => {
        cleanInput();
        setShow2(false);
    }

    const handleClickImage = () => {
        document.getElementById('fileInput').click();
    };

    const handleImageSelect = (event) => {
        const file = event.target.files[0];
        if (file) {
            setImage(URL.createObjectURL(event?.target?.files[0]));
            let files = event.target.files;
            let filesReader = new FileReader();
            filesReader.readAsDataURL(files[0]);
            filesReader.onload = (event) => {
                setSendImage(event.target.result);
            }
        }
    };

    const positionHome = [
        { value: 1, label: '1' },
        { value: 2, label: '2' },
        { value: 3, label: '3' },
        { value: 4, label: '4' },
        { value: 5, label: '5' },
        { value: 6, label: '6' },
        { value: 7, label: '7' },
        { value: 8, label: '8' },
        { value: 9, label: '9' }
    ]
    const positionSearch = [
        { value: 1, label: '1' },
        { value: 2, label: '2' },
        { value: 3, label: '3' },
        { value: 4, label: '4' },
        { value: 5, label: '5' }
    ]
    const positionStore = [
        { value: 1, label: '1' },
    ]

    const pageCheck = (location) => {
        setPage(location);

        switch (location) {
            case 'home':
                setPage(location)
                break;

            case 'search':
                setPage(location)
                break;

            case 'store':
                setPage(location)
                break;

            default:
                setPage(location)
                break;
        }
    }


    const editBanner = async () => {
        try {
            if (!postion || !title || !status || !page || !date || !target || !link) {
                setEmpty(true);
                setTimeout(() => {
                    setEmpty(false);
                }, 3000);
            } else {
                let isBanner;
                banner ? isBanner = 1 : isBanner = 0;
                const response = await putBanner(title, status, page, postion, isBanner, date, sendImage, slug, target, link);
                if (response?.message === "success") {
                    cleanInput();
                    setShow(false)
                    setSuccess(true);
                    loadBanner();
                    setTimeout(() => {
                        setSuccess(false);
                    }, 3000);
                } else {
                }
            }
        } catch (error) {
            setMessageError(error)
            setError(true);
            setTimeout(() => {
                setError(false)
            }, 3000);
        }
    }
    const createBanner = async () => {
        try {
            if (!postion || !title || !sendImage || !status || !page || !date || !target || !link) {
                setEmpty(true);
                setTimeout(() => {
                    setEmpty(false);
                }, 3000);
            } else {
                let isBanner;
                banner ? isBanner = 1 : isBanner = 0;
                const response = await postBanner(title, status, page, postion, isBanner, date, sendImage, target, link);
                if (response?.message === "success") {
                    cleanInput();
                    setShow(false)
                    setSuccess(true);
                    loadBanner();
                    setTimeout(() => {
                        setSuccess(false);
                    }, 3000);
                } else {
                }
            }
        } catch (error) {
            setMessageError(error)
            setError(true);
            setTimeout(() => {
                setError(false)
            }, 3000);
        }
    }

    const cleanInput = () => {
        setSendImage('');
        setBanner('');
        setDate('');
        setPage('');
        setStatus('');
        setPosition('');
        setImage('');
        setTitle('');
        setSlug('');
        setLink('');
        setTraget('');
        setEdit(false);
    };

    const getBannerIndex = async (slug) => {
        try {
            const response = await getBannersId(slug);
            setEdit(true);
            setSlug(slug);
            setTitle(response?.data?.banner.title);
            setImage(response?.data?.banner.image);
            setPage(response?.data?.banner.page);
            setDate(response?.data?.banner.expiration_date);
            response?.data?.banner?.page === 'home' && setPosition(positionHome.find(opt => opt?.value === response?.data?.banner.position));
            response?.data?.banner?.page === 'search' && setPosition(positionSearch.find(opt => opt?.value === response?.data?.banner.position));
            response?.data?.banner?.page === 'store' && setPosition(positionStore.find(opt => opt?.value === response?.data?.banner.position));
            setStatus(response?.data?.banner.status);
            setBanner(response?.data?.banner.is_banner === 1 ? true : false)
            setLink(response?.data?.banner.action);
            setTraget(response?.data?.banner.target);
            setShow(true);
        } catch (error) {

        }
    }

    React.useEffect(() => {
        loadBanner();
    }, [])
    return (
        <Container>
            <div style={{ padding: '10px 5px' }}>
                <AdmSideBarMenu />
            </div>
            <Content>
                <InfoTable>
                    <Line style={{ justifyContent: 'space-between', display: "flex", paddingTop: '15px', }}>
                        <div style={{ paddingLeft: '25px' }} onClick={() => console.log(bannersList)}>
                            Banners
                        </div>
                        <Search>
                            <input type="text" placeholder='Procurar' style={{ color: '#A9ABAE' }} onChange={(e) => setSearchBanner(e.target.value)} value={searchBanner} />
                        </Search>
                        <div>
                            <Button variant="contained" color="success" onClick={() => setShow(true)} >Novo Banner</Button>
                        </div>
                    </Line>
                    <Divider variant="inset" />
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <ButtonPage onClick={() => setFolder('home')} style={{ borderBottom: folder === 'home' && '2px solid green' }}>Home</ButtonPage>
                        <ButtonPage onClick={() => setFolder('store')} style={{ borderBottom: folder === 'store' && '2px solid green' }}>Lista de Lojas</ButtonPage>
                        <ButtonPage onClick={() => setFolder('search')} style={{ borderBottom: folder === 'search' && '2px solid green' }}>Busca de Produtos</ButtonPage>
                    </div>
                    <div style={{ width: '100%', padding: '15px', justifyContent: 'center', display: 'flex' }}>

                        <Grid container spacing={3} sx={{ justifyContent: 'center' }}>
                            {bannersList.filter((list) => list.page === folder)?.filter((list) => {
                                if (searchBanner === "") {
                                    return list
                                } else if (list.title.toLowerCase().includes(searchBanner.toLocaleLowerCase()) || list.page.toLowerCase().includes(searchBanner.toLocaleLowerCase())) {
                                    return list
                                } else {
                                    return null
                                }
                            })?.map((line) =>
                                <Grid item xs="auto" key={line?.slug}>
                                    <Card sx={{ cursor: 'pointer', width: 300 }}>
                                        <CardMedia
                                            component="img"
                                            alt={line.title}
                                            height="100"
                                            image={line.image}
                                        />
                                        <CardContent>
                                            <Typography gutterBottom variant="h5" component="div" style={{ textTransform: 'capitalize' }}>
                                                {line?.page}
                                            </Typography>
                                            <Typography variant="body2" color="text.secondary">
                                                {line.title}
                                            </Typography>
                                        </CardContent>
                                        <CardActions>
                                            <Button size="small" onClick={() => getBannerIndex(line.slug)}>Editar <EditIcon color="success" /></Button>
                                            <Button size="small"  /* onClick={() => confirmDelete(line.title, line.slug)} */>Deletar <DeleteIcon color="error" /></Button>
                                        </CardActions>
                                    </Card>
                                </Grid>
                            )}
                        </Grid>
                    </div>
                </InfoTable>
            </Content>

            <Modal show={show} onHide={handleClose} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>{edit ? "Editar Banner" : "Criar Novo Banner"}</Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    <div style={{ overflowY: 'auto', height: '70vh', padding: '0 15px' }}>
                        <Row>
                            <Col xs lg="12" style={{}}>
                                <Form.Group className="mb-3" controlId="image" style={{ margin: 'auto', width: '100%', border: 'solid 1px #cacaca', borderRadius: '16px', }}>
                                    <CardMedia
                                        component="img"
                                        sx={{ borderRadius: '16px', width: '100%', cursor: 'pointer' }}
                                        height="194"
                                        alt={title}
                                        src={image || "/static/images/avatar/1.jpg"}
                                        onClick={handleClickImage}
                                    />
                                    <input
                                        type="file"
                                        id="fileInput"
                                        style={{ display: 'none' }}
                                        accept="image/*"
                                        onChange={handleImageSelect}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs lg="6">
                                <Form.Group className="mb-3" controlId="title">
                                    <Form.Label>Titulo</Form.Label>
                                    <Form.Control type="text" placeholder="Titulo do Banner" onChange={(e) => setTitle(e.target.value)} value={title} required />
                                </Form.Group>
                            </Col>
                            <Col xs lg="">
                                <Form.Group className="mb-3" controlId="description">
                                    <Form.Label>Data de Expiração</Form.Label>
                                    <Form.Control type="date" placeholder="Expiração" onChange={(e) => setDate(e.target.value)} value={date} required />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs lg="6">
                                <Form.Group className="mb-3" controlId="description">
                                    <Form.Label>Página</Form.Label>
                                    <Form.Select placeholder="Status" onChange={(e) => pageCheck(e.target.value)} value={page} required >
                                        <option>Selecionar</option>
                                        <option value={'home'}>Home</option>
                                        <option value={'search'}>Busca</option>
                                        <option value={'store'}>Lista de Lojas</option>
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            <Col xs lg="3">
                                <Form.Group className="mb-3" controlId="tax">
                                    <Form.Label>Posição</Form.Label>
                                    {!page && <Select />}
                                    {page === "home" && <Select options={positionHome} onChange={(opt) => setPosition(opt)} value={postion} />}
                                    {page === "search" && <Select options={positionSearch} onChange={(opt) => setPosition(opt)} value={postion} />}
                                    {page === "store" && <Select options={positionStore} onChange={(opt) => setPosition(opt)} value={postion} />}
                                </Form.Group>
                            </Col>
                            <Col xs lg="3">
                                <Form.Group className="mb-3" controlId="tax">
                                    <Form.Label>Status</Form.Label>
                                    <Form.Select placeholder="Status" onChange={(e) => setStatus(e.target.value)} value={status} required >
                                        <option>Selecionar</option>
                                        <option value={1}>Ativo</option>
                                        <option value={0}>Desativo</option>
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs lg="9">
                                <Form.Group className="mb-3" controlId="url">
                                    <Form.Label>Link de Redirecionamento</Form.Label>
                                    <Form.Control type="text" placeholder="URL" onChange={(e) => setLink(e.target.value)} value={link} required />
                                </Form.Group>
                            </Col>
                            <Col xs lg="3">
                                <Form.Group className="mb-3" controlId="tax">
                                    <Form.Label>Abrir em</Form.Label>
                                    <Form.Select onChange={(e) => setTraget(e.target.value)} value={target} required >
                                        <option>Selecionar</option>
                                        <option value='_blank'>Nova Guia</option>
                                        <option value='_self'>Guia Atual</option>
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Form.Check
                            type="checkbox"
                            label="Banner"
                            id="isBanner"
                            checked={banner}
                            onChange={(e) => setBanner(e.target.checked)}
                        />
                        <div style={{ paddingTop: '25px' }}>
                            Mapa de posições
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                {page === "home" && <CardMedia
                                    component="img"
                                    sx={{ borderRadius: '16px', width: '50%', border: 'solid 1px #c0bfbc' }}
                                    height="100%"
                                    alt={page}
                                    src={HomeM}
                                />}
                                {page === "search" && <CardMedia
                                    component="img"
                                    sx={{ borderRadius: '16px', width: '50%', border: 'solid 1px #c0bfbc' }}
                                    height="100%"
                                    alt={page}
                                    src={SearchM}
                                />}
                                {page === "store" && <CardMedia
                                    component="img"
                                    sx={{ borderRadius: '16px', width: '50%', border: 'solid 1px #c0bfbc' }}
                                    height="100%"
                                    alt={page}
                                    src={StoreM}
                                />}
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    {edit ?
                        <Button variant="contained" color="primary" onClick={editBanner}>Salvar</Button> :
                        <Button variant="contained" color="primary" onClick={createBanner}>Salvar</Button>
                    }

                </Modal.Footer>

            </Modal>

            <Modal show={show2} onHide={handleClose2}>
                <Modal.Header closeButton>
                    <Modal.Title>Excluir Categoria</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Deseja excluir esse banner "{title}"
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose2}>
                        Close
                    </Button>
                    <Button variant="contained" color="error" /* onClick={() => deleteLine(slug)} */>Deletar</Button>
                </Modal.Footer>
            </Modal>
            <div id="sanckbar">
                {error && <SnackBarAdm message={`Ocorreu um erro ao executar essa operação ${messageError}`} type="error" time={3000} states={true} />}
                {success && <SnackBarAdm message={`Operação realizada com sucesso`} type="success" time={3000} states={true} />}
                {empty && <SnackBarAdm message={`Preencher todos os campos`} type="warning" time={3000} states={true} />}
            </div>
        </Container>
    )
}

export default Banners;