import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    background: #009c43 ;
`;

export const Board = styled.div`
    flex: 1;
    height: 100vh;
    display: flex;
    flex-direction: row;
    box-shadow: 0px 1px 2px #00000029;
    background: #fff ;
    border-radius: 6px;

    @media(max-height: 1080px){
        
    }
    
`;
export const Board0 = styled.div`
    width: 40rem;
    height: 100vh;
    display: flex;
    flex-direction: row;
    box-shadow: 0px 1px 2px #00000029;
    background: #009c43 ;
    border-radius: 6px;

    @media(min-width: 1920px){
        width: 60rem;
    }
    @media(max-width: 1200px){
        width: 20rem;
    }
    @media(max-width: 750px){
       width: 0px;
       visibility: hidden;
    }
    
`;
export const Column = styled.div`
    width: 100%;
    height: 100%;
    border: 1px solid #E1E5EB;
    padding: 10% 20%;
    border-radius: 6px;
`;
export const Bottom = styled.div`
    text-align: center; 
    padding-top: 15px;
    display: flex;
    align-items: center; 
    align-content: center; 
    justify-content: center;

    >p {
        padding: 5px;
        font-size: 1rem;
        :hover{
            cursor: pointer;
            font-size: 1.1rem;

        }
    }
`;

export const Middle = styled.div`
    align-items: center;
    align-content:center;
    vertical-align: middle;
    justify-content: center;
`;