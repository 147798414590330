import axios from "axios";


export async function getAddressWithZipCode(zip) {
    try {
        var { data: res } = await axios({ method: 'get', url: `https://viacep.com.br/ws/${zip}/json/`, });
        return res
    } catch (error) {
        return error
    }
}

export async function getDistance(zip1, zip2) {
    try {

        /* 
                var origin1 = new google.maps.LatLng(55.930385, -3.118425);
                var origin2 = 'Greenwich, England';
                var destinationA = 'Stockholm, Sweden';
                var destinationB = new google.maps.LatLng(50.087692, 14.421150);
        
                var service = new google.maps.DistanceMatrixService();
                service.getDistanceMatrix(
                    {
                        origins: [origin1, origin2],
                        destinations: [destinationA, destinationB],
                        travelMode: 'DRIVING',
                        transitOptions: TransitOptions,
                        drivingOptions: DrivingOptions,
                        unitSystem: unitSystem,
                        avoidHighways: Boolean,
                        avoidTolls: Boolean,
                    }, callback);
        
                function callback(response, status) {
                } */

    } catch (error) {

    }
}

export async function shippingAvailable(storeSlug, ibgeNumber) {
    try {
        const { data: res } = await axios({ method: 'get', url: `${process.env.REACT_APP_LINK}/api/public/stores/shipment/verify/${storeSlug}/${ibgeNumber}` });
        return res
    } catch (error) {
        return error
    }
}

export async function shipmentAvailableList(rote) {
    const { data: res } = await axios({ method: 'get', url: `${process.env.REACT_APP_LINK}/${rote}` });
    return res
}

export async function postAddress(title, street, number, complement, district, zip_code, city, state, country, description, ibge_code) {
    var data = {
        "title": title,
        "street": street,
        "number": number,
        "complement": complement,
        "district": district,
        "zip_code": zip_code,
        "city": city,
        "state": state,
        "country": country,
        "description": description,
        "ibge_code": ibge_code,
    }
    const { data: res } = await axios({ method: 'post', url: `${process.env.REACT_APP_LINK}/api/user/addresses`, headers: { 'Authorization': `Bearer ${localStorage.getItem('clientToken')}` }, data })
    return res
}

export async function putAddress(title, street, number, complement, district, zip_code, city, state, country, description, ibge_code, id) {
    var data = {
        "title": title,
        "street": street,
        "number": number,
        "complement": complement,
        "district": district,
        "zip_code": zip_code,
        "city": city,
        "state": state,
        "country": country,
        "description": description,
        "ibge_code": ibge_code,
    }
    const { data: res } = await axios({ method: 'PUT', url: `${process.env.REACT_APP_LINK}/api/user/addresses/${id}`, headers: { 'Authorization': `Bearer ${localStorage.getItem('clientToken')}` }, data })
    return res
}

export async function deleteAddress(id) {
    const { data: res } = await axios({ method: 'DELETE', url: `${process.env.REACT_APP_LINK}/api/user/addresses/${id}`, headers: { 'Authorization': `Bearer ${localStorage.getItem('clientToken')}` } })
    return res
}