import React from 'react';

import {
    Container,
} from './styles';


const PaymentCompleted = () => {

    const CloseWindow = () =>{
        setTimeout(() => {
            window.close();
        }, 3000);
    }
    React.useEffect(() => {
        CloseWindow()
    }, [])

    return (
        <Container>
            <h3>Pagamento concluido</h3>
        </Container>
    )
}

export default PaymentCompleted;