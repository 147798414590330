import React from "react";
import { getAddressWithZipCode, shipmentAvailableList } from "../../../Services/address";
import { ButtonGreen, ButtonSilver } from '../../../Styles/stylesButtons';
import Spinner from '../../Components/General/SmallComponents/spinner';
import PaymentOption from "../../Components/General/PaymentOptions";
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import { useInformation } from "../../../Providers/informations";
import * as GetProducts from '../../../Services/getProducts';
import NewAddress from "../../Components/General/AddressNew";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { TitleH3 } from "../../../Styles/style";
import Avatar from '@mui/material/Avatar';
import { Modal } from "react-bootstrap";
import Grid from '@mui/material/Grid';
import axios from "axios";
import {
    Container,
    DivListProduct,
    TotalPriceItem,
    SmallSubtitle,
    ValueResume,
    LabelResume,
    LoadingDiv,
    DivResume,
    DivList,
    H3Title,
    Medium,
    Small,
    Input,
    Line,
    Big,
    Mobile,
    Web,
} from './styles';

const OrderReview = () => {

    const { info } = useInformation();
    const location = useLocation();
    const navigate = useNavigate();

    const [productsList, setProductsList] = React.useState([]);
    const [listAdress, setListAdress] = React.useState([]);
    const [shipping, setShipping] = React.useState([]);

    const [selectedValue, setSelectedValue] = React.useState();
    const [totalPrice, setTotalPrice] = React.useState();
    const [addressId, setAddressId] = React.useState();
    const [loading, setLoading] = React.useState();

    const [showAddressList, setShowAddressList] = React.useState(false);
    const [shippingStatus, setShippingStatus] = React.useState(false);
    const [showShipp, setShowShipp] = React.useState(false);
    const [payments, setPayments] = React.useState([])
    const [paymentMethod, setPaymentMethod] = React.useState();

    let { param } = useParams();


    const options = { style: 'currency', currency: 'BRL' };

    var price = 0;

    const getProductsOder = async () => {
        const products = await GetProducts.getProductsOrder();
        setProductsList(products.data.products);
        setPayments(products.data.payment);

        products.data.products.map((product) => price = price + product.total_price);
        setTotalPrice(price)
    }

    const getListAdress = async () => {
        try {
            const { data: res } = await axios({ method: 'get', url: `${process.env.REACT_APP_LINK}/api/user/addresses`, headers: { 'Authorization': `Bearer ${localStorage.getItem('clientToken')}` } })
            setListAdress(res?.data?.address);
            /* checkShippment(res?.data.address[0].zip_code); */
        } catch (error) {

        }
    }

    const changeAddress = (id, zip_code, address) => {
        try {
            setAddressId(id);
            checkShippment(zip_code);
            setSelectedValue(address);
            handleCloseAddressList();
        } catch (error) {

        }
    }

    const handleCloseShipp = () => {
        setShowShipp(!showShipp)
    }

    const handleCloseAddressList = () => {
        setShowAddressList(!showAddressList);
    }

    const [shipmentStatus, setShipmentStatus] = React.useState(false);

    const checkShippment = async (zip) => {
        setLoading(true);
        handleCloseShipp();
        const addressResponse = await getAddressWithZipCode(zip);
        setShipping(addressResponse);
        if (addressResponse?.erro === true) {
            setLoading(false);
        } else {
            try {
                const shipmentUrl = `api/public/stores/shipment/verify-locale/${addressResponse.ibge}/${productsList.map(item => item.store_slug).join('|')}`;
                const response = await shipmentAvailableList(shipmentUrl);
                if (response?.data?.some(ship => ship.shipment_available === true)) {
                    if (response?.data?.some(ship => ship.shipment_available === false)) {
                        setShipmentStatus(true)
                    }
                    setShippingStatus(true);
                    const idsToRemove = productsList
                        .filter(item => !response.data.some(other => item.store_slug === other.store_slug && other.shipment_available === true))
                        .map(item => item.id);

                    const filteredArr1 = productsList.filter(item => !idsToRemove.includes(item.id));
                    setProductsList(filteredArr1);
                } else {
                    setShippingStatus(false);
                }
            } catch (error) {
                setShippingStatus(false);
            }
            setLoading(false);
        }
    }

    React.useEffect(() => {
        getListAdress();
        getProductsOder();
    }, [location])

    return (
        <Container>
            <Line>
                <Medium>
                    <Line style={{ justifyContent: 'space-between', display: "flex", paddingTop: '15px', }}>
                        <div>
                            <TitleH3 style={{ fontSize: '1.2rem' }}>Endereço de entrega</TitleH3>
                        </div>
                    </Line>
                    <hr></hr>
                    {listAdress.length === 0 ?
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <NewAddress redirect={"/produtos/compra/finalizar/verify"} />
                        </div>
                        :
                        <DivList>
                            {!selectedValue ?
                                <div style={{ display: 'flex', justifyContent: 'center', width: "100%" }}><ButtonGreen style={{ borderRadius: '20px', fontSize: '0.7rem', fontWeight: 'bold', width: '40%', height: '30px' }} onClick={handleCloseAddressList}>
                                    Selecionar endereço
                                </ButtonGreen>
                                </div> : <>
                                    <div style={{ flex: '1', display: 'flex', flexDirection: 'column', padding: '0 15px' }}>
                                        <H3Title>{selectedValue?.title}</H3Title>
                                        <SmallSubtitle>{selectedValue?.zip_code}</SmallSubtitle>
                                        <SmallSubtitle>{selectedValue?.street}, {selectedValue?.number} - {selectedValue?.district}, {selectedValue?.city} - {selectedValue?.state} - {selectedValue?.country}</SmallSubtitle>
                                    </div>
                                    <div><ButtonGreen style={{ borderRadius: '20px', fontSize: '0.7rem', fontWeight: 'bold', width: '130px', height: '30px' }} onClick={handleCloseAddressList}>
                                        Trocar endereço
                                    </ButtonGreen>
                                    </div>
                                </>}

                        </DivList>
                    }
                </Medium>
                <Small>
                    <Line style={{ justifyContent: 'space-between', display: "flex", paddingTop: '10px', }}>
                        <div>
                            <TitleH3 style={{ fontSize: '1.2rem' }}> Resumo do pedido</TitleH3>
                        </div>
                    </Line>
                    <DivList style={{ flexDirection: 'column' }}>
                        <DivResume>
                            <LabelResume>
                                Subtotal
                            </LabelResume>
                            <ValueResume>
                                {paymentMethod === 'pix' && (payments?.pix / 100)?.toLocaleString('pt-BR', options)}
                                {paymentMethod === 'boleto' && (payments?.boleto / 100)?.toLocaleString('pt-BR', options)}
                                {paymentMethod === 'card' && totalPrice?.toLocaleString('pt-BR', options)}
                                {!paymentMethod && totalPrice?.toLocaleString('pt-BR', options)}
                            </ValueResume>
                        </DivResume>
                        <DivResume>
                            <LabelResume>
                                Frete
                            </LabelResume>
                            {shippingStatus ?
                                <ValueResume style={{ color: 'green' }}>
                                    grátis
                                </ValueResume>
                                :
                                <ValueResume style={{ color: '#000' }}>
                                    -
                                </ValueResume>}
                        </DivResume>
                        <hr></hr>
                        <DivResume>
                            <LabelResume>
                                Total
                            </LabelResume>
                            <ValueResume>
                                {totalPrice?.toLocaleString('pt-BR', options)}
                            </ValueResume>
                        </DivResume>
                    </DivList>
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center', paddingTop: '10px' }}>
                        {!addressId ? <ButtonSilver style={{ borderRadius: '20px', width: '60%' }} onClick={handleCloseAddressList} >
                            Selecionar endereço
                        </ButtonSilver> : shippingStatus ? !info.ClientDocumentNumber ?
                            <ButtonGreen style={{ borderRadius: '20px', width: '60%' }} onClick={() => navigate("/usuario/perfil?redirect=finalizar_compra")}>
                                Completar cadastro
                            </ButtonGreen>
                            :
                            <div style={{ width: '100%', justifyContent: 'center', }}>
                                {process.env.REACT_APP_ENVARIOMENT === "PRODUCTION" ? <PaymentOption value={totalPrice} productsList={productsList} addressId={addressId} payment={payments} funPaymentMethod={setPaymentMethod} /> :

                                    <>
                                        {param === "goodToGo" && <PaymentOption value={totalPrice} productsList={productsList} addressId={addressId} payment={payments} funPaymentMethod={setPaymentMethod} />}
                                    </>}

                            </div> :
                            <ButtonSilver style={{ borderRadius: '20px', width: '60%' }}>
                                Entrega não disponível
                            </ButtonSilver>}
                    </div>
                </Small>
            </Line>
            <Line>
                <Big>
                    <Line style={{ justifyContent: 'space-between', display: "flex", paddingTop: '15px', }}>
                        <div>
                            <TitleH3>Produtos adicionados</TitleH3>
                        </div>
                    </Line>

                    <Web>
                        <Grid container spacing={2} sx={{ padding: '15px 20px', overflow: 'auto', height: '80%', marginBottom: '10px' }} >
                            {productsList?.map((products) => (
                                <Grid xs={6} sx={{ padding: '0 5px' }} key={products?.id}>
                                    <DivListProduct>
                                        <div style={{ padding: '5px 10px 5px 10px' }}><Avatar sx={{ width: 70, height: 70 }} src={`${process.env.REACT_APP_LINK}/${products?.images?.[0]}`} variant="rounded" /></div>
                                        <div style={{ flex: '1', display: 'flex', flexDirection: 'column', paddingLeft: '25px' }}>
                                            <H3Title>{products?.title}</H3Title>
                                            <div style={{ display: 'flex', justifyContent: 'space-between', padding: '8px' }}>
                                                <div style={{ width: '50%' }}>
                                                    <SmallSubtitle>{products?.unit_price?.toLocaleString('pt-BR', options)} / unidade</SmallSubtitle>
                                                </div>
                                                <div style={{ textAlign: 'center', width: '30%' }}>
                                                    <input disabled type="number" autoComplete="off" value={products?.quantity} id="qtd" style={Input} maxLength="7" />
                                                </div>
                                                <div style={{ flex: '1', display: 'flex' }}>
                                                    <TotalPriceItem>{products?.total_price?.toLocaleString('pt-BR', options)}</TotalPriceItem>
                                                </div>
                                            </div>

                                        </div>
                                    </DivListProduct>
                                </Grid>
                            ))}
                        </Grid>
                    </Web>
                    <Mobile>
                        <Grid container spacing={2} sx={{ padding: '15px 20px', overflow: 'auto', height: '80%', marginBottom: '10px' }} >
                            {productsList?.map((products) => (
                                <Grid xs={12} sx={{ padding: '0 5px' }} key={products?.id}>
                                    <DivListProduct>
                                        <div style={{ padding: '5px 10px 5px 10px' }}><Avatar sx={{ width: 40, height: 40 }} src={`${process.env.REACT_APP_LINK}/${products?.images?.[0]}`} variant="rounded" /></div>
                                        <div style={{ flex: '1', display: 'flex', flexDirection: 'column', paddingLeft: '25px' }}>
                                            <H3Title>{products?.title}</H3Title>
                                            <div style={{ justifyContent: 'space-between', padding: '8px' }}>
                                                <div style={{ width: '50%' }}>
                                                    <SmallSubtitle>R$: {products?.unit_price?.toLocaleString('pt-BR', options)} / unidade</SmallSubtitle>
                                                </div>
                                                <div style={{ textAlign: 'center', width: '30%' }}>
                                                    <input disabled type="number" autoComplete="off" value={products?.quantity} id="qtd" style={Input} maxLength="7" />
                                                </div>
                                                <div style={{ flex: '1', display: 'flex' }}>
                                                    <TotalPriceItem>R$:{products?.total_price?.toLocaleString('pt-BR', options)}</TotalPriceItem>
                                                </div>
                                            </div>

                                        </div>
                                    </DivListProduct>
                                </Grid>
                            ))}
                        </Grid>
                    </Mobile>
                </Big>
            </Line>

            <Modal show={showShipp} onHide={handleCloseShipp} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Disponibilidade de entrega</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {loading ? <LoadingDiv>
                        <Spinner />

                    </LoadingDiv> : <LoadingDiv>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th scope="col">CEP</th>
                                    <th scope="col">Cidade</th>
                                    <th scope="col">Estado</th>
                                    <th scope="col">Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{shipping?.cep} </td>
                                    <td>{shipping?.localidade}</td>
                                    <td>{shipping?.uf}</td>
                                    <th scope="row">{shippingStatus ? "Frete Grátis" : "Ainda não chegamos na sua região"}</th>
                                </tr>
                            </tbody>
                        </table>
                        {shippingStatus ? <LocalShippingIcon fontSize='large' color="success" /> : <TravelExploreIcon fontSize='large' color="success" />}
                        {shipmentStatus && <small>Alguns itens não possuem frete para sua cidade, esses foram removidos do carrinho</small>}
                    </LoadingDiv>}
                </Modal.Body>
                <Modal.Footer>
                    {!loading && <ButtonGreen style={{ borderRadius: '20px' }} onClick={handleCloseShipp}>Continuar</ButtonGreen>}
                </Modal.Footer>
            </Modal>

            <Modal show={showAddressList} onHide={handleCloseAddressList} size="md">
                <Modal.Header closeButton>
                    <Modal.Title>Lista de Endereços</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {loading ? <LoadingDiv>
                        <Spinner />

                    </LoadingDiv> : <LoadingDiv>
                        {listAdress?.map((address) => (
                            <DivList id={address.id} key={address?.id}>
                                <div style={{ flex: '1', display: 'flex', flexDirection: 'column', padding: '0 15px' }}>
                                    <H3Title>{address?.title}</H3Title>
                                    <SmallSubtitle>{address?.zip_code}</SmallSubtitle>
                                    <SmallSubtitle>{address?.street}, {address?.number} - {address?.district}, {address?.city} - {address?.state} - {address?.country}</SmallSubtitle>
                                </div>
                                <div><ButtonGreen onClick={() => changeAddress(address.id, address?.zip_code, address)} style={{ borderRadius: '20px', fontSize: '0.7rem', fontWeight: 'bold', width: '100px', height: '30px' }}>
                                    Selecionar
                                </ButtonGreen>
                                </div>
                            </DivList>
                        ))}
                    </LoadingDiv>}
                </Modal.Body>
                <Modal.Footer></Modal.Footer>
            </Modal>
        </Container>
    )
}

export default OrderReview;