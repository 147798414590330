import React from "react";
import { TitleH3, TitleH6 } from "../../../../Styles/style";
import { Legend, Tooltip, ResponsiveContainer } from 'recharts';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid } from 'recharts';
import { LineChart, Line } from 'recharts';
import StoreIcon from '@mui/icons-material/Store';
import ReceiptIcon from '@mui/icons-material/Receipt';
import AssessmentIcon from '@mui/icons-material/Assessment';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { ButtonGreen } from "../../../../Styles/stylesButtons";
import IconBreadcrumbs from "../../../Components/General/Breadcrumbs";
import dayjs from "dayjs";
import 'dayjs/locale/pt-br';
import './styles.css';
import {
    Container, ContentCard, TopTitleDiv, Card
} from './styles';
import { getReportsMonth } from "../../../../Services/store";
import Statistcs from "../../../Components/General/Statistcs";
import ExportReport from "./exportReport";

dayjs.locale('pt-br');

const SalesReport = () => {

    const today = dayjs();

    const [data, setData] = React.useState([]);
    const [salesTotal, setSalesTotal] = React.useState([]);


    const [month, setMonth] = React.useState(today.format('MM'));
    const [year, setYear] = React.useState(today.format('YYYY'));

    const where = [
        { where: 'Minha Loja', link: '/loja/perfil', icon: <StoreIcon sx={{ mr: 0.5 }} fontSize="inherit" /> },
        { where: 'Vendas', link: '/loja/vendas', icon: <ReceiptIcon sx={{ mr: 0.5 }} fontSize="inherit" /> },
        { where: 'Relatórios', link: '/loja/vendas/relatorios', color: '#009C43', icon: <AssessmentIcon sx={{ mr: 0.5 }} fontSize="inherit" /> },
    ]

    const getReportsByMonth = async (url) => {
        try {
            const response = await getReportsMonth(url);
            setData(response.data)
        } catch (error) {

        }
    }

    const getReportsByStatus = async () => {
        try {
            const response = await getReportsMonth('sales-by-status');
            setSalesTotal(response)
        } catch (error) {

        }
    }

    const handleDateChange = (newDate) => {
        const formattedDate = dayjs(newDate);
        setMonth(formattedDate.format('MM'));
        setYear(formattedDate.format('YYYY'));
        getReportsByMonth(`sales-by-period/${formattedDate.format('MM')}/${formattedDate.format('YYYY')}`);
    };

    React.useEffect(() => {
        getReportsByMonth(`sales-by-period/${today.format('MM')}/${today.format('YYYY')}`);
        getReportsByStatus();
    }, [])


    return (
        <Container>
            <IconBreadcrumbs list={where} />

            <ContentCard>
                <TopTitleDiv>
                    <div style={{ height: '50%', display: 'flex', justifyContent: 'space-between', padding: '15px', }}>
                        <TitleH3> Relatório de vendas </TitleH3>
                    </div>
                </TopTitleDiv>
                <div style={{ padding: '15px', display: 'flex' }}>
                    <Card style={{ width: '100%' }}>
                        <Statistcs sales={salesTotal} />
                        {/*  <div style={{ width: '100%', height: '280px' }}>
                            <ResponsiveContainer width="100%" height="100%">
                                <PieChart width={400} height={400}>
                                    <Pie
                                        dataKey="value"
                                        isAnimationActive={false}
                                        data={data01}
                                        cx="50%"
                                        cy="50%"
                                        outerRadius={80}
                                        fill={data01.fill}
                                        label
                                    />
                                    <Tooltip />
                                    <Legend />
                                </PieChart>
                            </ResponsiveContainer>
                        </div> */}
                    </Card>
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between', padding: '15px' }}>
                    <div>
                        <TitleH6>{month || year ? `Período de ${month}/${year}` : ' Últimos 30 dias'} </TitleH6>
                    </div>
                    <div style={{ width: '30%' }}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer components={['DatePicker', 'DatePicker', 'DatePicker']} >
                                <DatePicker label={'Mês e Ano'} views={['month', 'year']} onChange={handleDateChange} />
                            </DemoContainer>
                        </LocalizationProvider>
                    </div>
                </div>
                <div style={{ padding: '15px', display: 'flex' }}>
                    <Card>
                        <div>
                            <TitleH6>Total de Vendas</TitleH6>
                        </div>
                        <div style={{ width: '100%', height: '280px' }}>
                            <ResponsiveContainer width="100%" height="100%">
                                <BarChart
                                    width={500}
                                    height={300}
                                    data={data}
                                    margin={{
                                        top: 5,
                                        right: 30,
                                        left: 20,
                                        bottom: 5,
                                    }}
                                    barSize={30}
                                >
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="date" />
                                    <YAxis />
                                    <Tooltip />
                                    <Legend />
                                    <Bar dataKey="vendas" fill="#009C43" />
                                </BarChart>
                            </ResponsiveContainer>
                        </div>
                    </Card>
                </div>



                <div style={{ padding: '15px', display: 'flex' }}>
                    <Card style={{ width: '100%' }}>
                        <div>
                            <TitleH6>Valor Total</TitleH6>
                        </div>
                        <div style={{ width: '100%', height: '280px' }}>
                            <ResponsiveContainer width="100%" height="100%">
                                <LineChart
                                    width={500}
                                    height={300}
                                    data={data}
                                    margin={{
                                        top: 5,
                                        right: 30,
                                        left: 20,
                                        bottom: 5,
                                    }}
                                >
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="date" />
                                    <YAxis />
                                    <Tooltip />
                                    <Legend />
                                    <Line type="monotone" dataKey="valor" stroke="#009C43" activeDot={{ r: 8 }} />
                                </LineChart>
                            </ResponsiveContainer>
                        </div>
                    </Card>
                </div>
                <div style={{ display: 'flex', justifyContent: 'flex-end', padding: '15px' }}>
                    <ExportReport detailSales={data} m={month} y={year} />
                </div>
            </ContentCard>
        </Container>
    )
}

export default SalesReport;