import React from "react";
import { useLocation } from "react-router-dom";
import * as Orders from '../../../Services/orders';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { TitleH3 } from '../../../Styles/style';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import axios from "axios";

import {
    Container,
    ContentCard,
    ListContentOrder,
    TopTitleDiv,
    DivMaster,
} from './styles';
import Itens from "./itens";


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 2 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}



const MyOrders = () => {

    const location = useLocation();

    const theme = useTheme();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleChangeIndex = (index) => {
        setValue(index);
    };

    const [page, setPage] = React.useState(1);
    const [orderList, setOrderList] = React.useState([]);
    const [totalOfPages, setTotalOfPages] = React.useState();
    const [nexProducts, setNextProducts] = React.useState();



    const getMyOrders = async () => {
        const listOrders = await Orders.getOrders();
        if (listOrders?.response?.data.message) {
            console.log("error")
        } else {
            setOrderList(listOrders?.data.orders.data);
            setNextProducts(listOrders.data.orders.links);
            setTotalOfPages(listOrders.data.orders.last_page);
        }
    }

    React.useEffect(() => {
        getMyOrders();
        setPage(1);
    }, [location])


    const nextPage = async (value1) => {
        const { data: res } = await axios({ method: 'get', url: `${process.env.REACT_APP_LINK}/api/user/orders${nexProducts?.[value1]?.url.split("/")[1]}`, headers: { 'Authorization': `Bearer ${localStorage.getItem("clientToken")}` } });
        setOrderList(Object.values(res.data.orders.data));
    }

    const handleChangePage = (event, value1) => {
        setPage(value1);
        nextPage(value1);
    };
    return (
        <Container>
            <ContentCard>
                <TopTitleDiv>
                    <div style={{ height: '50%', display: 'flex', justifyContent: 'space-between', padding: '15px', }}>
                        <TitleH3> Meus Pedidos </TitleH3>
                        {/*  <DivFilter style={{ display: 'flex' }}>
                            <p style={{ display: 'flex', alignItems: 'center', padding: '0 10px', height: '100%' }}>Pedidos feitos em</p>  <Select options={options} />
                        </DivFilter> */}
                    </div>
                    <AppBar position="static" sx={{ background: '#ffffff', color: 'black', border: 'none', boxShadow: '0', flex: '1' }}>
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            indicatorColor="success"
                            textColor="inherit"
                            variant="fullWidth"
                            aria-label="full width tabs example"
                        >
                            <Tab label="Todos" {...a11yProps(0)} />
                            {/* <Tab label="Pedidos Cancelados" {...a11yProps(1)} /> */}
                        </Tabs>
                    </AppBar>
                </TopTitleDiv>
                <ListContentOrder>
                    <SwipeableViews
                        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                        index={value}
                        onChangeIndex={handleChangeIndex}
                    >
                        <TabPanel value={value} index={0} dir={theme.direction}>

                            <Grid container spacing={2}>
                                {orderList?.map((list) => (
                                    <DivMaster key={list?.order}>
                                        <Grid item xs={12} >
                                            <Itens list={list} />
                                        </Grid>
                                    </DivMaster>
                                ))}

                            </Grid>
                            <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginBottom: '25px' }}>
                                <Stack spacing={2}>
                                    <Pagination count={totalOfPages} page={page} onChange={handleChangePage} />
                                </Stack>
                            </div>
                        </TabPanel>
                        <TabPanel value={value} index={1} dir={theme.direction}>
                            Item Two
                        </TabPanel>
                    </SwipeableViews>
                </ListContentOrder>
            </ContentCard>
        </Container>
    )
}

export default MyOrders;