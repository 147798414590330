import styled from "styled-components";

export const Container = styled.div`
    padding: 2%;
`;
export const Line = styled.div`
    width: 100%;
    display: flex;
   
    padding-bottom: 15px ;
    @media(max-width: 900px) {
        flex-direction: column;
    }
`;

export const Salles = styled.div`
    height: calc(100vh - 21vh);
    background-color: #fff ;
    box-shadow: 0px 0px 9px #26242424;
    border-radius: 6px;
    margin-left: 15px;
    border: 1px solid #c6c6c6 ;
    overflow: auto ;

    @media(max-width: 900px){
        margin-left: 0;
    }
`;
export const InfoTable = styled.div`
    flex:1;
    height: 100vh;
    background-color: #fff ;
    box-shadow: 0px 0px 9px #26242424;
    border-radius: 6px;
    border: 1px solid #c6c6c6 ;
    overflow: auto;

    @media(max-height: 768px){
        height: 100vh;
    }
`;

export const SmallCard = styled.div`
    background-color: #fff ;
    box-shadow: 0px 0px 9px #26242424;
    border: 1px solid #c6c6c6 ;
    border-radius: 6px;
    height: 100%;
    width: 34%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;

    @media(max-width: 900px){
        width: 100%;
        margin: 5px 0px;
    }
`;
export const DivBy3 = styled.div`
    width: calc(100%/3);
    display: flex;
    align-items: flex-end;
    >h6{
        color: #434343;
    };
    >small {
        color: #787878;
        display: flex;
        align-items: flex-end;
    }
`;
export const LineBy3 = styled.div`
     width: 100%; 
     padding: 0 5%;
     padding-top: 3%;
     display: flex;
     align-items: flex-end;
     align-content: flex-end;
     justify-content: space-between;
     border-bottom: 1px solid #f1f1f1;

     @media(max-width: 900px){
        align-items: flex-start;
         align-content: flex-start;
     }
    
`;
export const LineBy3B = styled.div`
     width: 100%; 
     padding: 0 5%;
     padding-top: 3%;
     display: flex;
     align-items: flex-end;
     align-content: flex-end;
     justify-content: space-between;
     border-bottom: 1px solid #f1f1f1;

     @media(max-width: 900px){
        flex-direction: column;
        align-items: flex-start;
        align-content: flex-start;
     }
    
`;
export const DivProducts = styled.div`
    width: 45%; 
    height: 100%;
    @media(max-width: 900px){
        width: 100%; 
   
    }
`;

export const DivTop = styled.div`
    padding: 2% 5%;
    display: flex;
    justify-content: space-between;

    @media(max-width: 900px){
        flex-direction: column;
    }
`;