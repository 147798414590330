import React from 'react';
import StoreDashboard from '../../Components/Store/StoreDashboard';
import { useInformation } from '../../../Providers/informations';

import {
    Container,
} from './styles';

const StoreProfile = () => {

    const { storeInfo } = useInformation();

    return (
        <Container>
            {!storeInfo.StoreId ? <div style={{ height: '100vh' }} /> : <StoreDashboard />}
        </Container>
    )
}

export default StoreProfile;