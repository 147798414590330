import styled from "styled-components";

export const Container = styled.div`
 padding: 25px;
 width:99% ;
`;
export const ProductSection = styled.div`
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-left: auto;
    margin-right: auto;
`;

export const arrowStyles = {
    position: 'absolute',
    zIndex: 2,
    top: 'calc(50% - 15px)',
    width: 50,
    height: 50,
    cursor: 'pointer',
    opacity: '2'
};

export const indicatorStyles = {
    background: '#fff',
    width: 8,
    height: 8,
    display: 'inline-block',
    margin: '0 8px',
}
