import styled from "styled-components";

export const Container = styled.div`
    margin-top: 2%;
    padding-bottom: 25px;
    padding: 0;

    @media(max-width: 1600px){
        padding:0 1% ;
    }
`;
export const ItemArea = styled.div`
    display: flex;
    justify-content: center;
`;

export const arrowStyles = {
    position: 'absolute',
    zIndex: 2,
    top: 'calc(50% - 15px)',
    width: 50,
    height: 50,
    cursor: 'pointer',
    opacity: '2'
};