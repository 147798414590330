import styled from "styled-components";

export const Container = styled.div`
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    width: 100%;
    height: 450px;
    justify-content: center;
    display: flex;
    align-items: center;
    align-content: center;
    margin-bottom: 5%;
    @media(max-width: 800px){
        height: 100%;
    }
    
`;

export const Div = styled.div`
    width: 90%;
    display: flex;
    @media(max-width: 1500px){
        width: 90%;
    }
    @media(max-width: 800px){
        width: 60%;
        flex-direction: column;
        justify-content: center;
    }

`;
export const Left = styled.div`
    width: 60%;
    height: 450px;
    border-radius: 10px;
    background: #FFF;
    margin-right: 2%;
    transition: transform 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275), box-shadow 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    cursor: pointer;
    :hover{
        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
        transform: translateY(-5px);
    }
    @media(max-width: 800px){
        width: 100%;
       height: 100%;

    }
`;
export const Right = styled.div`
    width: 40%;
    height: 450px;
    justify-content: center;
    @media(max-width: 800px){
       width: 100%;
       height: 100%;
       padding-top: 15px;
    }
`;
export const Line = styled.div`
    height: 50%;
    width: 100%;
    display: flex;
    justify-content: space-between;
`;

export const CardLine = styled.div`
    width: 90%;
    padding: 0 2%;
    height: 100%;
    border-radius: 10px;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: transform 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275), box-shadow 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    cursor: pointer;
    :hover{
        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
        transform: translateY(-5px);
    };
    > img {
        @media(max-width: 1420px){
            width: 90%;
    }
    }
`;

export const P = styled.p`
    color: #000;
    text-align: center;
    font-size: 2rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    @media(max-width: 800px){
        font-size: 0.8rem;
    }
`;

export const DivGreen = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    > button {
        font-size: 1.3rem;
        width: 40%;
        height: 60px;
        @media(max-width: 800px){
        font-size: 0.5rem;
        height: 30px;
    }
    }
    > small {
        @media(max-width: 800px){
        font-size: 0.5rem;
        }
    }
`;