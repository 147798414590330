import styled from "styled-components";

export const Container = styled.div`
     padding: 1% 5%;
`;

export const ContentCard = styled.div`
    width: 100%;
    height: 100vh;
    background-color: #FFFFFF ;
    box-shadow: 0px 0px 9px #26242424;
    border-radius: 6px;
    padding-bottom: 2%;
    @media(max-width: 1366px){
        padding-bottom: 5%;
    }
`;

export const TopTitleDiv = styled.div`
    width: 100%;
    height: 110px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 2px #00000029;
    border-bottom: 1px solid #DDDDDD;
    opacity: 1;
    padding: 5px;
    border-radius: 6px 6px 0 0;
`;

export const ListContentOrder = styled.div`
    height: 90%;
    overflow: auto;
`;

export const DivMaster = styled.div`
    display: flex;
    width: 100%;
    background: #FFFFFF;
    border-radius: 6px;
    @media(max-width: 900px){
        display: flex;
        flex-direction: column;
    }
`;

export const ItemDiv = styled.div`
    border-bottom: 1px solid #D3CBCB;
    border-top: 1px solid #D3CBCB;
    background: #FFF;
    width: 100%;
    padding: 5px; 
    margin-bottom: 15px;
    
`;
export const Top = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #F1EEEE;

    @media(max-width: 1130px){
        flex-direction: column;
    }
`;
export const Bottom = styled.div`
    width: 100%;
    padding: 15px;
    display: flex;
    justify-content: space-between;
`;
export const TopL = styled.div`
    width: 80%;
    height: 100%;
`;
export const TopR = styled.div`
    width: 20%;
`;
export const Label = styled.small`
    color: #8F8888;
    font-size: 0.8rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
`;
export const TextContent = styled.span`
    color: #000;
    font-size: 0.9rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: uppercase;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`;
export const Line = styled.div`
    display: flex;
    flex-direction: column;
    text-align: left;
`;
export const Lines = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 15px;
`;
export const ImageCard = styled.div`
    width: 73.59px;
    height: 76.995px;
    flex-shrink: 0;
    border-radius: 10px;
    border: 1px solid #C9C5C5;
    background: url(${props => props.url}), lightgray 50% / cover no-repeat ;

`;

export const TextProduct = styled.span`
    display: flex;
    height: 30.975px;
    flex-direction: column;
    justify-content: center;
    width: 300px;
    color: #434343;
    font-size: 0.8rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    @media(max-width: 600px){
        width: 80%;
    }
    @media(max-width: 1366px){
        width: 50%;
    }
    
`;
export const TextStore = styled.small`
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-shrink: 0;
    color: #211E1E;
    font-size: 0.9rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
`;
export const ProductViewCard = styled.div`
    display: flex;
    
`;