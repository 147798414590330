import styled from 'styled-components';

export const Container = styled.div`
    padding: 2%;
`;
export const ProductSection = styled.div`
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-left: auto;
    margin-right: auto;
`;
export const SideSection = styled.div`
    width: 300px;
    height: 100%;
    border-radius: 6px;
    padding: 5px 2px;
    background: #fff ;
    overflow: auto;

    @media(max-width: 800px){
        display: none;
    }
`;