import styled from "styled-components";
import { makeStyles } from '@mui/styles';


export const Container = styled.div`
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: row;
`;
export const Left = styled.div`
    height: 100%;
    width: 60%;

    @media(max-width: 800px){
        display: none;
       
    }

`;
export const Right = styled.div`
    height: 100%;
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    @media(max-width: 800px){
        width: 100%;
       
    }
`;

export const Middle = styled.div`
    width: 75%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    @media(max-width: 800px){
        width: 100%;
       
    }
`;
export const useStyles = makeStyles({
    small: {
        color: 'red',
    },
});
export const DivTitle = styled.div`
    padding-bottom: 20px;
    display: none;
    >h1{
        color: #009c43;
        font-weight: 900;
    }
    @media(max-width: 800px){
        display: flex;
    }
`;
export const DivButton = styled.div`
    align-items: center;
    align-content: center;
    display: flex;
    padding-top: 10px;
    width: 400px;
    padding: 15px 0 ;

    @media(max-width: 800px){
       width: 90%;
    }
`;