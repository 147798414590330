import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import AppRoutes from './app.routes';
import AdmRoutes from './adm.routes';

const Routes = () => (
    <BrowserRouter>
        {window.location.href.split('/')[3] === "agroProdutos_management" ? <AdmRoutes /> : <AppRoutes />}
    </BrowserRouter>

);

export default Routes;