import styled from 'styled-components';

export const Label = styled.div`
    text-align: left; 
    padding-left: 10%;
    > h3 {
        padding-top: 10px;
        text-decoration: none;
        color: #6E6E6E;
        font-weight: 550;
    }
`;

export const CustomizeInput = styled.div`
    width: 400px;
    height: 70px;
    border-radius: 6px;
    background: #fff;
    border: solid 1px #DDDDDD;
    border-left: solid 10px #009c43;
    display: flex;
    flex-direction: column;
    padding: 5px;
    margin-bottom: 5px;
    > label{
        font-size: 12px;
        color: #009c43;
    }
    > input {
        border-radius: 6px;
        height: 60%;
        padding-left: 5px ;
        color: #717171;
        font-size: 1rem;
    }

    > div {
      > label{
        font-size: 12px;
        color: #009c43;
    }
    > input {
        border-radius: 6px;
        height: 60%;
        padding-left: 5px ;
        color: #717171;
        font-size: 1rem;
        width: 90%;
    }
    }
    @media(max-width: 800px){
       width: 90%;
    }
`;
export const TitleH3 = styled.h3`
  color: #717171;
  font-size: 1.8rem;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  @media(max-width: 900px){
    font-size: 1.2rem;
  }

  
`;

export const TitleH6 = styled.h6`
  color: #717171;
  font-size: 1.2rem;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

  @media(max-width: 900px){
    font-size: 0.9rem;
  }
`;