import React from "react";
import * as ProductsFunction from '../../../../Services/getProducts';
import * as AddProduct from '../../../../Services/addViewProduct';
import { useNavigate } from "react-router-dom";
import Checkbox from '@mui/material/Checkbox';
import SnackBar from "../SnackBar";
import axios from "axios";
import {
    Container,
    Description,
    DivBottom,
    DivList,
    Image2,
    Input,
    Elips,
    Image,
    Price,
    P,
} from './styles';
import { useInformation } from "../../../../Providers/informations";
import { ButtonGreenSmall } from "../../../../Styles/stylesButtons";


const CarProductCard = ({ products, origin, funcGet, control, set }) => {
    const navigate = useNavigate();
    const { setCart } = useInformation();

    const [valor, setValor] = React.useState(products?.quantity * parseFloat(products?.unit_price));
    const [quantity, setQuantity] = React.useState(products?.quantity);

    const [checked, setChecked] = React.useState(products.pre_order_selected === 1 ? true : false);
    const [cartSuccess, setCartSuccess] = React.useState(false);
    const [listSuccess, setListSuccess] = React.useState(false);
    const [cartError, setCartError] = React.useState(false);
    const [listError, setListError] = React.useState(false);

    const options = { style: 'currency', currency: 'BRL' };

    const change = async () => {
        setValor(quantity * parseFloat(products?.unit_price));
        try {
            if (quantity !== products.quantity) {
                var data = {
                    "quantity": quantity
                }
                await axios({ method: 'put', url: `${process.env.REACT_APP_LINK}/api/user/cart/update/${products.store_slug}/${products.product_slug}`, headers: { 'Authorization': `Bearer ${localStorage.getItem("clientToken")}` }, data })
                navigate('/produtos/cart/lista');
            }
        } catch (error) {

        }
    }
    const addToList = async (slug, storeSlug) => {
        try {
            await AddProduct.addToFavorite(slug, storeSlug);
            setListSuccess(true);
            setTimeout(() => {
                setListSuccess(false);
            }, 1500);
        } catch (error) {
            setListError(true);
            setTimeout(() => {
                setListError(false);
            }, 1500);
        }
    }
    const removeProduct = async (productSlug, storeSlug) => {
        try {
            const statusRemoved = await ProductsFunction.deleteCarProduct(productSlug, storeSlug);
            getListCarProd()
            if (statusRemoved?.response?.data.message) {

            } else {
                navigate('/produtos/cart/lista');
            }
        } catch (error) {

        }
    }
    const getListCarProd = async () => {
        try {
            const response = await ProductsFunction.getProductsCart();
            setCart(response.data.products)
        } catch (error) {

        }
    }

    const addToCart = async (slug, storeSlug) => {
        if (localStorage.getItem("ClientAuth") === "true") {
            const add = await AddProduct.addToCart(slug, 1, storeSlug);
            if (add.status_code !== 200 || !add) {
                setCartError(true);
                setTimeout(() => {
                    setCartError(false);
                }, 1000);
            } else {
                getListCarProd()
                setCartSuccess(true);
                navigate('/produtos/cart/lista');
                setTimeout(() => {
                    setCartSuccess(false);
                }, 4000);
            }
        } else {
            localStorage.setItem("product", slug);
            localStorage.setItem("gotToCart", "yes");
            navigate(`/login/ebe67af1-1bde`);
        }
    }

    const handleChange = async (event) => {
        setChecked(event.target.checked);
        try {
            await ProductsFunction.edirPreOrder(products.id, event.target.checked);
            if (control) {
                set(false)
                setTimeout(() => {
                    funcGet();
                }, 6000);
            }
        } catch (error) {

        }

    };

    React.useEffect(() => {
        const timeOut = setTimeout(() => { change() }, 2000);
        return () => clearTimeout(timeOut);
    }, [quantity])

    return (
        <Container>
            <DivList>
                {origin === "cart" ?
                    <div style={{ display: 'flex' }}>
                        <div><Checkbox checked={checked}
                            onChange={handleChange}
                            inputProps={{ 'aria-label': 'controlled' }} color="success" /></div>
                        <Image><img src={`${process.env.REACT_APP_LINK}/${products?.images?.[0]}`} alt={products?.title}></img></Image>
                    </div> :
                    <Image2><img src={`${process.env.REACT_APP_LINK}/${products?.images?.[0]}`} alt={products?.title}></img> </Image2>}

                <Description>
                    {origin === "cart" ? <h6>{products?.title}</h6> : <h6 style={Elips}>{products?.title}</h6>}
                    {origin === "cart" ? <><small></small><b> {products?.unit_price?.toLocaleString('pt-BR', options)} </b></> : <><small> </small><b> {products?.price?.toLocaleString('pt-BR', options)} </b></>}
                    {/* <small>/unidade</small> */}
                    <p></p>
                    {origin === "cart" ? <div style={{ display: 'flex' }}>
                        <P onClick={() => removeProduct(products?.product_slug, products?.store_slug)}>Remover</P> <P onClick={() => addToList(products?.product_slug, products?.store_slug)}>Adicionar aos Favoritos</P>
                    </div> :
                        <DivBottom>
                          <ButtonGreenSmall onClick={() => addToCart(products?.product_slug, products?.store_slug)}>+ Carrinho</ButtonGreenSmall>
                        </DivBottom>}
                </Description>

                <Price>
                    {origin === "cart" && <><div style={{ display: 'flex' }}>
                        <input onChange={(e) => { setQuantity(Math.min(e.target.value, products?.stock)) }}
                            type="number" autoComplete="off" value={quantity} id="qtd" style={Input} placeholder={products?.quantity} maxLength="7" />
                    </div> <small><b>{valor?.toLocaleString('pt-BR', options)}</b></small> </>}
                </Price>
            </DivList>
            {cartSuccess && <SnackBar type="success" time={1000} message={"Adicionado ao carrinho com sucesso"} states={true} />}
            {cartError && <SnackBar type="error" time={1000} message={"Erro ao adicionar ao carrinho"} states={true} />}
            {listError && <SnackBar type="error" time={1000} message={"Erro ao adicionar a lista"} states={true} />}
            {listSuccess && <SnackBar type="success" time={1000} message={"Adicionado a lista de favoritos"} states={true} />}
        </Container>
    )
}

export default CarProductCard;