import styled from "styled-components";

export const Container = styled.div`
    min-height: 100vh;
    height: 100%;
`;
export const Content = styled.div`
    padding: 2%;
`;

export const Title = styled.h3`
    font-size: 1.8rem; 
    color: #7d7d7d;

    @media(max-width: 800px){
        font-size: 1.6rem; 
    }
`;