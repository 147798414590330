import React from 'react';
import Routes from './Routes';
import GlobalStyles from './Styles/GlobalStyles';
import { ThemeProvider } from 'styled-components';
import light from './Styles/themes/light';
import { useScrollTo } from "react-use-window-scroll";
import { initializeInstallPrompt } from './installPrompt';

const App = () => {

    const scrollTo = useScrollTo();
    React.useEffect(() => {
        scrollTo(0, 0);
    }, [window.location])

    initializeInstallPrompt();

    return (
        <ThemeProvider theme={light}>
            <GlobalStyles />
            <Routes />
        </ThemeProvider>
    );
}

export default App;