import React from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';


import {
    Container,
    DivMain,
    DivImg,
} from './styles';


const CarouselStore = ({ banner, logo }) => {

    return (
        <Container>
            <Carousel autoPlay={false}
                showThumbs={false}
                style={{ borderRadius: '6px' }}
                interval={6000}
                infiniteLoop={true}
                showStatus={false}>
                <DivMain>
                    <DivImg>
                        <img src={banner} alt="Banner" style={{ borderRadius: '6px' }} />
                    </DivImg>
                    {window.innerWidth <= '750' && <div className='legend' style={{ background: '#ffffff', width: '8rem', opacity: '1', height: '8rem', borderRadius: '100%', backgroundImage: `url(${logo})`, backgroundSize: 'contain', backgroundRepeat: 'no-repeat', backgroundPosition: 'center' }}>
                    </div>}
                    {window.innerWidth > '750' && <div className='legend' style={{ background: '#ffffff', width: '150px', opacity: '1', height: '150px', borderRadius: '100%', backgroundImage: `url(${logo})`, backgroundSize: 'contain', backgroundRepeat: 'no-repeat', backgroundPosition: 'center' }}>

                    </div>}
                </DivMain>
                {/* <div>
                    <img src={banner05} alt="Produto" />
                      <p className="legend">Legend 3</p>
                </div> */}
            </Carousel>
        </Container>
    );
};

export default CarouselStore;