import React from 'react';
import AdminLogin from '../Admin/Pages/AdmLogin';
import Layout from '../Admin/Components/Layout';
import AdmDashboard from '../Admin/Pages/AdminDashboard';
import AdminPrivateRoute from '../Hooks/AdminAuth';
import StoreList from '../Admin/Pages/Store';

import {
  Routes,
  Route,
} from "react-router-dom";
import Lines from '../Admin/Pages/Lines';
import Banners from '../Admin/Pages/Banners';
import SalesList from '../Admin/Pages/Sales';



const AdmRoutes = () => (
  <Layout>
    <Routes>
      {/* <Route path="" element={<MainPage />} /> */}
      <Route exact path='/agroProdutos_management/supervisor/login' element={<AdminLogin />} />
      <Route exact path='/agroProdutos_management/supervisor/dashboard' element={<AdminPrivateRoute> <AdmDashboard /> </AdminPrivateRoute>} />
      <Route exact path='/agroProdutos_management/supervisor/store' element={<AdminPrivateRoute> <StoreList /> </AdminPrivateRoute>} />
      <Route exact path='/agroProdutos_management/supervisor/lines' element={<AdminPrivateRoute> <Lines /> </AdminPrivateRoute>} />
      <Route exact path='/agroProdutos_management/supervisor/banners' element={<AdminPrivateRoute> <Banners /> </AdminPrivateRoute>} />
      <Route exact path='/agroProdutos_management/supervisor/sales' element={<AdminPrivateRoute> <SalesList /> </AdminPrivateRoute>} />
    </Routes>
  </Layout>

);

export default AdmRoutes;