import React from 'react';
import ProfileStoreTabs from '../Configurações/StoreProfileTabs';
import { getBalance } from '../../../../Services/StoreCheckout';
import { getStoreProfile } from '../../../../Services/store';
import { TitleH3, TitleH6 } from '../../../../Styles/style';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import CountUp from 'react-countup';
import Condition from '../../../../Assets/Documents/CONDIÇÕES DE NAVEGAÇÃO.pdf';
import Sales from '../../../../Assets/Documents/TERMOS DE COMERCIALIZAÇÃO.pdf';


import {
    Container,
    TitleArea,
    SmallCard,
    InfoTable,
    Square,
    Bottom,
    Small,
    Line,
    Area,
    DivMidle,
} from './styles';
import { SmallChart } from './SmallChart';


const ClientDashboard = () => {

    const navigate = useNavigate();
    const options = { style: 'currency', currency: 'BRL' };

    const [storeProfile, setStoreProfile] = React.useState([]);
    const [storeBalance, setStoreBalance] = React.useState([]);

    const getStoreInfo = async () => {
        const storeInfo = await getStoreProfile();
        setStoreProfile(storeInfo.data);
    }
    const getBalanceFunc = async () => {
        try {
            const response = await getBalance();
            setStoreBalance(response)
        } catch (error) {

        }
    }

    React.useEffect(() => {
        getStoreInfo();
        getBalanceFunc();
    }, [])

    return (
        <Container>
            <Line style={{}}>
                <InfoTable>
                    <ProfileStoreTabs prof={storeProfile} getFunc={getStoreInfo} balanceDisp={(storeBalance?.data?.available_amount / 100).toLocaleString('pt-BR', options)} balanceIndsp={(storeBalance?.data?.waiting_funds_amount / 100).toLocaleString('pt-BR', options)} />
                </InfoTable>
                <DivMidle>
                    <SmallCard >
                        <TitleArea>
                            <TitleH6 >Vendas</TitleH6>
                        </TitleArea>
                        <hr></hr>
                        <Area >
                            <Square style={{ borderRight: '2px solid #bababa' }}>
                                <Small>Últimos 30 dias</Small>
                                <TitleH3><CountUp end={storeProfile?.orders?.last_month} duration={2.75} /></TitleH3>
                                <Bottom></Bottom>
                            </Square>
                            <Square style={{}}>
                                <Small>Total:</Small>
                                <TitleH3><CountUp end={storeProfile?.orders?.total} duration={3.75} /></TitleH3>
                                <Bottom>
                                    <Link to='/loja/vendas' style={{ textDecoration: 'none' }}><Small style={{ cursor: 'pointer', color: '#009C43' }}>Ver mais</Small></Link>
                                </Bottom>
                            </Square>
                        </Area>
                    </SmallCard >
                    <SmallCard >
                        <TitleArea style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <TitleH6 >Produtos</TitleH6>
                            <TitleH6 ><CountUp end={storeProfile?.products?.total} duration={3.75} /></TitleH6>
                        </TitleArea>
                        <hr></hr>
                        <Area >
                            <Square style={{ borderRight: '2px solid #bababa' }}>
                                <Small>Disponíveis</Small>
                                <TitleH3><CountUp end={storeProfile?.products?.active} duration={2.75} /></TitleH3>
                                <Bottom></Bottom>
                            </Square>
                            <Square style={{}}>
                                <Small>Indisponíveis</Small>
                                <TitleH3><CountUp end={storeProfile?.products?.inactive} duration={1.75} /></TitleH3>
                                <Bottom onClick={() => navigate('/produtos/lista')}>
                                    <Small style={{ cursor: 'pointer', color: '#009C43' }}>Ver mais</Small>
                                </Bottom>
                            </Square>
                        </Area>
                    </SmallCard >
                    <SmallCard style={{ cursor: 'pointer' }} onClick={() => navigate('/loja/vendas/relatorios')}>
                        <TitleArea>
                            <TitleH6 >Relatórios de Vendas</TitleH6>
                        </TitleArea>
                        <hr></hr>
                        <SmallChart data={storeProfile?.week_sales} />
                    </SmallCard >
                </DivMidle>

                <DivMidle >
                    <SmallCard >
                        <TitleArea>
                            <TitleH6 >Meus Ganhos R$</TitleH6>
                        </TitleArea>
                        <hr></hr>
                        <Area >
                            <Square style={{ borderRight: '2px solid #bababa' }}>
                                <Small>Disponível</Small>
                                <TitleH6>{(storeBalance?.data?.available_amount / 100).toLocaleString('pt-BR', options)}</TitleH6>
                                <Bottom></Bottom>
                            </Square>
                            <Square >
                                <Small>A Receber</Small>
                                <div style={{ width: '90%', textAlign: 'center' }}><TitleH6 style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{(storeBalance?.data?.waiting_funds_amount / 100).toLocaleString('pt-BR', options)}</TitleH6></div>
                                <Bottom style={{}}>
                                    <Small style={{ cursor: 'pointer', color: '#009C43' }} onClick={() => navigate('/loja/ganhos')}>Ver mais</Small>
                                </Bottom>
                            </Square>
                        </Area>
                    </SmallCard >

                    <SmallCard >
                        <TitleArea style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <TitleH6 >Perguntas</TitleH6>
                            <TitleH6 ><CountUp end={storeProfile?.uqestions?.open + storeProfile?.uqestions?.answered} duration={3.75} /></TitleH6>
                        </TitleArea>
                        <hr></hr>
                        <Area >
                            <Square style={{ borderRight: '2px solid #bababa' }}>
                                <Small>Abertas</Small>
                                <TitleH3><CountUp end={storeProfile?.uqestions?.open} duration={2.75} /></TitleH3>
                                <Bottom></Bottom>
                            </Square>
                            <Square style={{}}>
                                <Small>Respondidas</Small>
                                <TitleH3><CountUp end={storeProfile?.uqestions?.answered} duration={1.75} /></TitleH3>
                                <Bottom onClick={() => navigate('/loja/perguntas')}>
                                    <Small style={{ cursor: 'pointer', color: '#009C43' }}>Ver mais</Small>
                                </Bottom>
                            </Square>
                        </Area>
                    </SmallCard >
                    <SmallCard >
                        <TitleArea>
                            <TitleH6 >Termos de uso</TitleH6>
                        </TitleArea>
                        <hr></hr>
                        <Area >
                            <Square style={{ borderRight: '2px solid #bababa' }}>
                                <Small>Termos</Small>
                                <TitleH6 style={{ cursor: 'pointer' }} onClick={() => window.open(Condition, '_blank')}>
                                    Usuário
                                </TitleH6>
                                <Bottom></Bottom>
                            </Square>
                            <Square >
                                <Small>Termos</Small>
                                <div style={{ width: '90%', textAlign: 'center', cursor: 'pointer' }}>
                                    <TitleH6 style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }} onClick={() => window.open(Sales, '_blank')}>
                                        Loja
                                    </TitleH6></div>
                                <Bottom style={{}}>
                                    <Small style={{ cursor: 'pointer', }} onClick={() => navigate('/loja/ganhos')}>Ver mais</Small>
                                </Bottom>
                            </Square>
                        </Area>
                    </SmallCard >
                </DivMidle>
            </Line>
        </Container>
    )
}

export default ClientDashboard;