import styled from 'styled-components';

export const Container = styled.div`

`;

export const H3 = {
    fontSize: '0.9rem',
    color: '#A9ABAE',
    cursor: 'pointer',
    textTransform: 'uppercase',
    textDecoration: 'none',
}