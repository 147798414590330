import React from 'react';
import { Link } from 'react-router-dom';

import {
    Container,
    H3,
} from './styles';


const MenuItemText = ({string, link}) => {
    return(
        <Container>
            <Link to={link} style={H3}><h3 style={H3}>{string}</h3></Link>
        </Container>
    )
}

export default MenuItemText;