import React from "react";
import { ButtonGreenSmall, ButtonBlueSmall, ButtonSilverSmall } from '../../../../../Styles/stylesButtons';
import { useInformation } from "../../../../../Providers/informations";
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { useNavigate, useLocation } from "react-router-dom";
import VisibilityIcon from '@mui/icons-material/Visibility';
import InputGroup from "../../../General/InputGroup/Group";
import { getProfile, updateUser } from "../../../../../Services/user";
import { TitleH3 } from '../../../../../Styles/style';
import { ThemeProvider } from '@mui/material/styles';
import ModalComponent from '../../../General/Modal';
import SetasStore from "../../../Store/SetAsStore";
import Typography from '@mui/material/Typography';
import CheckIcon from '@mui/icons-material/Check';
import SnackBar from "../../../General/SnackBar";
import SendIcon from '@mui/icons-material/Send';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import InputMask from "react-input-mask";
import Tabs from '@mui/material/Tabs';
import Gravatar from "react-gravatar";
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import moment from "moment/moment";
import isMobile from '../../../../../Services/isMobile';

import {
    ListContentOrder,
    DeleteSection,
    ContentCard,
    TopTitleDiv,
    Container,
    theme2,
    DivRow,
} from './styles';
import { updatePassword } from "../../../../../Services/auth";
import { GetProfileInfo } from "../../../../../Services/pfofileInfo";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

const InfoDiv = () => {
    return (
        <div>
            <p>Sua Loja está em processo de aprovação, assim que aprovado você será notificado, e já poderá começar a vender</p>
            <small>Para maiores informações entre em contato: atendimento@agroprodutos.com.br</small>
        </div>
    )
}

const ProfileTabs = () => {


    const { info, storeInfo, open, setOpen, setInfo } = useInformation();
    const navigate = useNavigate();
    const location = useLocation();

    const searchParams = new URLSearchParams(location.search);


    const [hasPasswordChange, setPasswordChange] = React.useState(false);
    const [hasChange, setHasChange] = React.useState(false);
    const [required, setRequired] = React.useState(false);
    const [success, setSuccess] = React.useState(false);
    const [saving, setSaving] = React.useState(false);
    const [error, setError] = React.useState(false);

    const [docType, setDocType] = React.useState(info.ClientDocumentType ? info.ClientDocumentType : "CPF");
    const [docNumber, setDocNumber] = React.useState(info.ClientDocumentNumber);
    const [email, setEmail] = React.useState(info.ClientEmail);
    const [phone, setPhone] = React.useState(info.ClientPhone);
    const [name, setName] = React.useState(info.ClientName);
    const [indexValue, setIndexValue] = React.useState(1);
    const [value, setValue] = React.useState(0);
    const [inse, setInse] = React.useState(info.ClientInscE);

    const [notMatchPass, setNotMatchPass] = React.useState(false)
    const [emptyField, setEmptyField] = React.useState(false);

    const [passType, setPassType] = React.useState("password");
    const [oldPassword, setOldPassword] = React.useState();
    const [newPassword, setNewPAssword] = React.useState();
    const [confirmPass, setConfirmPass] = React.useState();

    const see = () => {
        setPassType("text");
    }
    const hide = () => {
        setPassType("password");
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const UpdateUserF = async (name, email, phone, docType, docNumber) => {
        if (!name || !email || !phone || !docType || !docNumber) {
            setRequired(true);
            setTimeout(() => {
                setRequired(false)
            }, 4000);
        } else {
            try {
                await updateUser(name, email, phone, docType, docNumber, info?.ClientUuid, inse);
                const profileInfo = await getProfile();
                setInfo({
                    ClientName: profileInfo.data.user.name,
                    CientType: profileInfo.data.user.type,
                    ClientUuid: profileInfo.data.user.uuid,
                    ClientEmail: profileInfo.data.user.email,
                    ClientStoreStatus: profileInfo.data.user.store?.status,
                    CreatedAt: profileInfo.data.user.created_at,
                    UpdatedAt: profileInfo.data.user.updated_at,
                    ClientPhone: profileInfo.data.user.phone,
                    ClientDocumentType: profileInfo.data.user.document_type,
                    ClientDocumentNumber: profileInfo.data.user.document,
                    ClientEmailVerified: profileInfo.data.user.email_verified,
                    ClientInscE: profileInfo?.data?.user?.inscricao_estadual
                });
                if (searchParams?.get('redirect') === "finalizar_compra") {
                    navigate('/produtos/compra/finalizar/verify')
                }
            } catch (error) {
                setError(true);
                setTimeout(() => {
                    setError(false);
                }, 4000);
            }
        }
    }

    const verifyEmail = () => {
        try {
            navigate('/confirmar/email')
        } catch (error) {

        }
    }

    const changePassword = async (old, newPass, confirmNewPass) => {
        setSaving(true);
        if (!old || !newPass || !confirmNewPass) {
            setEmptyField(true);
            setSaving(false)
            setTimeout(() => {
                setEmptyField(false)
            }, 6000);
        } else {
            if (newPass === confirmNewPass) {
                try {
                    const changePassResponse = await updatePassword(old, newPass, confirmNewPass)
                    if (changePassResponse.message === "Your password was changed successfully") {
                        setSuccess(true);
                        setOldPassword("");
                        setNewPAssword("");
                        setConfirmPass("");
                        setSaving(false);
                        setTimeout(() => {
                            setSuccess(false);
                        }, 4000);
                    }
                } catch (error) {
                    setError(true);
                    setSaving(false);
                    setTimeout(() => {
                        setError(false);
                    }, 4000);
                }
            } else {
                setNotMatchPass(true);
                setSaving(false)
                setTimeout(() => {
                    setNotMatchPass(false);
                }, 6000);
            }
        }

    }

    return (
        <Container>
            <ContentCard>
                <TopTitleDiv>
                    <div style={{ height: '63%', display: 'flex', justifyContent: 'space-between', padding: '15px' }}>
                        <div style={{ display: 'flex' }}>
                            <TitleH3>Meu Perfil </TitleH3>
                        </div>
                    </div>
                    <Divider />
                    <AppBar position="static" sx={{ background: '#FFFFFF', height: '20%', color: 'black', border: 'none', boxShadow: '0' }}>
                        <ThemeProvider theme={theme2}>
                            <Tabs
                                value={value}
                                onChange={handleChange}
                                indicatorColor="primary"
                                textColor="inherit"
                                variant="fullWidth"
                                aria-label="full width tabs example"
                            >
                                <Tab label="Meus Dados" onClick={() => setIndexValue(1)} {...a11yProps(0)} style={{ color: value === 0 && '#009c43', fontSize: '0.9rem', fontWeight: '700' }} />
                                <Tab label="Segurança" onClick={() => setIndexValue(2)} {...a11yProps(1)} style={{ color: value === 1 && '#009c43', fontSize: '0.9rem', fontWeight: '700' }} />
                                <Tab label="Minha Conta" onClick={() => setIndexValue(3)} {...a11yProps(2)} style={{ color: value === 2 && '#009c43', fontSize: '0.9rem', fontWeight: '700' }} />
                            </Tabs>
                        </ThemeProvider>
                    </AppBar>
                </TopTitleDiv>
                <ListContentOrder>
                    {indexValue === 1 && <div>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <Gravatar email={info.ClientEmail} style={{ borderRadius: '100%' }} size={110} />
                            {/*  <Avatar
                                    alt="Remy Sharp"
                                    src="/static/images/avatar/1.jpg"
                                    sx={{ width: 96, height: 96 }}
                                /> */}
                        </div>
                        <form onChange={() => setHasChange(true)}>
                            <InputGroup label={["Nome"]} type={["text"]} id={["name"]} value={[name]} customClass={["col"]} setState={setName} group={false} />
                            <InputGroup label={["Email", "Telefone"]} type={["text", "text"]} id={["email", "phone"]} value={[email, phone]} customClass={["col", "col"]} setState={setEmail} setState2={setPhone} group={true} />
                            <DivRow>
                                <div className="container">
                                    <div className="row">
                                        <div className="col">
                                            <label htmlFor="docType" className="form-label">Tipo do Documento (Selecionar)</label>
                                            <select className="form-select" id="docType" aria-describedby="docType" value={docType} onChange={(e) => setDocType(e.target.value)}>
                                                <option value="CPF">CPF</option>
                                                <option value="CNPJ" >CNPJ</option>
                                            </select>
                                        </div>
                                        {!isMobile() &&
                                            <div className="col">
                                                <label htmlFor="docNumber" className="form-label">Nº Documento {!docNumber && <small style={{ color: 'red' }}>"Pendente"</small>}</label>
                                                <InputMask mask={docType === "CNPJ" ? "99.999.999/9999-99" : "999.999.999-99"} className="form-control" id="docNumber" aria-describedby="docNumber" value={docNumber} onChange={(e) => setDocNumber(e.target.value)} />
                                            </div>
                                        }
                                    </div>
                                    {isMobile() && <div>
                                        <div className="col">
                                            <label htmlFor="docNumber" className="form-label">Nº Documento {!docNumber && <small style={{ color: 'red' }}>"Pendente"</small>}</label>
                                            <InputMask mask={docType === "CNPJ" ? "99.999.999/9999-99" : "999.999.999-99"} className="form-control" id="docNumber" aria-describedby="docNumber" value={docNumber} onChange={(e) => setDocNumber(e.target.value)} />
                                        </div>
                                    </div>}
                                </div>
                            </DivRow>
                            <div style={{width: '50%'}}>
                            <InputGroup label={["Inscrição Estadual (opcional)"]} type={["text"]} id={["inse"]} value={[inse]} customClass={["col"]} setState={setInse} group={false} />
                            </div>

                            <div className="container" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                {/* <div style={{ padding: '0px 10px' }}>
                                <ButtonBlueSmall> Editar </ButtonBlueSmall>
                            </div> */}
                                {hasChange &&
                                    <div style={{ padding: '0px 10px' }}>
                                        <ButtonGreenSmall onClick={() => UpdateUserF(name, email, phone, docType, docNumber)}>Salvar</ButtonGreenSmall>
                                    </div>
                                }
                            </div>
                        </form>
                        <div className="container" style={{ display: 'flex', justifyContent: 'space-between', fontSize: '0.9rem', paddingTop: '5%' }}>
                            <div >
                                <small>Conta criada em: {moment(info.CreatedAt).format('DD/MM/YYYY')}</small>
                            </div>
                            <div>
                                <small>Ultima Edição: {moment(info.UpdatedAt).format('DD/MM/YYYY ')}</small>
                            </div>
                        </div>
                    </div>}
                    {indexValue === 2 &&
                        <div>
                            <TitleH3 style={{ fontSize: '1.2rem', display: 'flex' }}>
                                Senha &nbsp; <div style={{ color: '#009c43', cursor: 'pointer' }}>{passType === "password" ? <VisibilityIcon onClick={see} /> : <VisibilityOffIcon onClick={hide} />}</div>
                            </TitleH3>
                            <Divider />
                            <form onChange={() => setPasswordChange(true)}>
                                <div className="container">
                                    <div className="row" style={{ paddingTop: '10px' }}>
                                        <div className="col">
                                            <DivRow>
                                                <label htmlFor="oldpassword" className="form-label">Senha Atual</label>
                                                <input type={passType} autoComplete="off" className="form-control" id="oldpassword" onChange={(e) => setOldPassword(e.target.value)} value={oldPassword} aria-describedby="senha" />
                                            </DivRow>
                                            <DivRow>
                                                <label htmlFor="newpassword" className="form-label">Nova Senha</label>
                                                <input type={passType} autoComplete="off" className="form-control" id="newpassword" onChange={(e) => setNewPAssword(e.target.value)} value={newPassword} aria-describedby="senha" />
                                            </DivRow>

                                            <DivRow>
                                                <label htmlFor="confirmpassword" className="form-label">Confirmar Nova Senha</label>
                                                <input type={passType} autoComplete="off" className="form-control" id="confirmpassword" onChange={(e) => setConfirmPass(e.target.value)} value={confirmPass} aria-describedby="senha" />
                                            </DivRow>
                                            <DivRow style={{ justifyContent: 'flex-end', display: 'flex' }}>
                                                {hasPasswordChange &&
                                                    saving ? <ButtonGreenSmall style={{ width: '130px', height: '30px' }}>Salvando...</ButtonGreenSmall> :
                                                    <ButtonGreenSmall style={{ width: '130px', height: '30px' }} onClick={() => changePassword(oldPassword, newPassword, confirmPass)} >Salvar Senha</ButtonGreenSmall>
                                                }
                                            </DivRow>
                                        </div>
                                        <div className="col" style={{ justifyContent: 'center', margin: 'auto', display: 'flex' }}>
                                            <div style={{ textAlign: 'justify', color: '#717171' }}>
                                                <p>Como sua senha deve ser:</p>
                                                <small>Ela deve ter ao menos 8 caracteres; <br></br> Deve conter letras e números</small>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>}
                    {indexValue === 3 &&
                        <DeleteSection>
                            <div style={{ height: '80%' }}>
                                <TitleH3 style={{ fontSize: '1.2rem' }}>
                                    Tipo de Conta &nbsp; {info.CientType === "customer" ? <ButtonBlueSmall> Cliente </ButtonBlueSmall> : <ButtonGreenSmall> Loja</ButtonGreenSmall>}
                                </TitleH3>
                                <Divider />
                                <div style={{ padding: '0px 10px', color: '#717171', paddingTop: '15px' }}>
                                    <p>Com a conta "Cliente" você poderá realizar suas compras dentro da nossa plataforma como em qualquer outro e-commerce</p>
                                    <p>Se você deseja vender através da nossa plataforma, é necessário o cadastro de pessoa jurídica do seu empreendimento</p>
                                    <p>Após realizar o cadastro de pessoa jurídica, nossa equipe irá entrar em contato para dar sequência em sua solicitação</p>
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    {info.CientType === "customer" && !storeInfo.StoreStatus ? <SetasStore /> : storeInfo.StoreStatus === "pending"
                                        ? <ModalComponent asChild={true} modalTitle="Loja em Análise" comp={<InfoDiv />} buttonText="Em análise">
                                            <ButtonSilverSmall onClick={() => setOpen(!open)} style={{ width: '8rem' }}>Em análise</ButtonSilverSmall>
                                        </ModalComponent>
                                        : storeInfo.StoreStatus === "aproved" && <ButtonGreenSmall>Loja Aprovada</ButtonGreenSmall>}
                                </div>
                            </div>
                            <div style={{ height: '20%' }}>
                                <TitleH3 style={{ fontSize: '1.2rem' }}>
                                    {info.ClientEmailVerified ? <ButtonGreenSmall style={{ width: '175px', height: '30px' }}>Email Verificado &nbsp; <CheckIcon /></ButtonGreenSmall>
                                        :
                                        <ButtonBlueSmall style={{ width: '170px', height: '30px' }} onClick={verifyEmail} >Verificar email &nbsp; <SendIcon /></ButtonBlueSmall>}
                                </TitleH3>
                                <Divider />
                            </div>
                            {/* <div style={{ height: '20%' }}>
                                <TitleH3 style={{ fontSize: '1.2rem' }}>
                                    Cancelamento de Conta
                                </TitleH3>
                                <Divider />
                                <div style={{ padding: '0px 10px', color: '#717171', paddingTop: '15px', display: 'flex', justifyContent: 'space-between' }}>
                                    <div style={{ width: '70%' }}>
                                        <small>Você pode cancelar sua conta conosco a qualuquer momento</small>
                                        <br></br>
                                        <small>Ao cancelar sua conta, todos os seus dados serão anonimizados</small>
                                    </div>
                                    <div style={{ width: '30%' }}>
                                        <ButtonRedSmall style={{ width: '170px', height: '30px' }}>Cancelar Conta</ButtonRedSmall>
                                    </div>
                                </div>
                            </div> */}
                        </DeleteSection>}
                </ListContentOrder>
                {error && <SnackBar type="error" time={4000} message="Não foi possível salvar os dados" states={true} />}
                {success && <SnackBar type="success" time={4000} message="Dados salvos com sucesso" states={true} />}
                {emptyField && <SnackBar type="info" time={4000} message="Preencher todos os campos" states={true} />}
                {notMatchPass && <SnackBar type="info" time={6000} message="A senha nova não é a mesma digitada na confirmação" states={true} />}
                {required && <SnackBar type="info" time={4000} message="Preencher todos os campos" states={true} />}
            </ContentCard>
        </Container>
    )
}

export default ProfileTabs;