import React from 'react';
import CardProduct from '../../Components/General/CardProduct';
import * as GetProducts from '../../../Services/getProducts';
import { useParams, useLocation, Link, useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import empty from '../../../Assets/ImagensGerais/empty.png';
import CarouselProduct from '../../Components/General/CaroselProduct';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import Alert from '../../../Assets/Alert.svg';

import {
    Container,
    ProductSection,
    Div404,
    DivWite,
    DivImage,
    DivTexts,
    DivAsideLeft,
    DivCardAside,
} from './styles';
import axios from 'axios';
import { getAds, getBannersList } from '../../../Services/publics';
import localforage from 'localforage';


const SearchPage = () => {

    let params = useParams();
    const location = useLocation();
    const navigate = useNavigate();

    const [listSearchedProducts, setListSearchedProducts] = React.useState([]);
    const [ads, setAds] = React.useState([]);

    const [totalOfPages, setTotalOfPages] = React.useState();
    const [nexProducts, setNextProducts] = React.useState();
    const [stateError, setStateError] = React.useState();

    const getProductsList = async () => {
        try {
            setStateError()
            const lstProducts = await GetProducts.searchProducts(params.search);
            if (lstProducts.status_code === 404) {
                setListSearchedProducts([]);
                setStateError(lstProducts.status_code)
            } else {
                setListSearchedProducts(lstProducts.data);
                setNextProducts(lstProducts.meta.links);
                setTotalOfPages(lstProducts.meta.last_page)
                localforage.setItem('productsSearch', lstProducts)
            }

        } catch (error) {
            setListSearchedProducts([]);
            setStateError(error?.response?.status_code)
        }
    }

    const getNextpage = async (page) => {
        var data = {
            "search": params.search,
        }
        const { data: res } = await axios({ method: 'post', url: `${page}`, headers: { 'Accept': 'application/json' }, data })
        setListSearchedProducts(res.data);

        let listProducts = await localforage.getItem('productsSearch');
        setTotalOfPages(listProducts?.meta.last_page);
        setNextProducts(listProducts?.meta.links);
        setPage(parseInt(localStorage.getItem('pageNumber')))
    }

    React.useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        if (searchParams.get('page')) {
            getNextpage(searchParams.get('page'));
        } else {
            getProductsList();
            setPage(1)
        }
    }, [location])

    const nextPage = async (value) => {
        navigate(`${location.pathname}?page=${nexProducts?.[value]?.url}`)

        localStorage.setItem('locationPage', `${location.pathname}?page=${nexProducts?.[value]?.url}`)
        localStorage.setItem('pageNumber', value);
    }

    const [page, setPage] = React.useState(1);

    const handleChange = (event, value) => {
        setPage(value);
        nextPage(value);
    };

    const [bannersImg, setImages] = React.useState([])

    const getBanners = async () => {
        try {
            const response = await getBannersList("search");
            setImages(response.data.banners)
        } catch (error) {

        }
    }
    const getAd = async () => {
        try {
            const response = await getAds("search");
            setAds(response.data?.banners)
        } catch (error) {

        }
    }

    const [pageWidth, setPageWidth] = React.useState(window.innerWidth);

    function recarregarPagina() {
        setPageWidth(window.innerWidth)
    }
    var TimeOut;

    window.onresize = function () {
        clearTimeout(TimeOut);
        TimeOut = setTimeout(recarregarPagina, 10);
    };

    React.useEffect(() => {
        getBanners();
        getAd();
    }, [])


    return (
        <Container>
            {stateError !== 404 &&
                pageWidth > 800 &&
                <DivAsideLeft>
                    {ads?.map((list, index) => (
                        <DivCardAside style={{ backgroundImage: `url(${list.image})` }} onClick={() => window.open(list?.action ? list?.action : '/', list?.target ? list?.target : '_self')} key={index} />
                    ))}
                </DivAsideLeft>
            }

            {stateError === 404 || listSearchedProducts.length <= 0 ?
                <Div404>
                    <DivWite>
                        <DivImage>
                            <img src={Alert} width={180} alt='anuncio' />
                        </DivImage>
                        <DivTexts>
                            <h3>Não há anúncios que correspondem à sua busca.</h3>
                            <li><b>Revise a ortografia</b> das palavras.</li>
                            <li>Utilize <b>palavras mais genéricas</b> ou menos palavras.</li>
                            <li><Link to={`/`} >Navega nas categorias</Link> para encontrar un produto similar</li>
                        </DivTexts>
                    </DivWite>
                </Div404>
                :
                <ProductSection>
                    <div style={{ marginBottom: '25px' }}>
                        <CarouselProduct images={bannersImg} />
                    </div>
                    <Grid container spacing={1} >
                        <Grid container item spacing={0.5}>
                            {listSearchedProducts.map((list) => (
                                <Grid item xs={pageWidth <= 800 ? 6 : 3} spacing={1} sx={{ display: 'flex', justifyContent: pageWidth <= 800 ? 'center' : 'flex-end' }} key={list?.id}>
                                    <CardProduct cat="linha_sementes" img={!list?.images ? empty : `${process.env.REACT_APP_LINK}/${list?.images?.[0]}`} title={list?.title} description={list?.description} price={list?.price} store={list.store_slug} slug={list.product_slug} />
                                </Grid>
                            ))}
                        </Grid>
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginBottom: '25px' }}>
                            <Stack spacing={2}>
                                <Pagination count={totalOfPages} page={page} onChange={handleChange} />
                            </Stack>
                        </div>
                    </Grid>
                </ProductSection>
            }

        </Container>
    )
}

export default SearchPage