import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

import {
    Container,
} from './styles';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


const SnackBarAdm = ({ type, time, message, states }) => {
    /* type:
    error : alerta de erro (vermelho)
    warning : alerta para aviso  (laranja)
    info : alerta para aviso (azul)
    success : alerta para sucesso (verde)
     */
    const [open, setOpen] = React.useState(states);

    const [state, setState] = React.useState({
        vertical: 'bottom',
        horizontal: 'center',
    });
    const { vertical, horizontal } = state;

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    return (
        <Container>
            <Snackbar open={open} autoHideDuration={time} onClose={handleClose} anchorOrigin={{ vertical, horizontal }}>
                <Alert onClose={handleClose} severity={type} sx={{ width: '100%' }}>
                    {message}
                </Alert>
            </Snackbar>
        </Container>
    )

}

export default SnackBarAdm;