import React from 'react';
import Button from '@mui/material/Button';
import axios_base from '../../../../axios_base';
import { useInformation } from '../../../../Providers/informations';
import { useNavigate, useLocation } from "react-router-dom";
import SnackBar from '../../../Components/General/SnackBar';
import { Link } from 'react-router-dom';
import loginImage from '../../../../Assets/Login/login_register.png';
import axios from 'axios';
import { CustomizeInput } from '../../../../Styles/style';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {
    Container,
    Left,
    Right,
    Middle,
    DivButton,
    DivTitle,
} from './styles';
import { addToCart, addToFavorite } from '../../../../Services/addViewProduct';
import { postBuyNow } from '../../../../Services/orders';


const CommonLogin = () => {

    const navigate = useNavigate();
    const { setInfo } = useInformation();
    const location = useLocation();
    const [email, setEmail] = React.useState();
    const [password, setPassword] = React.useState();
    const [loginError, setLoginError] = React.useState(false);
    const [success, setSuccess] = React.useState(false);

    const [seePassword, setSeePassword] = React.useState(false);
    const [passType, setPassType] = React.useState('password');

    const seePass = (see, type) => {
        setSeePassword(see)
        setPassType(type);
    }

    const login = async (login, pass) => {
        try {
            var data = {
                email: login,
                password: pass
            }
            const { data: res } = await axios_base.post(`/api/auth/login`, data)
            if (res.message === "success") {
                setSuccess(true)
                localStorage.setItem("clientToken", res?.data?.token);
                setInfo({ clientToken: res?.data?.token, ClientAuth: true });

                try {
                    const profile = await axios({ method: 'get', url: `${process.env.REACT_APP_LINK}/api/user/profile`, headers: { 'Authorization': `Bearer ${res.data.token}` }, })
                    console.log(profile)
                    setInfo({ ClientName: profile?.data.data.user.name, CientType: profile?.data.data.user.type, ClientEmail: profile?.data.data.user.email, ClientDocumentType: profile?.data.data.user.document_type, ClientDocumentNumber: profile?.data.data.user.document, ClientPhone: profile?.data.data.user.phone });
                } catch (error) {
                    console.log(error.response)
                }


                localStorage.setItem('ClientAuth', "true");
                /*   window.location.reload(); */
                const searchParams = new URLSearchParams(location.search);
                if (searchParams?.get('addto') || searchParams?.get('redirect')) {
                    if (searchParams?.get('addto')) {
                        switch (searchParams.get('addto')) {
                            case 'car':
                                await addToCart(searchParams.get('slug'), 1, searchParams.get('storelug'))
                                navigate(`/produtos/cart/lista`)
                                break;
                            case 'buyNow':
                                await postBuyNow(searchParams.get('storelug'), searchParams.get('slug'), 1)
                                navigate(`/produtos/compra/finalizar/verify`)
                                break;
                            case 'myList':
                                await addToFavorite(searchParams.get('slug'), searchParams.get('storelug'))
                                navigate(`/produtos/favoritos/lista`)
                                break;
                            default:
                                break;
                        }
                    }
                    if (searchParams?.get('redirect')) {
                        if (searchParams?.get('redirect') === 'home') {
                            navigate(`/`)
                        } else {
                            navigate(`/usuario/perfil`)
                        }
                    }
                } else {
                    navigate(`/`)
                }
            }
        } catch (error) {
            if (error.response.data.message === "Login Error.") {
                setLoginError(true);
                setTimeout(() => {
                    setLoginError(false);
                }, 6000);
            }

        }
    }
    React.useEffect(() => {
        console.log(location)
    }, [])
    return (
        <Container>
            <Left style={{ backgroundImage: `url(${loginImage})`, backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundSize: 'cover', cursor: 'pointer' }} onClick={() => navigate('/')}>

            </Left>
            <Right >
                <DivTitle onClick={() => navigate('/')}>
                    <h1>Agro Produtos</h1>
                </DivTitle>
                <Middle>
                    <div style={{ textAlign: 'Left' }}>
                        <h3 style={{ fontWeight: '500', color: '#717171' }}>Fazer Login!</h3>
                    </div>
                    <CustomizeInput>
                        <label htmlFor="email">Email</label>
                        <input type="email" id="email" onChange={(e) => setEmail(e.target.value)} />
                    </CustomizeInput>
                    <CustomizeInput >
                        <label htmlFor="password">Senha</label>
                        <div style={{ display: 'flex', height: '100%' }}>
                            <input type={passType} id="password"
                                onKeyPress={(e) => { if (e.code === "Enter") { login(email, password) } }}
                                onChange={(e) => setPassword(e.target.value)} />
                            <div style={{ justifyContent: 'flex-end', display: 'flex', color: 'green', height: '15px' }}>
                                {seePassword ? <VisibilityOffIcon style={{ cursor: 'pointer' }} onClick={() => seePass(false, 'password')} /> : <VisibilityIcon style={{ cursor: 'pointer' }} onClick={() => seePass(true, 'text')} />}
                            </div>
                        </div>
                    </CustomizeInput>
                    <DivButton >
                        <Button variant="contained" color="success" style={{ width: '100%', height: '50px' }}
                            onClick={() => login(email, password)}>
                            Login
                        </Button>
                    </DivButton>
                    <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'center', width: '400px' }}>
                        <Link to="/esqueci_senha" style={{ textDecoration: 'none' }}> <p style={{ paddingLeft: '5px', color: '#717171' }} >Esqueci minha senha</p></Link>
                        <p style={{ paddingRight: '5px', color: '#717171' }} >Não é registrado ainda? <Link to={location?.search ? `/register/param${location?.search}` : `/register`} style={{ color: '#009c43' }}>Crie sua conta</Link></p>
                    </div>
                    <div id="sanckbar">
                        {loginError && <SnackBar type="error" time={6000} message="Falha ao realizar login, verifique seus dados de login e tente novamente" states={true} />}
                        {success && <SnackBar type="success" time={3000} message="Logado com sucesso" states={true} />}
                    </div>
                </Middle>
            </Right>
        </Container>
    )
}

export default CommonLogin;