import * as React from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Rating from '@mui/material/Rating';
import { red, blue, green } from '@mui/material/colors';
import moment from 'moment';
import Grid from '@mui/material/Grid';

function getRandomColor() {
    const colors = [red[500], blue[500], green[500]];
    const randomIndex = Math.floor(Math.random() * colors.length);
    return colors[randomIndex];
}


const RatingCard = ({ stars, userName, date, text }) => {
    const avatarColor = getRandomColor();
    return (
        <div style={{ width: '100%', height: '100%' }}>
            <Grid container spacing={3}>
                <Grid item xs="auto">
                    <Card sx={{ width: 280, border: 'solid 1px #F1F1F3' }}>
                        <CardHeader
                            avatar={
                                <Avatar sx={{ bgcolor: avatarColor }} aria-label="recipe">
                                    {userName.charAt(0)}
                                </Avatar>
                            }
                            title={userName}
                            subheader={moment(date).format('DD/MM/YYYY')}
                        />
                        <Rating name="read-only" value={stars} readOnly />
                        <CardContent>
                            <Typography variant="body2" color="text.secondary">
                                {text}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </div>
    );
}

export default RatingCard