import React from "react";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const Acord = ({ title, item, icon, children }) => {
    return (
        <div>
            <Accordion >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography>
                        <div style={{ display: 'flex' }}>
                            <div style={{ paddingRight: '10px', }}>
                                {icon}
                            </div>
                            {title}
                        </div>
                        <small style={{ color: '#717171' }}>{item}</small>
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        {children}
                    </Typography>
                </AccordionDetails>
            </Accordion>
        </div>
    )
}

export default Acord;