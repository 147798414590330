import styled from "styled-components";

export const Container = styled.div`
    background: #fff;
    height: 100%;
    min-height: 100vh;
    box-shadow: 0px 1px 2px #00000029;
    border-radius: 6px;
    opacity: 1;
    padding: 10px;
`;
export const DivHead = styled.div`
`;
export const DivBottom = styled.div`
    display: flex;
    justify-content: space-between;
    > button {
        border-radius: 20px;
    }
    >p {
        font-size: 1.8rem;
        color: #434343;
        font-family: Mulish;
        @media(max-width: 800px){
            font-size: 0.8rem;
    }
    }
`;
export const ProductSection = styled.div`
    
    /* @media(max-width: 1920px){
        height: 80%;
    }
    @media(max-width: 1366px){
        height: 65%;
    }

    @media(min-width: 1920px){
        height: 80%;
    } */
    overflow-y: auto;
    padding: 5px;
`;

export const EmptyDiv = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    align-content: center;
    justify-content: center;
    flex-direction: column;
    >img {
        padding-top: 10%;
        padding-bottom: 2%;
        width: 15%;
    }
    > p{
        font-size: 1.8rem;
        color: #434343;
        font-family: Mulish;
    }
`;