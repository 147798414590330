import styled from "styled-components";

export const Container = styled.div`
    grid-area: FT;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    top: 0;
    background-color: #009c43;
    height: 100px;
    width: 100%;
`;
export const Menu = styled.div`
    flex: 1;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    min-width: 0;
`;
export const MenuLine1 = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    >p{
        cursor: pointer;
    }
`;
export const Copyright = styled.div`
    align-content: center;
    align-items: center;
    justify-content: center;
    display: flex;
    width: 100% ;
    text-align: left;
    color: #fff;
`;